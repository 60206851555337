.signpost-banner {
  padding: 5px 30px;
  opacity: 0;
  top: -64px;

  transition: opacity 0.3s ease-in, top 0.3s ease-in;

  &__link:hover {
    text-decoration: none;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: @screen-tablet) {
      flex-direction: column;
    }
  }

  &__left-container {
    display: flex;
    align-items: center;

    @media (max-width: @screen-tablet) {
      margin-bottom: 10px;
    }
  }

  &__highlighted-text {
    padding: 10px 20px;
    font-size: 27px;
    font-family: @bold-font-family;
    margin-right: 20px;
  }

  &__image {
    margin-right: 20px;
  }

  &__right-container {
    display: flex;
    align-items: flex-end;
  }

  &__rich-text {
    font-size: 19px;
    line-height: 19px;
    font-family: @base-font-family;
    margin: 5px 0;

    p {
      font-size: 19px;
      line-height: 19px;
      font-family: @base-font-family;
      margin-bottom: 0;
    }

    strong {
      font-family: @bold-font-family;
    }
  }
}
