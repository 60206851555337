.ism-step-picker {
  align-self: center;
  cursor: pointer;
  user-select: none;
  position: relative;
  z-index: 2;

  &__selected {
    font-size: 15px;
    padding: 10px 28px 10px 0px;
    font-family: @bold-font-family;

    @media (min-width: @screen-tablet) {
      font-size: 18px;
    }

    svg {
      width: 22px;
      height: 10px;
      stroke: @brand-green--light;
      margin-left: 8px;
    }
  }

  &__items {
    display: none;
    max-width: 415px;
    width: calc(100vw - 36px);
    max-height: 50vh;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: @brand-white;
    border: 1px solid #c7c7c7;
  }

  &.active .ism-step-picker__items {
    display: block;
  }

  &__item {
    padding: 12px 10px;
    opacity: 0.3;

    &.completed,
    &.active {
      opacity: 1;
    }

    &.active {
      text-decoration: underline;
    }

    &:hover {
      opacity: 1;
    }
  }
}
