.ism-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 750px;
  margin: 0 auto;
  width: 100%;

  @media (min-width: @screen-tablet) {
    position: relative;
    z-index: 1;
  }

  &__row {
    max-width: 304px;
    width: 100%;
    margin: 5px 0px;

    .ism-button {
      margin: 0px 6px;
    }

    &--wide {
      max-width: 420px;
    }

    &--x-wide {
      max-width: 500px;
    }

    &--marg-20 {
      margin: 20px 0px;
    }

    &--marg-top-30 {
      margin-top: 30px;
    }

    &--split {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &--x-centre {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    ul {
      margin-bottom: 26px;
    }
  }

  &__error {
    display: block;
    font-weight: bold;
    font-family: @bold-font-family;
    color: @brand-red !important;
    font-size: 14px;
  }

  &__control {
    padding: 5px 0px;
    margin: 0 auto;

    &--centre {
      align-items: center;
    }

    label {
      display: block;
      font-weight: bold;
      font-family: @bold-font-family;
      color: #333333;
    }

    input {
      display: inline-block;
      width: 100%;
      border-radius: 8px;
      padding: 10px;
      border: 1px solid #000;

      &:focus,
      &:active {
        outline: none;
      }
    }

    iframe {
      border-radius: 4px;
    }

    .ism-form__error {
      display: none;
    }

    &.invalid {
      input {
        border: 1px solid @brand-red;
      }

      .ism-form__error {
        display: block;
      }
    }

    &--sub {
      display: inline-block;
      vertical-align: bottom;
      margin-left: 3px;
    }

    input[type='number'] {
      max-width: 100px;
      text-align: center;
    }
  }

  &__radios {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }

  &__radio {
    border: 1px solid #c7c7c7;
    padding: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    user-select: none;
    margin-bottom: 18px;
    align-self: center;
    margin-right: 12px;

    &:last-child {
      margin-right: 0px;
    }

    @media (min-width: @screen-xs) {
      align-self: auto;
      padding: 20px;
    }

    &-value {
      width: 24px;
      height: 24px;
      border: 1px solid #333333;
      border-radius: 50%;
      margin-right: 7px;
      position: relative;
    }

    &.invalid {
      border: 1px solid @brand-red;
    }

    &.active {
      border: 1px solid @brand-green--light;
      border-bottom: 6px solid @brand-green--light;
      margin-bottom: 12px;

      .ism-form__radio-value:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 15px;
        height: 15px;
        background-color: @brand-green--light;
        border-radius: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &-label {
      font-size: 14px;
      font-weight: normal;

      @media (min-width: @screen-xs) {
        font-size: 17px;
      }
    }
  }
}
