.ism-boxes {
  &:not(.ism-single-question) {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    width: 100%;
    justify-content: center;

    @media (min-width: @screen-tablet) {
      flex-direction: row;
      flex-wrap: wrap;
      width: auto;
    }
  }

  &__inner {
  }

  .ism-box {
    flex: 0 0 auto;
    padding: 8px 8px 8px 40px;
    position: relative;

    @media (min-width: @screen-tablet) {
      padding: 8px 8px 60px 8px;
      width: 33%;
    }

    &__inner {
      border: 1px solid #d5d5d5;
      padding: 20px 12px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      height: 100%;

      @media (min-width: @screen-tablet) {
        flex-direction: column;
        padding: 12px;
      }
    }

    &.invalid .ism-box__inner {
      border: 1px solid @brand-red;
    }

    &.active .ism-box__inner {
      border: 1px solid @brand-green--light;
      border-bottom: 7px solid @brand-green--light;
    }

    &__media {
      position: relative;
      flex: 0 0 auto;
      max-width: 40%;

      @media (min-width: @screen-tablet) {
        margin-bottom: 16px;
        max-width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover';
      }
    }

    &__label {
      text-align: center;
      font-weight: normal;
      font-size: 17px;
      display: block;
      flex: 1 1 auto;

      @media (min-width: @screen-tablet) {
        flex: 1 0 auto;
        margin-bottom: 50px;
      }
    }

    &__state {
      width: 24px;
      height: 24px;
      border: 1px solid #333333;
      border-radius: 50%;
      position: relative;
      flex: 0 0 auto;

      @media (min-width: @screen-tablet) {
        margin: 0 auto 20px auto;
        width: 30px;
        height: 30px;
      }
    }

    &.active .ism-box__state:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      background-color: @brand-green--light;

      @media (min-width: @screen-tablet) {
        width: 20px;
        height: 20px;
      }
    }

    &__info {
      position: absolute;
      left: 15px;
      bottom: 50%;
      transform: translate(-50%, 50%);

      @media (min-width: @screen-tablet) {
        left: 50%;
        bottom: 30px;
      }
    }
  }

  &.ism-single-question {
    .ism-box__info {
      position: relative;
      top: -20px;
      left: 8px;
    }
  }
}

.ism-info-icon {
  position: relative;
  width: 28px;
  height: 28px;
  border: 1px solid #333333;
  border-radius: 50%;
  cursor: pointer;

  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #333333;
    width: 16px;
    height: 16px;
  }
}

.ism-box-modal {
  position: fixed;
  background-color: rgba(@brand-white, 0.84);
  top: 18px;
  left: 0;
  width: 100%;
  // 50 for the footer height and 18 for the header height
  height: calc(100% - 50px - 18px);
  padding: 16px;

  z-index: 10;
  flex-direction: column;
  align-items: center;
  display: none;

  @media (min-width: @screen-tablet) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: unset;
  }

  &.active {
    display: flex;
  }

  &__close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  &__media {
    max-width: 260px;
    flex: 0 0 auto;

    @media (min-width: @screen-tablet) {
      margin: 0 auto;

      .full-width & {
        width: 100%;
        max-width: 700px;
      }
    }
  }

  &__inner {
    max-width: 760px;
    width: 100%;
    background-color: @brand-white;
    padding: 20px;
    border-bottom: 7px solid @brand-green--light;
    margin: auto;
    display: flex;
    box-shadow: 0px 3px 6px 2px rgba(#000, 0.25);
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
    max-height: 100%;
    overflow-y: auto;

    .ism-box-modal--small & {
      max-width: 460px;
    }

    @media (min-width: @screen-tablet) {
      flex-direction: row;
    }

    &.full-width {
      flex-direction: column;
    }
  }

  &__copy {
    padding: 25px 0px;

    h2,
    h3 {
      font-size: 30px;
      font-weight: bold;
      text-align: left;
      margin-bottom: 30px;
      margin-top: 0px;
    }

    p {
      font-size: 16px;
    }
  }

  &__copy-inner {
    // overflow: auto;
    // height: 40vh;
  }
}
