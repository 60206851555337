@import '../partials/_mixins';
@import '../partials/_variables';

.media-hub {
    position: relative;
    padding-bottom: 157px;

    .owl-carousel .owl-wrapper-outer.autoHeight {
        height: auto!important;
    }

    .row {
        margin-right: -10px;
        margin-left: -10px;

        .col-sm-6 {
            padding-right: 10px;
            padding-left: 10px;
        }
    }

    .owl-theme .owl-controls {
        margin: 0;
    }

    .owl-theme .owl-controls .owl-buttons div {
        position: absolute;
        top: 50%;
        left: -98px;
        margin: -38px 0 0 0;
        width: 77px;
        height: 77px;
        padding: 0;
        background: none;
        opacity: 1;
        border-radius: 0;
        z-index: 10;

        .owl-nav-prev, .owl-nav-next {
            background: url(@sprite-path) no-repeat 0 -376px;
            display: block;
            width: 77px;
            height: 77px;
            z-index: 2;
            position: relative;
        }

        .text-span {
            width: 0;
            position: absolute;
            top: 50%;
            left: 68px;
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            z-index: 1;
            overflow: hidden;
            -moz-transition: width 1s ease;
            -o-transition: width 1s ease;
            -webkit-transition: width 1s ease;
            transition: width 1s ease;
            text-align: left;
            display: block;
            padding: 5px 0;

            &.text-next {
                left: auto;
                right: 70px;
                text-align: right;

                .inner-span {
                    padding: 5px 20px 5px 10px;
                    display: inline-block;
                }
            }

            .inner-span {
                background: @brand-orange;
                padding: 5px 10px 5px 20px;
                color: #fff;
                .font-size(20);
                text-transform: uppercase;
                line-height: normal;
                white-space: nowrap;
            }
        }

        &:hover {
            .owl-nav-prev {
                background-position: 0 -376px;
            }

            .text-span {
                width: 200px;
            }
        }

        &.owl-next {
            left: auto;
            right: -98px;

            .owl-nav-next {
                background-position: -78px -376px;
            }
        }
    }




    .case-box {
        border: 1px solid @brand-light-grey;
        padding: 15px;
        margin-bottom: 15px;

        &:last-child {
            margin: 0;
        }


        .case-top {
            position: relative;
            padding-right: 90px;

            &.twit-icon {
                padding-right: 0;

                a {
                    display: none;
                }

                h5 {
                    padding-left: 60px;
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 15px;
                        background: url(@sprite-path) no-repeat -41px 0;
                        width: 30px;
                        height: 38px;
                    }
                }
            }

            &.youtube-icon {
                padding-right: 0;

                h5 {
                    padding-left: 60px;
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 15px;
                        background: url(@sprite-path) no-repeat -0px 0;
                        width: 30px;
                        height: 38px;
                    }
                }
            }
            h1 {
                display: inline-block;
                background: @brand-orange;
                padding: 7px 15px;
                margin-left: -15px;
                color: #fff;
            }
            h5 {
                display: inline-block;
                background: @brand-orange;
                padding: 7px 15px;
                margin-left: -15px;
                color: #fff;
            }

            a {
                position: absolute;
                top: 0;
                right: 0;
                color: @brand-orange;
            }
        }


        &.brand-orange {
            .case-top {
                h1 {
                    background: @brand-orange;
                }

                a {
                    color: @brand-orange;
                }
            }

            .case-bottom {
                .case-text {
                    h6 {
                        color: @brand-orange;
                    }

                    a {
                        color: @brand-orange;
                    }
                }
            }
        }

        &.brand-green {
            .case-top {
                h5 {
                    background: @brand-green;
                }

                a {
                    color: @brand-green;
                }
            }

            .case-bottom {
                .case-text {
                    h6 {
                        color: @brand-green;
                    }
                }
            }
        }


        &.big-box {
            margin-bottom: 0;

            .case-bottom {
                .case-image {
                    /*width: 65%;*/
                    width: 100%;
                    padding-right: 0px;
                }

                .case-text {
                    width: 35%;
                    width: 100%;
                    margin-top: 20px;
                }
            }
        }

        .case-bottom {
            .case-image {
                float: left;
                width: 40%;
                padding-right: 20px;
            }

            .case-text {
                float: left;
                width: 60%;

                h6 {
                    color: @brand-orange;
                    margin: 0 0 5px 0;
                }
            }
        }
    }


    .media-view {
        margin: 0 auto;
        width: 74%;
        position: absolute;
        bottom: 50px;
        left: 13%;


        h5 {
            margin: 0 0 15px;
        }

        .grey-bar {
            margin: 0 0 15px;

            &:before {
                display: none;
            }



            .green-block {
                position: absolute;
                top: 0;
                right: auto;
                left: -9px;
                content: ' ';
                display: block;
                height: 8px;
                width: 16px;
                -moz-transition: all 1s ease-in-out;
                -o-transition: all 1s ease-in-out;
                -webkit-transition: all 1s ease-in-out;
                transition: all 1s ease-in-out;
                background: #859a3e;
            }
        }


        span {
            cursor: pointer;


            &:hover {
                color: @brand-green;
            }
        }

        .synced {
            span {
                color: @brand-green;
            }
        }
    }
}

.IE8 .media-hub .owl-theme .owl-controls .owl-buttons div .text-span {
    display: none!important;
}

//Media list page

.article-wrap {
    position: relative;
    margin: 15px 0;

    .media-filter {
        width: 518px;
        padding: 9px;
        background: @brand-orange;
        position: absolute;
        right: 0;
        top: -74px;


        .filter-inner {
            width: 100%;
            display: block;
        }

        label {
            display: inline-block;
            color: #fff;
            .font-size(18);
            font-weight: normal;
            margin-right: 8px;
        }

        select {
            width: 165px!important;
            margin-bottom: 0;
        }

        .filter {
            display: inline-block;
            width: 200px;
            margin-right: 22px;

            &.no-marg {
                margin-right: 0;
            }
        }

        .chosen-container {
            height: 32px;
            margin-bottom: 0;
            background: #f2f2f2;

            .chosen-drop, .chosen-results li {
                background: #f2f2f2;

                &.highlighted {
                    background: #729c2a;
                }
            }

            .chosen-single {
                height: 32px;
                background: #f2f2f2;

                span {
                    height: 32px;
                    line-height: 32px;
                }

                div {
                    padding: 3px 18px;
                }
            }
        }
    }


    .article-item {
        margin-bottom: 45px;
        padding: 0 20px;

        .grey-bar {
            margin-top: 25px;
        }

        .article {
            display: table;
            width: 100%;

            a {
                text-decoration: none;
                display: block;
                position: relative;

                &:hover {
                    .hover-overlay {
                        background: #000;
                        opacity: 0.4;
                    }

                    .overlay {
                        height: 74px;
                        width: 90px;
                    }

                    .overlay img {
                        width: 56px;
                    }
                }
            }

            .article-img {
                width: 222px;
                max-width: 222px;
                display: table-cell;
                vertical-align: top;
                position: relative;

                .fancybox {
                    display: block;
                    position: relative;
                    height: 100%;
                    width: 100%;
                    text-align: center;
                    text-decoration: none;

                    &:before {
                        content: '\f16a';
                        font-family: 'FontAwesome';
                        .font-size(66);
                        color: #fff;
                        opacity: 0.7;
                        position: absolute;
                        top: 49%;
                        left: 0;
                        right: 0;
                        -moz-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        -o-transform: translateY(-50%);
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                        -moz-transition: all 0.4s ease-in-out;
                        -o-transition: all 0.4s ease-in-out;
                        -webkit-transition: all 0.4s ease-in-out;
                        transition: all 0.4s ease-in-out;
                    }

                    &:hover {
                        &:before {
                            opacity: 1;
                            font-size: 74px;
                        }
                    }
                }


                img {
                    max-width: 222px;
                }

                .hover-overlay {
                    opacity: 0;
                    background: none;
                    display: block;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    -moz-transition: all 0.4s ease-in-out;
                    -o-transition: all 0.4s ease-in-out;
                    -webkit-transition: all 0.4s ease-in-out;
                    transition: all 0.4s ease-in-out;
                }

                .overlay {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 58px;
                    width: 78px;
                    background-color: @brand-orange;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 55%;
                    z-index: 30;
                    -moz-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    -webkit-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;

                    img {
                        width: 42px;
                        -moz-transition: all 0.4s ease-in-out;
                        -o-transition: all 0.4s ease-in-out;
                        -webkit-transition: all 0.4s ease-in-out;
                        transition: all 0.4s ease-in-out;
                    }

                    &.news {
                        background-image: url(/sitefiles/images/news-feature-icon.png);
                    }

                    &.casestudy {
                        background-image: url(/sitefiles/images/news-feature-icon.png);
                    }

                    &.blog {
                        background-image: url(/sitefiles/images/blog-feature-icon.png);
                    }
                }
            }

            .article-desc {
                padding-left: 40px;
                display: table-cell;
                vertical-align: top;

                h3 {
                    color: #666666;
                    .font-size(19);
                    margin-bottom: 15px;
                    -moz-transition: all 0.4s ease-in-out;
                    -o-transition: all 0.4s ease-in-out;
                    -webkit-transition: all 0.4s ease-in-out;
                    transition: all 0.4s ease-in-out;

                    &:hover {
                        color: @brand-font-green;
                    }
                }
            }
        }

        .blog-author {
            display: inline-block;
            .font-size(13);
            margin-top: 8px;

            .fa {
                .font-size(20);
                padding-right: 8px;
            }
        }

        .tags-used {
            display: inline-block;
            .font-size(13);
            margin-top: 8px;

            .fa {
                .font-size(20);
                padding-right: 6px;
                padding-left: 8px;
                position: relative;
                top: 3px;
            }

            ul {
                padding: 0;
                display: inline-block;

                li {
                    list-style-type: none;
                    display: inline-block;
                    padding-left: 0px;

                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}

.IE8 {
    .tags-used {
        display: block!important;

        ul li:before {
            display: none;
        }
    }

    .article-wrap .article-item .article {
        display: block;
    }

    .article-wrap .article-item .article .article-img {
        display: block;
        width: 100%;
    }

    .article-wrap .article-item .article .article-desc {
        display: block;
        margin-top: 15px;
        padding-left: 0;
        width: 100%;
    }

    .article-wrap .article-item .article a:hover .hover-overlay {
        background: url(/sitefiles/images/opacity-40.png) repeat 0px 0px;
        opacity: 1;
    }
}



//Media Hub Detail
.hub-content {
    margin-bottom: 40px;

    .grey-bar {
        margin-top: 45px;
    }

    .blog-author {
        display: inline-block;
        .font-size(13);
        margin-top: 8px;
        float: left;

        .fa {
            .font-size(20);
            padding-right: 8px;
        }
    }

    .tags-used {
        display: inline-block;
        .font-size(13);
        margin-top: 8px;
        float: left;

        .fa {
            .font-size(20);
            padding-right: 6px;
            padding-left: 8px;
            position: relative;
            top: 3px;
        }

        ul {
            padding: 0;
            display: inline-block;

            li {
                list-style-type: none;
                display: inline-block;
                padding-left: 0px;

                &:before {
                    display: none;
                }
            }
        }
    }
}


.post-area {
    position: relative;
    padding-left: 85px;
    float: left;
    margin-right: 21px;
    margin-bottom: 18px;
    max-width: 100%;

    .left-type {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 78px;
        background: @brand-green;
        color: #fff;

        .date {
            position: absolute;
            top: 30px;
            left: 17px;
            .font-size(18);
            text-align: center;

            span {
                width: 100%;
                display: block;
                padding-left: 4px;
            }

            strong {
                display: block;
                width: 100%;
                .font-size(32);
                margin-bottom: 5px;
            }
        }

        .post-type {
            position: absolute;
            bottom: 0;
            left: 0;
            border-top: 6px solid #fff;
            padding: 12px;
            width: 100%;
            text-align: center;

            &.bg-orange {
                background: @brand-orange;
            }

            &.bg-green {
                background: @brand-green;
            }

            &.bg-grey {
                background: @brand-grey;
            }

            img {
                width: 40px;
            }
        }
    }
}

@media (max-width:1330px) {
    .media-hub .owl-theme .owl-controls .owl-buttons div {
        left: 0;
        opacity: 0.5;
    }

    .media-hub .owl-theme .owl-controls .owl-buttons div.owl-next {
        right: 0;
    }
}

@media (max-width:1199px) {
    .post-area {
        width: 422px;
    }

    .article-wrap .media-filter {
        width: 430px;

        .filter {
            width: 160px;
            margin-right: 20px;

            .chosen-container {
                .chosen-single span {
                    padding-right: 7px;
                }

                .chosen-results li {
                    padding-right: 6px;
                }
            }
        }
    }

    .media-hub .owl-theme .owl-controls .owl-buttons div {
        left: -98px;
        opacity: 1;
    }

    .media-hub .owl-theme .owl-controls .owl-buttons div.owl-next {
        right: -98px;
    }

    .article-wrap .article-item .article .article-img {
        width: 178px;
        max-width: 178px;


        img {
            max-width: 178px;
        }
    }

    .article-wrap .article-item .article .article-desc {
        padding-left: 22px;
    }
}

@media (max-width:1130px) {
    .media-hub .owl-theme .owl-controls .owl-buttons div {
        left: 0;
        opacity: 0.5;
    }

    .media-hub .owl-theme .owl-controls .owl-buttons div.owl-next {
        right: 0;
    }
}

@media (max-width:991px) {

    .media-hub .case-box {
        .case-bottom iframe {
            min-width: 136px!important;
        }
    }

    .post-area {
        width: 290px;
    }

    .article-wrap {
        padding-top: 66px;

        &.list-no-filter {
            padding-top: 0px;
        }

        .media-filter {
            width: 100%;
            position: absolute;
            right: auto;
            top: -14px;

            .filter {
                width: 178px;
            }
        }
    }

    .media-hub .owl-theme .owl-controls .owl-buttons div {
        left: -98px;
        opacity: 1;
    }

    .media-hub .owl-theme .owl-controls .owl-buttons div.owl-next {
        right: -98px;
    }

    .media-hub .case-box .case-bottom .case-image, .media-hub .case-box.big-box .case-bottom .case-image {
        width: 100%;
        margin-bottom: 15px;
    }

    .media-hub .case-box .case-bottom .case-text, .media-hub .case-box.big-box .case-bottom .case-text {
        width: 100%;
    }

    .article-wrap .article-item .article .article-img {
        width: 133px;
        max-width: 133px;

        img {
            max-width: 133px;
        }
    }

    .article-wrap .article-item .article .article-img .overlay {
        padding: 7px 12px;

        img {
            width: 32px;
        }
    }

    .article-wrap .article-item .article a:hover {
        .overlay img {
            width: 45px;
        }
    }
}

@media (max-width:905px) {
    .media-hub .owl-theme .owl-controls .owl-buttons div {
        left: 0;
        opacity: 0.5;
    }

    .media-hub .owl-theme .owl-controls .owl-buttons div.owl-next {
        right: 0;
    }
}




@media (max-width:767px) {

    .article-wrap .media-filter .filter {
        width: 160px;
    }

    .media-hub .case-box.big-box .case-bottom .case-image {
        width: 65%;
    }

    .media-hub .case-box.big-box .case-bottom .case-text {
        width: 35%;
    }

    .media-hub .case-box .case-bottom .case-image {
        width: 40%;
    }

    .media-hub .case-box .case-bottom .case-text {
        width: 60%;
    }

    .media-hub .row .col-sm-6 {
        padding-right: 15px;
        padding-left: 15px;
    }

    .media-hub .row {
        margin-right: -15px;
        margin-left: -15px;
    }

    .media-hub .case-box.big-box {
        margin-bottom: 15px;
    }

    .media-hub {
        padding: 0;
    }

    .media-hub .media-view {
        position: static;
        margin: 0 auto 25px;
        width: 100%;

        .grey-bar {
            display: block!important; //important to override the normal grey bar rule
        }
    }

    .media-hub .owl-theme .owl-controls .owl-buttons div {
        position: fixed;
    }

    .article-item {
        .grey-bar {
            display: block!important;
        }
    }

    .media-hub .owl-theme .owl-controls .owl-buttons {
        display: none;
    }

    .media-hub .case-box:last-child {
        margin-bottom: 15px;
    }


    .hub-content .tags-used, .hub-content .blog-author {
        float: none;
    }
}

@media (max-width:550px) {
    .post-area {
        width: 100%;
        float: none;
    }

    .hub-content {
        margin-bottom: 20px;

        .grey-bar {
            display: block!important;
            margin-top: 25px;
        }
    }
}

@media (max-width:480px) {

    .article-wrap {
        padding-top: 160px;

        &.list-no-filter {
            padding-top: 0px;
        }

        .media-filter {
            padding: 16px;

            .filter {
                width: 100%;
                margin-bottom: 15px;

                select {
                    width: 100%!important;
                    height: 40px;
                }

                &.no-marg {
                    margin: 0;
                }
            }
        }
    }

    .media-hub .case-box .case-bottom .case-image, .media-hub .case-box.big-box .case-bottom .case-image {
        width: 100%;
    }

    .media-hub .case-box .case-bottom .case-text, .media-hub .case-box.big-box .case-bottom .case-text {
        width: 100%;
    }

    .media-hub .case-box .case-bottom .case-text h6 {
        margin-top: 10px;
    }

    .article-wrap .article-item .article {
        display: block;
        width: 100%;

        .article-img {
            width: 100%;
            max-width: 100%;
            display: block;
            margin-bottom: 20px;

            img {
                max-width: 100%;
            }
        }

        .article-desc {
            width: 100%;
            display: block;
            padding-left: 0;
        }
    }

    .article-wrap .article-item {
        margin-bottom: 25px;
        padding: 0 10px;

        .tags-used {
            display: block;

            .fa {
                padding-left: 0;
            }
        }
    }
}

.replaced .article-item {
    opacity: 0;
}

@media (max-width:360px) {
    .post-area .left-type .date {
        top: 20px;
        .font-size(18);
    }
}


.BlogCommentsTitle {
    margin-bottom: 20px;
    display: block;
}

.CommentUserName {
    font-weight: bold;
    display: block;
    margin-bottom: 2px;
}

.CommentDate {
    margin-top: 5px;
    display: block;
    font-size: 80%;
}
