@import '../partials/_mixins';
@import '../partials/_variables';

.home-banner {
    position: relative;
}

@media (max-width: 991px) {
    .home-banner {
        top: 0!important;
    }
}