@import '_variables';

// Set the font size to the target using the specified context.
.font-size(@target: @base-font-size, @context: @base-font-size) {
    font-size: (@target / @context) + 0em;
}

// Set the line height to to the target, using the specified context.
.line-height(@target: @base-line-height, @context: @base-font-size) {
    line-height: (@target / @context) + 0em;
}

// Set the border radius.
.border-radius (@radius: 5px) {
    border-radius: @radius;
    -moz-background-clip: padding-box;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}

// Set the background image and element size to display a sprite.
.sprite(@x, @y, @width, @height, @color: transparent) {
    height: @height;
    width: @width;
    background: @color ~"url(@{sprite-path})" -@x -@y no-repeat;
}

// Set a linear gradient background.
.gradient-y(@color-start, @color-end: darken(@color-start, 5%)) {
    background: @color-start;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,@color-start), color-stop(100%,@color-end));
    background: -webkit-linear-gradient(top, @color-start 0%,@color-end 100%);
    background: -moz-linear-gradient(top, @color-start 0%, @color-end 100%);
    background: -ms-linear-gradient(top, @color-start 0%,@color-end 100%);
    background: -o-linear-gradient(top, @color-start 0%,@color-end 100%);
    background: linear-gradient(to bottom, @color-start 0%,@color-end 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@color-start', endColorstr='@color-end', GradientType=0);
}

// Set a linear gradient background.
.gradient-x(@color-start, @color-end: darken(@color-start, 5%)) {
    background: @color-start;
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,@color-start), color-stop(100%,@color-end));
    background: -webkit-linear-gradient(left, @color-start 0%,@color-end 100%);
    background: -moz-linear-gradient(left, @color-start 0%, @color-end 100%);
    background: -ms-linear-gradient(left, @color-start 0%,@color-end 100%);
    background: -o-linear-gradient(left, @color-start 0%,@color-end 100%);
    background: linear-gradient(to right, @color-start 0%,@color-end 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@color-start', endColorstr='@color-end', GradientType=0);
}

// Set the button styles
.button(@color-bg: @base-btn-bg-color, @color-font: @base-btn-font-color) {
    display: inline-block;
    position: relative;
    margin: 0;
    border: 0;
    padding: 12px 36px;
    color: @color-font;
    background-color: @color-bg;
    .font-size(18);
    vertical-align: middle;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 0;
    -moz-transition: background ease 0.3s;
    -o-transition: background ease 0.3s;
    -webkit-transition: background ease 0.3s;
    transition: background ease 0.3s;

    &:hover, &:focus {
        color: @color-font;
        background-color: lighten(@color-bg, 10%);
    }
}

// Set the placeholder colour
.placeholder-color(@color: @base-input-placeholder-color) {
    &::-webkit-input-placeholder {
        color: @color;
    }

    &:-moz-placeholder { /* Firefox 18- */
        color: @color;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        color: @color;
    }

    &:-ms-input-placeholder {
        color: @color;
    }
}