.ism-solutions {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 40px;
  max-width: 850px;
  width: 100%;

  @media (min-width: @screen-tablet) {
    position: relative;
    z-index: 1;
  }

  +.ism-form__row {
    @media (min-width: @screen-tablet) {
      position: relative;
      z-index: 1;
    }
  }

  &-item {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 40px;

    @media (min-width: @screen-tablet) {
      flex-direction: row;
      flex-wrap: nowrap;
    }

    h2 {
      font-size: 21px;
      text-align: left;
      margin-bottom: 18px;
    }

    ul {
      margin-bottom: 32px;
      padding: 0px;
      list-style-type: none;
      border: 1px solid @brand-green--light;
      list-style: disc;

      @media (min-width: @screen-tablet) {
        list-style-type: disc;
        padding-left: 18px;
        border: none;
      }

      li {
        color: @brand-green--light;
        padding: 5px 10px;
        border-bottom: 1px solid @brand-green--light;
        list-style-type: disc;

        @media (min-width: @screen-tablet) {
          padding: 0px;
          border-bottom: none;
        }

        &:before {
          display: none;
        }

        &:nth-child(even) {
          background-color: #e2f0d9;

          @media (min-width: @screen-tablet) {
            background: none;
          }
        }

        &:last-child {
          border-bottom: none;
        }

        strong {
          margin-right: auto;

          @media (min-width: @screen-tablet) {
            margin-right: 0px;
          }

          &:after {
            @media (min-width: @screen-tablet) {
              content: ':';
              display: inline-block;
              margin-right: 4px;
            }
          }
        }

        span {
          color: #333333;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-family: @bold-font-family;

          @media (min-width: @screen-tablet) {
            display: inline-block;
          }
        }
      }
    }

    &__media {
      flex: 0 0 35%;
      background-color: @brand-white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      picture {
        height: 100%;
      }

      img {
        flex: 0 0 auto;
        display: block;
        margin-left: auto;

        @supports (display: grid) {
          height: 100%;
          width: 100%;
          object-fit: contain;
          font-family: 'object-fit: contain';
        }
      }
    }

    &__media-inner {
      width: 100%;
      padding: 16px;
      flex: 1 0 1px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (min-width: @screen-tablet) {
        padding: 0;
      }

      @supports (display: grid) {
        height: 100%;
        display: block;
      }
    }

    &__copy {
      flex: 0 0 65%;
    }

    &__copy-inner {
      padding: 15px;

      @media (min-width: @screen-tablet) {
        padding: 70px;
      }
    }
  }
}
