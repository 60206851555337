.ism-question {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;
  margin-right: 15px;

  &--y-centre {
    justify-content: center;
  }

  // Need to do h3 because thats what HTML comes out of api..
  h2,
  h3 {
    font-size: 25px;
    text-align: center;
    margin-bottom: 28px;
    color: #333333;
    font-family: @bold-font-family;

    @media (min-width: @screen-tablet) {
      font-size: 30px;
      margin-bottom: 45px;
    }
  }

  strong {
    font-family: @bold-font-family;
  }

  p {
    font-size: 17px;
    margin-bottom: 24px;
    color: #333333;
  }

  &__avatar {
    fill: @brand-green--light;
    width: 70px;
    height: 70px;
    align-self: center;
    margin-bottom: 35px;

    + .ism-button {
      margin-bottom: 8px;
    }
  }

  &__icon {
    width: 300px;
    margin-bottom: 25px;

    svg {
      width: 100%;
    }
  }

  &__depth {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: 0 auto 45px auto;

    &-icon {
      width: 100%;

      &--gw{
        height: 103px;
      }
    }

    &-label {
      position: absolute;
      top: -3px;
      left: 50%;
      transform: translateX(-50%);
      color: @brand-green--light;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      align-items: center;
      z-index: 2;
      padding: 7px 0px;
      top: -3px;
      user-select: none;

      @media (min-width: @screen-tablet) {
        top: 3px;
      }

      svg {
        display: block;
        fill: @brand-green--light;
        width: 7px;
        height: 16px;
        margin-right: 4px;
      }
    }

    &-indicator {
      width: 1px;
      background-color: @brand-green--light;
      margin-right: 4px;
      position: relative;
      height: 100%;

      &:before,
      &:after {
        content: '';
        width: 7px;
        height: 7px;
        background-image: url('/sitefiles/images/ism/icon-point.svg');
        position: absolute;
      }

      &:before {
        content: '';
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%) rotate(180deg);
      }

      &:after {
        content: '';
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
