@import '../partials/_mixins';
@import '../partials/_variables';

.HomePage header {
  -moz-transition-delay: 0s;
  -o-transition-delay: 0.4s;
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;

  .grey-bar {
    -moz-transition-delay: 0.9s;
    -o-transition-delay: 0.7s;
    -webkit-transition-delay: 0.7s;
    transition-delay: 0.7s;
  }
}

.animate-banner {
  opacity: 0 !important;
  position: relative;
  top: 0;
  left: 100%;
  -moz-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}

.home-banner {
  overflow: hidden;
  opacity: 0 !important;

  .owl-buttons .owl-prev {
    position: absolute;
    z-index: 8000;
    top: 60%;
    margin-top: -13px !important;
    left: 30px;
    opacity: 1 !important;
    text-indent: -9999px;
    width: 47px;
    height: 47px;
    background: url(@sprite-path) no-repeat 0px -177px !important;

    &:hover {
      background-position: -59px -177px !important;
    }
  }

  .item {
    position: relative;

    .banner-overlay {
      position: absolute;
      z-index: 900;
      bottom: 200px;
      left: 0px;
      right: 0px;

      .banner-title {
        display: inline-block;
        padding: 20px;
        .font-size(36);
        color: @brand-font-green;
        background: #fff;

        a {
          display: block;
          color: @brand-font-green;
          text-decoration: none;
          -moz-transition: color 0.3s ease-in-out;
          -o-transition: color 0.3s ease-in-out;
          -webkit-transition: color 0.3s ease-in-out;
          transition: color 0.3s ease-in-out;

          &:hover {
            color: lighten(@brand-font-green, 8%);
          }
        }
      }

      .banner-text {
        margin: -27px 0px 0px 34px;
        background: url('~@imgOld/white-overlay.png') repeat 0px 0px;
        width: 396px;
        display: block;
        padding: 40px 20px 30px 20px;
        .font-size(16);

        a {
          color: #666;
          text-decoration: none;
          -moz-transition: color 0.3s ease-in-out;
          -o-transition: color 0.3s ease-in-out;
          -webkit-transition: color 0.3s ease-in-out;
          transition: color 0.3s ease-in-out;

          &:hover {
            color: @brand-font-green;
          }
        }
      }
    }
  }

  .owl-buttons .owl-next {
    position: absolute;
    z-index: 8000;
    top: 60%;
    margin-top: -13px !important;
    right: 30px;
    opacity: 1 !important;
    text-indent: -9999px;
    width: 47px;
    height: 47px;
    background: url(@sprite-path) no-repeat 1px -229px !important;

    &:hover {
      background-position: -59px -229px !important;
    }
  }
}

.animate-banner.slide-in {
  opacity: 1 !important;
  left: 0;

  .home-banner {
    opacity: 1 !important;
  }
}

.homepage-signposts {
  position: relative;
  top: -36px;

  .signpost-item {
    opacity: 0;
    width: 20%;
    float: left;
    padding: 0 15px;

    .widget.sign-post-widget {
      min-height: 95px;

      /*a:hover {
                .signpost .signpost-icon img {
                    left: 5px;
                    width: 54px;
                }
            }*/

      .signpost {
        height: 54px;
        .signpost-icon {
          width: 67px;

          img {
            left: 7px;
          }
        }

        .signpost-text {
          .font-size(23);
          line-height: 26px;
        }
      }
    }
  }
}

.load-home-content {
  opacity: 0;
  -moz-transition: opacity 0.6s ease-in-out;
  -o-transition: opacity 0.6s ease-in-out;
  -webkit-transition: opacity 0.6s ease-in-out;
  transition: opacity 0.6s ease-in-out;

  &.fade-in {
    opacity: 1 !important;
  }
}

.homepage-strip {
  padding: 40px 0 30px;
  background: url(~@imgOld/faint-line-repeat.png) repeat 0px 0px;
}

.home-widget-wrap {
  margin: 60px 0;
}

@media (max-width: 1590px) {
  .home-banner .item .banner-overlay .banner-text {
    width: 550px;
  }

  .home-banner .item .banner-overlay {
    bottom: 120px;
  }
}

@media (max-width: 1390px) {
  .home-banner .item .banner-overlay {
    bottom: 75px;
    padding-left: 80px;
  }

  .home-banner .owl-buttons .owl-prev {
    left: 2px;
  }

  .home-banner .owl-buttons .owl-next {
    right: 2px;
  }
}

@media (max-width: 1270px) {
  .home-banner .item .banner-overlay {
    bottom: 65px;
    padding-left: 80px;

    .banner-text {
      width: 780px;
    }
  }
}

@media (max-width: 1199px) {
  .home-banner .item .banner-overlay {
    bottom: 38px;
    padding-left: 80px;

    .banner-text {
      width: 840px;
      padding: 40px 20px 12px 20px;
    }
  }

  .homepage-signposts {
    position: relative;
    top: -25px;
  }

  .homepage-signposts .signpost-item .widget.sign-post-widget {
    /*a:hover {
                .signpost .signpost-icon img {
                    left: 3px;
                    width: 43px;
                }
            }*/

    .signpost .signpost-icon {
      width: 56px;

      img {
        left: 4px;
        width: 39px;
      }
    }
  }

  .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-text {
    .font-size(19);
    line-height: 25px;
  }

  .homepage-signposts .widget.sign-post-widget .signpost .depot-title {
    .font-size(19);
    line-height: 25px;
  }
}

@media (max-width: 1040px) {
  .home-banner .item .banner-overlay {
    bottom: 26px;
    padding-left: 44px;
  }
}

@media (max-width: 991px) {
  .home-banner .item .banner-overlay {
    bottom: auto;
    top: 48px;
    padding-left: 0;
    padding-right: 10%;

    .banner-text {
      width: 100%;
    }
  }

  .homepage-signposts {
    top: 8px;

    .container {
    }

    .signpost-item {
      width: 25%;
      float: left;
      padding: 0 3px;

      &:last-child {
        display: none;
      }
    }
  }
}

@media (max-width: 767px) {
  .homepage-signposts .signpost-item {
    width: 50%;
  }

  .home-banner .item .banner-overlay {
    .banner-title {
      display: inline-block;
      padding: 14px 0;
      .font-size(26);
    }
  }

  .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-text {
    .font-size(24);
    line-height: 31px;
  }

  .homepage-signposts .widget.sign-post-widget .signpost .depot-title {
    .font-size(24);
    line-height: 25px;
  }

  .home-banner .item .banner-overlay .banner-text {
    padding: 0;
    margin: 0;
  }

  .home-banner .item .banner-overlay {
    top: 5px;
    left: 0;
    position: relative;
  }

  .home-banner .owl-buttons .owl-prev,
  .home-banner .owl-buttons .owl-next {
    top: 40%;
  }
}

@media (max-width: 550px) {
  .home-widget-wrap {
    margin: 25px 0 5px;
  }

  .home-banner .owl-buttons .owl-prev,
  .home-banner .owl-buttons .owl-next {
    top: 20%;
  }

  .home-banner .item .banner-overlay {
    top: 5px;
    left: 0;
    position: relative;
    padding-right: 0;

    .container {
      padding: 0;
    }

    .banner-title {
      display: inline-block;
      padding: 9px;
      padding-bottom: 0;
      font-size: 20px;
      color: #729c2a;
      background: #fff;
    }

    .banner-text {
      width: 100%;
      margin: 0;
      padding: 10px 10px;
      .font-size(14);
    }
  }
}

@media (max-width: 480px) {
  .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-text {
    .font-size(17);
    line-height: 22px;
  }

  .widget.sign-post-widget .signpost .postcode-select .chosen-container .chosen-single {
    padding: 1px 2px !important;
  }

  .homepage-signposts .widget.sign-post-widget .signpost .depot-title {
    .font-size(17);
  }

  .widget.sign-post-widget .signpost .postcode-select {
    padding: 0 7px !important;
  }

  .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-icon {
    width: 48px;
  }

  .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-icon img {
    left: -2px;
  }

  /*.homepage-signposts .signpost-item .widget.sign-post-widget a:hover .signpost .signpost-icon img {
        left: -3px;
    }*/

  .homepage-signposts {
    top: -2px;

    .widget {
      margin-bottom: 7px;
    }

    .chosen-container .chosen-results li {
      height: 42px;
      line-height: 16px;
      padding-left: 12px;
      padding-top: 13px;
      padding-right: 9px;
    }
  }
}

@media (max-width: 335px) {
  .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-text {
    .font-size(17);
    line-height: 22px;
  }
}
