@import '../partials/_mixins';
@import '../partials/_variables';


footer {
    background: #575757;
    color: #fff;
    padding-top: 30px;
    display: none;

    h3 {
        .font-size(18);
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
    }

    .green-hover {

        h3 {
            color: #fff;
            font-weight: normal;
        }

        /*&:hover {
            > h3 {
                color: #859A3E;
                .font-size(19);
            }
        }*/
    }

    .green-btm {
        background: #859a3e;
        margin-top: 40px;
        padding: 15px 0;

        .bottom-links {
            text-align: right;

            ul {
                padding: 0;
                margin: 0;

                li {
                    list-style-type: none;
                    display: inline-block;
                    position: relative;
                    padding-right: 14px;

                    &:before {
                        content: '|';
                        display: block;
                        color: #fff;
                        position: absolute;
                        top: 0px;
                        right: 4px;
                    }

                    &:last-child {
                        padding-right: 0;

                        &:before {
                            display: none;
                        }
                    }

                    a {
                        text-decoration: none;
                        color: #fff;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    .footer-links {
        float: left;
        width: 50%;

        ul {
            padding: 0;
            margin: 0;

            li {
                list-style-type: none;
                padding-left: 9px;
                position: relative;

                &:before {
                    display: block;
                    content: ' ';
                    background: #fff;
                    width: 3px;
                    height: 3px;
                    position: absolute;
                    top: 8px;
                    left: 0px;
                    -moz-border-radius: 50%;
                    -webkit-border-radius: 50%;
                    border-radius: 50%;
                    -moz-transition: all 0.4s ease-in-out;
                    -o-transition: all 0.4s ease-in-out;
                    -webkit-transition: all 0.4s ease-in-out;
                    transition: all 0.4s ease-in-out;
                }

                &:hover {
                    &:before {
                        background: @brand-font-green;
                        width: 2px;
                        height: 14px;
                        position: absolute;
                        top: 2px;
                        left: 0px;
                        -moz-border-radius: 50%;
                        -webkit-border-radius: 50%;
                        border-radius: 0;
                    }
                }

                a {
                    color: #fff;
                    text-decoration: none;
                }
            }
        }
    }

    .social-links {
        float: left;
        width: 50%;

        .social-link {
            width: 40px;
            height: 40px;
            display: inline-block;
            margin-right: 6px;
            -webkit-transform: translateZ(0);
            -moz-transform: translateZ(0);
            -ms-transform: translateZ(0);
            -o-transform: translateZ(0);
            transform: translateZ(0);
            -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            backface-visibility: hidden;
            position: relative;
            -webkit-transition-duration: 0.3s;
            -moz-transition-duration: 0.3s;
            -o-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-property: transform;
            -moz-transition-property: transform;
            -o-transition-property: transform;
            transition-property: transform;

            &:before {
                pointer-events: none;
                position: absolute;
                z-index: -1;
                content: '';
                top: 100%;
                left: 5%;
                height: 10px;
                width: 90%;
                opacity: 0;
                background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
                background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
                -webkit-transition-duration: 0.3s;
                -moz-transition-duration: 0.3s;
                -o-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform, opacity;
                -moz-transition-property: transform, opacity;
                -o-transition-property: transform, opacity;
                transition-property: transform, opacity;
            }


            &:hover {
                -webkit-transform: translateY(-5px);
                -moz-transform: translateY(-5px);
                -ms-transform: translateY(-5px);
                -o-transform: translateY(-5px);
                transform: translateY(-5px);

                &:before {
                    opacity: 1;
                    -webkit-transform: translateY(5px);
                    -moz-transform: translateY(5px);
                    -ms-transform: translateY(5px);
                    -o-transform: translateY(5px);
                    transform: translateY(5px);
                }
            }

            .i-youtube {
                display: inline-block;
                width: 40px;
                height: 40px;
                background: url(/SiteFiles/Images/youtube-icon.svg) no-repeat; 
            }

            .i-twitter {
                display: inline-block;
                width: 40px;
                height: 40px;
                background: url(/SiteFiles/Images/twitter-icon.svg) no-repeat; 
            }

            .i-linkedin {
                display: inline-block;
                width: 40px;
                height: 40px;
                background: url(/SiteFiles/Images/linkedin-icon.svg) no-repeat; 
            }

            .i-facebook {
                display: inline-block;
                width: 40px;
                height: 40px;
                background: url(/SiteFiles/Images/facebook-icon.svg) no-repeat;       
            }
        }
    }

    .footer-info {
        float: left;
        width: 50%;
    }
}

.InnerPage footer {
    display:block;
}


.cookie-panel {
    background: #171717;
    color: #fff;
    opacity: 0.9;
    bottom: 0;
    box-shadow: -1px -2px 2px 2px #999;
    display: none;
    padding: 20px;
    position: fixed;
    width: 100%;

    .cookie-close {
        position: absolute;
        right: 10px;
        top: 0px;
        .font-size(21);
        color: #fff;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;

        &:hover {
            color: @brand-font-green;
            -moz-transform: rotateZ(90deg);
            -ms-transform: rotateZ(90deg);
            -o-transform: rotateZ(90deg);
            -webkit-transform: rotateZ(90deg);
            transform: rotateZ(90deg);
        }
    }
}

.cookie-container{
    .cookie-panel-gdpr {
        background: #171717;
        opacity: 0.9;
        color: #fff;
        box-shadow: -1px -2px 2px 2px #999;
        padding: 20px;
        width: 100%;
        position: fixed;
        bottom: 0;
        z-index: 10;

        .cookie-close {
            position: absolute;
            right: 10px;
            top: 0px;
            .font-size(21);
            color: #fff;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;

            &:hover {
                color: @brand-font-green;
                -moz-transform: rotateZ(90deg);
                -ms-transform: rotateZ(90deg);
                -o-transform: rotateZ(90deg);
                -webkit-transform: rotateZ(90deg);
                transform: rotateZ(90deg);
            }
        }
    }
    .cookie-tab-ctrl{
        background: #171717;
        color: #fff;
        display: inline-block;
        padding: 13px;
        cursor: pointer;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 100;
    }

    @media (max-width: 991px){
      .cookie-panel-gdpr{
            padding-bottom: 50px;
      }
    }
}



.IE8 footer {
    display: block!important;
}


@media (max-width: 991px) {
    footer {
        padding-top: 20px;

        .footer-info img {
            width: 44px;
        }
    }
}

@media (max-width: 767px) {
    footer .green-btm .bottom-links {
        text-align: left;
        margin-top: 5px;
    }
}


@media (max-width: 550px) {
    footer {
        padding-top: 20px;

        address {
            margin-bottom: 10px;
        }

        .container {
            padding: 0;
        }

        .footer-links {
            width: 100%;
            text-align: center;
            margin: 10px 0 5px;
            background: #757575;
            -moz-border-radius: 11px;
            -webkit-border-radius: 11px;
            border-radius: 11px;
            -moz-transition: all 0.4s ease-in-out;
            -o-transition: all 0.4s ease-in-out;
            -webkit-transition: all 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;

            &.footer-open {
                background: #838383;

                h3 {
                    &:after {
                        -moz-transform: rotateZ(180deg);
                        -ms-transform: rotateZ(180deg);
                        -o-transform: rotateZ(180deg);
                        -webkit-transform: rotateZ(180deg);
                        transform: rotateZ(180deg);
                    }
                }
            }

            &:hover {
                background: #838383;
            }

            ul {
                display: none;

                li {
                    padding: 8px 0;

                    &:last-child {
                        margin-bottom: 7px;
                    }

                    &:hover {
                        background: #757575;

                        a {
                            color: @brand-orange;
                        }
                    }

                    a {
                        display: block;
                        .font-size(17);
                        -moz-transition: color 0.4s ease-in-out;
                        -o-transition: color 0.4s ease-in-out;
                        -webkit-transition: color 0.4s ease-in-out;
                        transition: color 0.4s ease-in-out;
                    }

                    &:before {
                        display: none;
                    }
                }
            }

            h3 {
                display: block;
                position: relative;
                text-align: center;
                cursor: pointer;
                margin: 0;
                padding: 12px 0px 9px;

                &:after {
                    font-family: 'FontAwesome';
                    content: '\f13a';
                    display: block;
                    font-size: 22px;
                    width: 22px;
                    height: 22px;
                    position: absolute;
                    top: 8px;
                    right: 18px;
                    -moz-transition: all 0.4s ease-in-out;
                    -o-transition: all 0.4s ease-in-out;
                    -webkit-transition: all 0.4s ease-in-out;
                    transition: all 0.4s ease-in-out;
                }
            }
        }

        .green-hover {
            &:hover h3 {
                .font-size(18);
                color: #fff;
            }
        }

        .social-links {
            padding: 16px 0 8px;

            h3 {
                display: none;
            }

            width: 100%;
            text-align: center;
        }

        .footer-info {
            h3 {
                display: none;
            }

            width: 100%;
            text-align: center;
        }
    }
}
