.ism-form__materials {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  border-top: 1px solid #707070;
  margin-top: 20px;
  margin-bottom: 30px;

  &--desktop {
    @media (max-width: @screen-tablet) {
      display: none;
    }
  }

  &--mobile {
    display: flex;
    @media (min-width: @screen-tablet) {
      display: none;
    }
  }

  @media (min-width: @screen-tablet) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.ism-material {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 12px;

  @media (min-width: @screen-tablet) {
    width: 45%;
  }

  &__container .ism-material {
    width: 100%;
  }
  
  &__title {
    font-size: 15px;
    color: #000000;
    font-weight: 700;
    padding-bottom: 10px;
  }

  &__media {
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    margin-right: 17px;
    flex: 0 0 32px;

    &--large {
      width: 80px;
      height: auto;
      flex: 0 0 auto;
    }

    &--loose-ground {
      background-image: url(/sitefiles/images/ism/materials/loose-ground.png);
    }

    &--soft-clay {
      background-image: url(/sitefiles/images/ism/materials/soft-clay.png);
    }

    &--loose-sand {
      background-image: url(/sitefiles/images/ism/materials/loose-sand.png);
    }

    &--peat {
      background-image: url(/sitefiles/images/ism/materials/peat.png);
    }

    &--silt {
      background-image: url(/sitefiles/images/ism/materials/silt.png);
    }

    &--road {
      background-image: url(/sitefiles/images/ism/materials/road.png);
    }

    &--cohesive-fill {
      background-image: url(/sitefiles/images/ism/materials/cohesive-fill.png);
    }

    &--dense-sand {
      background-image: url(/sitefiles/images/ism/materials/dense-sand.png);
    }

    &--stiff-clay {
      background-image: url(/sitefiles/images/ism/materials/stiff-clay.png);
    }

    &--rock {
      background-image: url(/sitefiles/images/ism/materials/rock.png);
    }

    &--loose-gravel {
      background-image: url(/sitefiles/images/ism/materials/loose-gravel.png);
    }
  }

  &__label {
    font-size: 15px;
  }
}
