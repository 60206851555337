@import '../partials/_mixins';
@import '../partials/_variables';

.tabbed-area {
    display: block;
    height: 100%;
    margin-bottom: 32px;
}

.section--info-promos .tabbed-area {
    height: 60%;
}

.tabbed-area__title {
    display: block;
    /*color: #ffffff;*/
    line-height: 1;
    text-align: center;
    text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.5);
}

.tabbed-area__navigation {
    display: none;
}

.tabbed-area__tab {
    display: block;
    width: 100%;
    outline: none;
    margin: 0;
    border: 0;
    padding: 8px 32px;
    /*background: #5f259f;
    color: #ffffff;*/
    line-height: 1.5;
    transition: background-color ease 0.3s, color ease 0.3s;
}

    .tabbed-area__tab--active, .tabbed-area__tab:focus, .tabbed-area__tab:hover {
        /*background: #ffffff;
        color: #5f259f;*/
    }

.tabbed-area__content {
    display: none;
    padding: 24px;
    height: 100%;
    width: 100%;
    border: 1px solid #859a3e;
    overflow: auto;
}

.tabbed-area__content--active {
    display: block;
}

button.tabbed-area__tab.tabbed-area__tab--active {
    background: url(/sitefiles/Images/nav-lines.png) repeat 0 0;
    color: #666;
}

.tabbed-area__content .row {
    display: block;
    margin: 0 -20px;
}

.tabbed-area__content .column {
    display: block;
    float: left;
    width: 50%;
    padding: 0 20px;
}

.tabbed-area__content img {
    height: auto !important;
    max-width: 100%;
}

@media screen and (min-width: 768px) {
    .tabbed-area__title {
        height: 80px;
        margin: 0;
        text-align: left;
    }

    .tabbed-area__navigation {
        display: inline-block;
        height: 64px;
    }

        .tabbed-area__navigation .tabbed-area__tab {
            display: inline-block;
        }

    .tabbed-area__tab {
        display: none;
        height: 100%;
        width: auto;
        outline: none;
        margin: 0;
        border: 0;
        padding: 0 32px;
        /*background: #5f259f;
        color: #ffffff;*/
        line-height: 1.33333;
        transition: background-color ease 0.3s, color ease 0.3s;
    }

        .tabbed-area__tab--active, .tabbed-area__tab:focus, .tabbed-area__tab:hover {
            /*background: #ffffff;
            color: #5f259f;*/
        }
}

@media screen and (min-width: 992px) {
    .tabbed-area__tab {
        line-height: 1;
    }
}

@media screen and (min-width: 1200px) {
    .tabbed-area__tab {
        line-height: 1.5;
    }
}
