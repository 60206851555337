@import '~chosen-js/chosen.min.css';
@import '~@vendor/owl-carousel/owl.carousel.css';
@import '~@vendor/owl-carousel/owl.theme.css';
@import '~@vendor/owl-carousel/owl.transitions.css';
@import '~@less/vendor/bootstrap.min.css';
.home-banner {
  position: relative;
}
@media (max-width: 991px) {
  .home-banner {
    top: 0!important;
  }
}
.basket .enquiry-form {
  padding-bottom: 50px;
}
.basket .basket-step {
  padding: 10px 0 0px;
  text-align: right;
  background: #f1f1f1 url(app/modules/~@imgOld/form-repeat.png) repeat 0px 0px;
  margin-bottom: 60px;
}
.basket .basket-step .choose-option {
  top: -151px;
  float: left;
  height: 50px;
  left: 0px;
}
.basket .basket-step .choose-option.grey {
  background-color: #575757;
}
.basket .basket-step .form-box {
  background: none;
  padding: 0;
}
.basket .basket-step .form-box input,
.basket .basket-step .form-box label,
.basket .basket-step .form-box .chosen-container,
.basket .basket-step .form-box textarea,
.basket .basket-step .form-box select {
  display: block;
  width: 100%;
  text-align: left;
}
.basket .basket-step .form-box select {
  height: 54px;
  margin-bottom: 30px;
}
.basket .basket-step .form-box.gdpr-consent {
  font-size: 1.28571429em;
  font-weight: normal;
  color: #575757;
  margin-bottom: 10px;
}
.basket .basket-step .container.with-button {
  position: relative;
}
.basket .basket-step .container.with-button .btn {
  position: absolute;
  bottom: -57px;
  right: 0;
}
.basket .basket-step .container.with-button .btn .open {
  display: inline-block;
}
.basket .basket-step .container.with-button .btn .proceed {
  display: none;
}
.basket .basket-step .basket-step-main {
  display: none;
}
.basket .basket-step .basket-step-main .ret-pass {
  display: none;
}
.basket .basket-step .basket-step-main .details-options {
  font-size: 1.28571429em;
}
.basket .basket-step .basket-step-main .details-options h2 {
  color: #859a3e;
}
.basket .basket-step .basket-step-main .details-options input[type='submit'] {
  width: 100%;
  position: relative;
  background-color: #ff9900;
  background-image: none;
  font-size: 1em;
  bottom: 5px;
}
.basket .basket-step .basket-step-main .details-options input[type='submit'].green {
  background-color: #859a3e;
}
.basket .basket-step .basket-step-main .details-options input[type='submit'].grey {
  color: #575757;
  background-color: transparent;
  border: 1px solid #ccc;
  padding: 11px 35px 12px 36px;
}
.basket .basket-step .basket-step-main .details-options table {
  background-color: transparent;
}
.basket .basket-step .basket-step-main .details-options table label {
  color: #859a3e;
}
.basket .basket-step .basket-step-main .details-options table input[type='text'],
.basket .basket-step .basket-step-main .details-options table input[type='email'] {
  margin: 5px 0 8px 0;
}
.basket .basket-step .basket-step-main .details-options td {
  border: none;
  padding: 0px;
}
.basket .basket-step .basket-step-main .details-options .LogonDialog {
  display: block !important;
  width: 100%;
  position: relative;
}
.basket .basket-step .basket-step-main .details-options .LogonDialog td {
  display: block !important;
  width: 100%;
  position: relative;
}
.basket .basket-step .basket-step-main .details-options .LogonDialog a {
  width: 100%;
  position: relative;
  background-color: #f90;
  display: block;
  background-image: none;
  color: #fff;
  padding: 12px 0px;
  text-align: center;
  font-size: 1em;
}
.basket .basket-step .basket-step-main .details-options .LogonDialog a:hover {
  border-radius: 10px;
}
.basket .basket-step .basket-step-main .details-options .LogonDialog .ErrorLabel {
  padding: 0px;
  background-color: transparent;
  border: none;
}
.basket .basket-step.active-step .container.with-button .btn .open {
  display: none;
}
.basket .basket-step.active-step .container.with-button .btn .proceed {
  display: inline-block;
}
.basket .basket-step.step2 .enquiry-btn i {
  left: 20px;
  top: 15px;
}
.basket .basket-step.step2 .enquiry-btn input[type='submit'] {
  float: right;
  position: relative;
  bottom: 0;
}
.basket .basket-step.step2 .btn {
  pointer-events: none;
}
.basket .basket-step.step2.active-step .btn {
  pointer-events: auto;
}
.basket .basket-step .grey-bar {
  width: 100%;
  display: block !important;
}
.basket .basket-step .grey-bar .green-bar {
  position: absolute;
  top: 0;
  right: 0;
  content: ' ';
  display: block;
  height: 8px;
  width: 30%;
  background: #859a3e;
}
.basket .basket-step .grey-bar .green-bar:before {
  position: absolute;
  top: 0;
  left: -30px;
  content: ' ';
  display: block;
  height: 8px;
  width: 35px;
  background: url(/sitefiles/images/green-edge.png) no-repeat 0px 0px;
}
.basket .basket-step .step {
  overflow: hidden;
  width: 0;
  -moz-transition: width 1.5s ease-in-out;
  -o-transition: width 1.5s ease-in-out;
  -webkit-transition: width 1.5s ease-in-out;
  transition: width 1.5s ease-in-out;
  -moz-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  margin-bottom: 40px;
  text-align: left;
}
.basket .basket-step .step .inner-step {
  display: inline-block;
  background: #859a3e;
  padding: 10px 20px 10px 0;
  white-space: nowrap;
}
.basket .basket-step .step .inner-step .step-number {
  display: inline-block;
  color: #fff;
  font-size: 1.28571429em;
}
.basket .basket-step .widget.featured-products .featured-product {
  margin-bottom: 10px;
  text-align: left;
}
.basket .basket-step .remove-item {
  margin-bottom: 15px;
  display: block;
  font-weight: bold;
  vertical-align: middle;
  text-decoration: none;
  text-align: left;
}
.basket .basket-step .remove-item .fa {
  color: #575757;
  margin-left: 10px;
  font-size: 120%;
  vertical-align: middle;
}
.basket .basket-step .remove-item:hover {
  color: #ff9900;
}
.basket .basket-step .remove-item:hover .fa {
  color: #ff9900;
}
.chosen-container {
  height: 54px;
  width: 100%!important;
  background: #fff;
  margin-bottom: 30px;
  font-size: 1em;
}
.chosen-container .chosen-single {
  border: none;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  background: #fff;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 54px;
}
.chosen-container .chosen-single div {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 48px;
  height: 100%;
  padding: 14px 10px;
}
.chosen-container .chosen-single div b {
  display: block;
  width: 26px;
  height: 26px;
  background: #729c2a url('/sitefiles/images/sprite.png') no-repeat -38px -48px;
}
.chosen-container .chosen-single span {
  display: block;
  height: 54px;
  line-height: 54px;
  color: #7b7b7b;
  padding-left: 7px;
  padding-right: 40px;
}
.chosen-container .chosen-drop {
  border: none;
}
.chosen-container .chosen-results li {
  height: 42px;
  line-height: 17px;
  background: #fff;
  color: #7b7b7b;
  padding-left: 12px;
  padding-right: 40px;
}
.chosen-container .chosen-results li:before {
  display: none;
}
.chosen-container .chosen-results li.highlighted {
  background: #729c2a;
}
.chosen-container-active.chosen-with-drop .chosen-single div b {
  background-position: -38px -48px;
  -moz-transform: rotateZ(180deg);
  -ms-transform: rotateZ(180deg);
  -o-transform: rotateZ(180deg);
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}
.chosen-container-active.chosen-with-drop .chosen-single {
  border: none;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  background: #fff;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
body {
  color: #666666;
  font-family: 'ff-dax-pro', Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'ff-dax-pro', Arial, sans-serif;
  font-size: 1.78571429em;
  margin: 0 0 20px 0;
}
h1 {
  font-size: 1.78571429em;
  color: #729c2a;
  font-weight: bold;
}
h1 .fa {
  margin-right: 10px;
  font-size: 140%;
}
h1 small {
  font-size: 60%;
  color: #729c2a;
}
h2 {
  font-size: 1.78571429em;
  color: #666666;
  font-weight: bold;
}
h3,
.BlogCommentsTitle {
  color: #729c2a;
  font-size: 1.78571429em;
  font-weight: bold;
}
h4 {
  font-size: 1.42857143em;
}
h5 {
  font-size: 1.28571429em;
}
h6 {
  font-size: 1.14285714em;
}
a {
  outline: none !important;
  color: #6f9d20;
}
a:focus,
a:hover {
  outline: none !important;
  color: #84bb26;
}
blockquote {
  border-left-color: #666666;
}
select {
  width: 100%;
  height: 40px;
  border-radius: 0;
  border: none;
}
.load-more-btn {
  background: #e4e9d4;
  color: #859a3e;
  padding: 5px 15px;
  margin-bottom: 40px;
}
.load-more-btn:hover {
  background: #859a3e;
  color: #e4e9d4;
}
.share-icons {
  float: right;
  display: inline-block;
  padding-top: 10px;
}
.load-overlay {
  display: none;
}
.HomePage .load-overlay {
  display: block;
  z-index: 9999;
  background: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.HomePage .load-overlay .load-icon {
  display: block;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -40px 0 0 -40px;
}
.fade-in {
  opacity: 1 !important;
  top: 0 !important;
}
.alLeft {
  text-align: left;
}
.alCenter {
  text-align: center;
}
.alRight {
  text-align: right;
}
.inner-content {
  margin-bottom: 45px;
}
.inner-content .grey-bar {
  margin-top: 45px;
}
.InnerPage .main-content {
  padding-top: 15px;
}
.breadcrumbs {
  margin: 20px 0 10px;
}
.breadcrumbs a,
.breadcrumbs span {
  color: #575757;
  text-decoration: none;
  margin-right: 30px;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
}
.breadcrumbs a:after,
.breadcrumbs span:after {
  content: '\f054';
  display: block;
  position: absolute;
  font-family: 'FontAwesome';
  top: 0;
  right: -20px;
  color: #859a3e;
  font-size: 0.8em;
}
.breadcrumbs a.CMSBreadCrumbsCurrentItem:after,
.breadcrumbs span.CMSBreadCrumbsCurrentItem:after {
  content: '\f078';
  right: -18px;
  top: -2px;
}
.breadcrumbs a:hover,
.breadcrumbs span:hover {
  color: #859a3e;
}
.breadcrumbs .hdn {
  display: none;
}
.breadcrumbs + img,
.top-image {
  margin-bottom: 15px;
}
.striped-bg-faint-line {
  padding: 30px 0 25px;
  background: url(app/modules/~@imgOld/faint-line-repeat.png) repeat 0px 0px;
}
.striped-bg-faint-line label {
  font-size: 1.17857143em;
  margin: 16px 6px 0 0;
  display: block;
  float: left;
}
.striped-bg-faint-line .info-strips {
  display: block;
  float: left;
  margin: 0;
}
.striped-bg-faint-line .strip {
  display: inline-block;
  padding: 14px 25px;
  margin: 0 0 0 16px;
}
img {
  max-width: 100%;
}
.marg-btm {
  margin-bottom: 15px;
}
.swipe-table {
  display: none;
  margin-bottom: 8px;
  font-weight: bold;
}
#page-wrapper {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  background: #f2f2f2;
}
.inner-wrapper {
  background: #fff;
  min-height: 100vh;
  position: relative;
}
input[type='submit'],
.btn {
  position: relative;
  outline: none;
  width: auto;
  float: none;
  background: #729c2a url(app/modules/~@imgOld/submit-arrow.png) no-repeat left center;
  color: #fff;
  font-size: 1.42857143em;
  padding: 12px 44px 12px 18px;
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  margin-bottom: 15px;
}
input[type='submit'].btn-orange,
.btn.btn-orange {
  background: #ff9900 url(app/modules/~@imgOld/submit-arrow.png) no-repeat right center;
}
input[type='submit'].btn-orange:hover,
.btn.btn-orange:hover,
input[type='submit'].btn-orange:focus,
.btn.btn-orange:focus {
  background: #ffad33 url(app/modules/~@imgOld/submit-arrow.png) no-repeat right center;
}
input[type='submit'].btn-grey,
.btn.btn-grey {
  background: #575757 url(app/modules/~@imgOld/submit-arrow.png) no-repeat right center;
}
input[type='submit'].btn-grey:hover,
.btn.btn-grey:hover,
input[type='submit'].btn-grey:focus,
.btn.btn-grey:focus {
  background: #717171 url(app/modules/~@imgOld/submit-arrow.png) no-repeat right center;
}
input[type='submit'].back-arrow,
.btn.back-arrow {
  background-image: url(app/modules/~@imgOld/back-arrow.png);
}
input[type='submit'].back-arrow.back-align-left,
.btn.back-arrow.back-align-left {
  background-position-x: 3px;
}
input[type='submit'].with-icon,
.btn.with-icon {
  padding: 12px 18px 12px;
  background-image: none !important;
}
input[type='submit'].with-icon .fa,
.btn.with-icon .fa {
  padding-right: 15px;
}
input[type='submit'].no-arrow,
.btn.no-arrow {
  background-image: none !important;
}
.enquiry-btn {
  float: right;
  min-width: 163px;
  margin: 0px;
  position: relative;
  right: 0;
}
.enquiry-btn .fa {
  position: absolute;
  z-index: 15;
  left: 0px;
  top: 23px;
  color: #fff;
  font-size: 1.28571429em;
}
.enquiry-btn .fa .pad-right {
  left: -60px;
  top: 20px;
}
.enquiry-btn input[type='submit'] {
  content: '\f07a';
  background: #ff9900 url(app/modules/~@imgOld/submit-arrow.png) no-repeat right center;
  padding: 12px 18px 12px;
  background-image: none !important;
  padding-left: 50px;
}
.enquiry-btn input[type='submit']:hover {
  background: #ffad33 url(app/modules/~@imgOld/submit-arrow.png) no-repeat right center;
}
.placeholder {
  color: #c6c6c6;
}
.main-content ul {
  padding: 0;
}
.main-content ul li {
  position: relative;
  color: #666666;
  list-style-type: none;
  padding-left: 16px;
}
.main-content ul li:before {
  display: block;
  position: absolute;
  top: calc(0.5em - 3px);
  left: 0px;
  width: 10px;
  height: 10px;
  content: ' ';
  border-radius: 50%;
  background-color: #859a3e;
}
.main-content ul li a {
  color: #666666;
}
.main-content ul li a:hover {
  color: #729c2a;
}
.main-content blockquote {
  border-left-color: #729c2a;
  font-size: 1.21428571em;
}
.main-content blockquote footer {
  background: none;
}
.main-content .table th {
  color: #729c2a;
  font-size: 1.28571429em;
  font-weight: normal;
  border-right: 1px solid #859a3e;
  border-bottom: 2px solid #859a3e;
  padding-bottom: 3px;
  padding-left: 15px;
}
.main-content .table th:last-child {
  /*border-right: none;*/
}
.main-content .table td {
  font-size: 1.14285714em;
}
.main-content .table tr td {
  border-right: 1px solid #859a3e;
  border-bottom: 1px solid #859a3e;
  border-top: none;
  padding: 15px 10px 3px;
}
.main-content .table tr td:last-child {
  /*border-right: none;*/
}
.main-content .table tr .td-no-border {
  border: none;
}
.blog-replys .CommentDetail {
  background-color: #fcfcfc;
  /*#f90 !important;*/
  font-family: 'ff-dax-pro', Arial, sans-serif;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin: 5px;
  padding: 5px;
}
.blog-replys .CommentDetail:nth-child(2n + 1) {
  background-color: #fdfdfd !important;
}
.CommentDetail .table-responsive .table {
  border-collapse: collapse;
}
/*User name and admin controls row in a comment*/
.CommentDetail .table tr:nth-child(2n + 1) {
  background-color: #859a3e !important;
  color: #fff;
}
.CommentDetail .table tr:nth-child(3n + 1) {
  background-color: #ebebeb !important;
  color: #859a3e;
}
.HomePage .content-wrap {
  opacity: 1;
}
.animate-with-dropdown {
  position: relative;
}
.site-offline header {
  position: relative !important;
  z-index: 3000;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  opacity: 1 !important;
  display: block !important;
}
.site-offline header .logo {
  left: 0;
}
.site-offline .error-wrap {
  top: 82px;
}
.site-offline .grey-bar {
  width: 100% !important;
}
.main-content .grey-bar {
  width: 100%;
}
@media (max-width: 1199px) {
  .striped-bg-faint-line label {
    float: none;
    margin: 0 0 10px 0;
  }
  .striped-bg-faint-line {
    float: none;
    display: table;
    width: 100%;
  }
  .striped-bg-faint-line .strip {
    display: table-cell;
    margin: 0;
    background: none;
    padding: 0 2px;
  }
  .striped-bg-faint-line .strip .strip-content {
    padding: 14px 10px;
    background: #e3e3e3;
  }
}
@media (max-width: 991px) {
  .content-wrap {
    opacity: 1 !important;
  }
  .HomePage .content-wrap {
    margin-top: 0 !important;
  }
  .container.no-tab-pad {
    width: 100%;
    padding: 0;
  }
}
@media (max-width: 767px) {
  .share-icons {
    float: none;
    display: block;
    padding-top: 10px;
  }
  .swipe-table {
    display: block;
  }
  .site-offline header .logo {
    left: 10px;
    width: 300px;
  }
  .inner-content {
    margin-bottom: 25px;
  }
  .mobGap {
    margin-bottom: 25px;
  }
  .striped-bg-faint-line .info-strips {
    display: block;
  }
  .striped-bg-faint-line .info-strips .strip {
    display: block;
    float: left;
    margin: 0 2px 2px 0;
    padding: 0;
    width: 48%;
  }
  .striped-bg-faint-line .info-strips .strip .strip-content {
    padding: 14px 10px;
  }
}
@media (max-width: 480px) {
  select {
    width: 100% !important;
    height: 40px;
  }
  .site-offline .error-wrap {
    margin-bottom: 300px;
  }
  #advertise-carousel {
    display: none !important;
  }
}
@media (max-width: 450px) {
  .inner-wrapper.move-wrapper {
    -moz-transform: translateX(244px);
    -ms-transform: translateX(244px);
    -o-transform: translateX(244px);
    -webkit-transform: translateX(244px);
    transform: translateX(244px);
  }
  .striped-bg-faint-line .info-strips .strip {
    float: none;
    margin: 0 0 2px 0;
    padding: 0;
    width: 100%;
  }
  .striped-bg-faint-line .info-strips .strip .strip-content {
    padding: 14px 10px;
  }
  .btn-orange {
    clear: both;
    right: 30px;
  }
}
.hdn {
  display: none;
}
/* CMS Desk */
.EditMode .homepage-signposts .EditorWidget.WebPartInner {
  float: left;
  width: 20%;
}
.EditMode .homepage-signposts .EditorWidget.WebPartInner .WebPartContent .signpost-item {
  width: 100%;
}
.EditMode .striped-bg-faint-line .EditorWidget.WebPartInner {
  float: left;
}
.my-profile-logout {
  text-align: right;
  margin-bottom: 39px;
}
.orig {
  display: none;
}
.load-more-btn {
  display: none;
}
@media screen and (max-width: 767px) {
  .table-responsive {
    border: 0px solid !important;
    /*overflow-x: hidden;*/
  }
  .col-sm-6.col-md-4.guest-checkout {
    padding-top: 25px;
  }
}
.option-btn {
  max-width: 188px;
}
.no-js-div {
  display: none !important;
}
.enquire-now {
  display: none !important;
}
.consent-item {
  margin-bottom: 34px;
}
.consent-item .btn {
  margin-bottom: 20px;
}
.consent-item .btn-manual-revoke {
  display: inline-block;
  position: relative;
  margin: 0;
  border: 0;
  padding: 12px 36px;
  color: #fff;
  background-color: #ff9900;
  font-size: 1.28571429em;
  vertical-align: middle;
  box-sizing: border-box;
  border-radius: 0;
  transition: background 0.3s ease;
  margin-bottom: 20px;
}
.consent-item .grey-bar:last-of-type {
  display: none;
}
.table-responsive .CaptchaTable {
  width: auto;
}
.table-responsive .CaptchaTable td {
  border: none !important;
}
.table-responsive .CaptchaTable td img {
  padding-top: 30px;
}
.lazy-load {
  opacity: 0;
  transition: opacity 0.25s ease;
  will-change: opacity;
}
.lazy-load.loaded {
  opacity: 1;
}
.mob-only {
  display: none !important;
}
@media screen and (max-width: 768px) {
  .mob-only {
    display: inherit !important;
  }
}
.map {
  position: relative;
  margin-bottom: 90px;
}
.map .inner-map {
  width: 615px;
  margin: 0 auto;
}
.map .grey-bar {
  margin-top: 30px;
}
.map .map-popup {
  width: 615px;
  display: none;
  position: absolute;
  z-index: 4000;
  top: 0px;
  left: 0px;
  border: 1px solid #859a3e;
  background: #fff;
  padding: 25px 0;
}
.map .map-popup .popup-content {
  display: none;
  position: relative;
}
.map .map-popup .popup-content .close-btn {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 1.78571429em;
  position: absolute;
  padding: 10px;
  top: -25px;
  right: 0px;
  cursor: pointer;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.map .map-popup .popup-content .close-btn:before {
  display: block;
  color: #575757;
  content: '\f00d';
  font-family: 'FontAwesome';
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.map .map-popup .popup-content .close-btn:hover {
  -moz-transform: rotateZ(90deg);
  -ms-transform: rotateZ(90deg);
  -o-transform: rotateZ(90deg);
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}
.map .map-popup .popup-content .close-btn:hover:before {
  color: #859a3e;
}
.map .map-popup .popup-content.active {
  display: block;
}
.map .map-popup.opened {
  -moz-animation: popupbounce 0.4s ease-in-out;
  -o-animation: popupbounce 0.4s ease-in-out;
  -webkit-animation: popupbounce 0.4s ease-in-out;
  animation: popupbounce 0.4s ease-in-out;
}
.map .map-popup:after {
  position: absolute;
  bottom: -15px;
  left: 200px;
  content: ' ';
  display: block;
  background: url('/sitefiles/images/sprite.png') no-repeat -83px -54px;
  width: 30px;
  height: 15px;
}
.map .map-popup .popup-box {
  width: 33%;
  float: left;
}
.map .map-popup p {
  margin-top: 0;
  font-size: 0.92857143em;
  padding-left: 32px;
}
.map .map-popup ul {
  padding-left: 32px;
  margin: 0;
}
.map .map-popup address,
.map .map-popup .directions {
  font-size: 0.92857143em;
  padding-left: 24%;
}
.map .map-popup label {
  padding-right: 8px;
}
.map .map-popup h4 {
  display: inline-block;
  font-size: 1.28571429em;
  color: #fff;
  background: #859a3e;
  padding: 10px 20px 10px 32px;
  margin-bottom: 10px;
}
.map .map-point {
  cursor: pointer;
}
.map .map-point .path {
  fill: #FE9903;
  -moz-animation: fillpath 2s ease-in-out infinite;
  -o-animation: fillpath 2s ease-in-out infinite;
  -webkit-animation: fillpath 2s ease-in-out infinite;
  animation: fillpath 2s ease-in-out infinite;
}
.IE8 .map .map-popup {
  position: relative!important;
  left: 0!important;
  top: 0!important;
}
.IE8 .map .map-popup:first-child {
  display: block;
}
.IE8 .map #Layer_1 img {
  display: none;
}
@media (max-width: 991px) {
  .map .inner-map {
    width: 100%;
  }
  .map svg {
    width: 100%;
  }
  .map .map-popup {
    width: 442px;
  }
  .map .map-popup a {
    -ms-word-break: break-word;
    word-break: break-word;
  }
  .map .map-popup label {
    display: block;
    max-width: 100%;
    margin-bottom: 0px;
  }
  .map .map-popup p {
    padding-left: 19px;
  }
  .map .map-popup ul {
    padding-left: 17px;
  }
  .map .map-popup h4 {
    padding: 6px 11px 4px 17px;
  }
  .map .map-popup:after {
    left: 130px;
  }
}
@media (max-width: 767px) {
  .map {
    margin-bottom: 25px;
  }
  .map .map-popup {
    position: relative;
    top: 0!important;
    left: 0!important;
    width: 100%;
  }
  .map .map-popup.opened {
    -moz-animation: none;
    -o-animation: none;
    -webkit-animation: none;
    animation: none;
  }
  .map .map-popup:after {
    display: none;
  }
  .map svg {
    display: none;
  }
  .map-filter h3 {
    position: relative;
    cursor: pointer;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .map-filter h3.slide-open {
    background: #9eb64a !important;
  }
  .map-filter h3.slide-open:before {
    -moz-transform: rotateZ(180deg);
    -ms-transform: rotateZ(180deg);
    -o-transform: rotateZ(180deg);
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
  }
  .map-filter h3:hover {
    background: #9eb64a !important;
  }
  .map-filter h3:before {
    position: absolute;
    top: 12px;
    right: 12px;
    content: '\f0ab';
    font-family: 'FontAwesome';
    font-size: 1.71428571em;
    background: none;
    width: 30px;
    height: 30px;
    display: block;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .map-filter .link-wrap {
    display: none;
  }
}
@media (max-width: 500px) {
  .map .map-popup .info-row .popup-box {
    width: 50%;
    padding-right: 15px;
  }
  .map .map-popup .info-row .popup-box address,
  .map .map-popup .info-row .popup-box .directions {
    padding-left: 19px;
  }
  .map .map-popup .info-row .popup-box:first-child {
    width: 100%;
  }
  .map .map-popup .products-row .popup-box {
    width: 50%;
  }
}
@-moz-keyframes popupbounce {
  0% {
    -moz-transform: scale(1) translateY(0%);
    -ms-transform: scale(1) translateY(0%);
    -o-transform: scale(1) translateY(0%);
    -webkit-transform: scale(1) translateY(0%);
    transform: scale(1) translateY(0%);
  }
  50% {
    -moz-transform: scale(1.1) translateY(-5%) translateX(2%);
    -ms-transform: scale(1.1) translateY(-5%) translateX(2%);
    -o-transform: scale(1.1) translateY(-5%) translateX(2%);
    -webkit-transform: scale(1.1) translateY(-5%) translateX(2%);
    transform: scale(1.1) translateY(-5%) translateX(2%);
  }
  100% {
    -moz-transform: scale(1) translateY(0%);
    -ms-transform: scale(1) translateY(0%);
    -o-transform: scale(1) translateY(0%);
    -webkit-transform: scale(1) translateY(0%);
    transform: scale(1) translateY(0%);
  }
}
@-ms-keyframes popupbounce {
  0% {
    -moz-transform: scale(1) translateY(0%);
    -ms-transform: scale(1) translateY(0%);
    -o-transform: scale(1) translateY(0%);
    -webkit-transform: scale(1) translateY(0%);
    transform: scale(1) translateY(0%);
  }
  50% {
    -moz-transform: scale(1.1) translateY(-5%) translateX(2%);
    -ms-transform: scale(1.1) translateY(-5%) translateX(2%);
    -o-transform: scale(1.1) translateY(-5%) translateX(2%);
    -webkit-transform: scale(1.1) translateY(-5%) translateX(2%);
    transform: scale(1.1) translateY(-5%) translateX(2%);
  }
  100% {
    -moz-transform: scale(1) translateY(0%);
    -ms-transform: scale(1) translateY(0%);
    -o-transform: scale(1) translateY(0%);
    -webkit-transform: scale(1) translateY(0%);
    transform: scale(1) translateY(0%);
  }
}
@-webkit-keyframes popupbounce {
  0% {
    -moz-transform: scale(1) translateY(0%);
    -ms-transform: scale(1) translateY(0%);
    -o-transform: scale(1) translateY(0%);
    -webkit-transform: scale(1) translateY(0%);
    transform: scale(1) translateY(0%);
  }
  50% {
    -moz-transform: scale(1.1) translateY(-5%) translateX(2%);
    -ms-transform: scale(1.1) translateY(-5%) translateX(2%);
    -o-transform: scale(1.1) translateY(-5%) translateX(2%);
    -webkit-transform: scale(1.1) translateY(-5%) translateX(2%);
    transform: scale(1.1) translateY(-5%) translateX(2%);
  }
  100% {
    -moz-transform: scale(1) translateY(0%);
    -ms-transform: scale(1) translateY(0%);
    -o-transform: scale(1) translateY(0%);
    -webkit-transform: scale(1) translateY(0%);
    transform: scale(1) translateY(0%);
  }
}
@keyframes popupbounce {
  0% {
    -moz-transform: scale(1) translateY(0%);
    -ms-transform: scale(1) translateY(0%);
    -o-transform: scale(1) translateY(0%);
    -webkit-transform: scale(1) translateY(0%);
    transform: scale(1) translateY(0%);
  }
  50% {
    -moz-transform: scale(1.1) translateY(-5%) translateX(2%);
    -ms-transform: scale(1.1) translateY(-5%) translateX(2%);
    -o-transform: scale(1.1) translateY(-5%) translateX(2%);
    -webkit-transform: scale(1.1) translateY(-5%) translateX(2%);
    transform: scale(1.1) translateY(-5%) translateX(2%);
  }
  100% {
    -moz-transform: scale(1) translateY(0%);
    -ms-transform: scale(1) translateY(0%);
    -o-transform: scale(1) translateY(0%);
    -webkit-transform: scale(1) translateY(0%);
    transform: scale(1) translateY(0%);
  }
}
@-moz-keyframes fillpath {
  0% {
    fill: #FE9903;
  }
  50% {
    fill: #cccccc;
  }
  100% {
    fill: #FE9903;
  }
}
@-ms-keyframes fillpath {
  0% {
    fill: #FE9903;
  }
  50% {
    fill: #cccccc;
  }
  100% {
    fill: #FE9903;
  }
}
@-webkit-keyframes fillpath {
  0% {
    fill: #FE9903;
  }
  50% {
    fill: #cccccc;
  }
  100% {
    fill: #FE9903;
  }
}
@keyframes fillpath {
  0% {
    fill: #FE9903;
  }
  50% {
    fill: #cccccc;
  }
  100% {
    fill: #FE9903;
  }
}
.error-wrap {
  position: relative;
}
.error-wrap .error-text {
  text-align: center;
  font-size: 1.71428571em;
  line-height: 28px;
  font-weight: bold;
  margin: 300px 0 200px;
}
.error-wrap .error-text a {
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  text-decoration: none;
  position: relative;
}
.error-wrap .error-text a:before {
  display: block;
  content: ' ';
  width: 100%;
  height: 2px;
  background: #84bb26;
  position: absolute;
  z-index: 15;
  bottom: 16px;
  opacity: 0;
  left: 0px;
  right: 0;
  margin: 0 auto;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.error-wrap .error-text a:hover:before {
  opacity: 1;
  bottom: 0px;
}
.error-wrap .error-banner {
  position: absolute;
  top: -260px;
  left: 380px;
}
.error-wrap .error-banner .you-seem-lost {
  position: relative;
  left: -1px;
  -moz-transform-origin: center top;
  -webkit-animation: swing 4s infinite ease-in-out;
  -o-animation: swing 4s infinite ease-in-out;
  -moz-animation: swing 4s infinite ease-in-out;
  animation: swing 4s infinite ease-in-out;
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  -o-transform-origin: top;
  transform-origin: top;
}
.error-wrap .error-banner .you-seem-lost__container {
  position: absolute;
  top: 50%;
  left: 12%;
  transform: skewY(-4deg);
  width: 290px;
  text-align: center;
}
.error-wrap .error-banner .you-seem-lost__container h3 {
  font-size: 30px;
  color: #fff;
}
@media (max-width: 768px) {
  .error-wrap .error-banner .you-seem-lost__container {
    width: 230px;
  }
  .error-wrap .error-banner .you-seem-lost__container h3 {
    font-size: 20px;
  }
}
@media (max-width: 362px) {
  .error-wrap .error-banner .you-seem-lost__container {
    width: 180px;
  }
}
.error-wrap .error-banner .pin-image {
  position: absolute;
  top: -34px;
  left: 155px;
  z-index: 10;
}
.error-wrap .error-img-left {
  position: absolute;
  top: -95px;
  left: 150px;
}
.error-wrap .error-img-right {
  position: absolute;
  top: -139px;
  right: -41px;
}
@media (max-width: 1199px) {
  .error-wrap .error-img-left {
    left: 63px;
  }
  .error-wrap .error-img-right {
    top: -92px;
    right: -27px;
    width: 318px;
  }
  .error-wrap .error-banner {
    top: -260px;
    left: 300px;
  }
}
@media (max-width: 991px) {
  .error-wrap .error-banner {
    top: -260px;
    left: 186px;
  }
  .error-wrap .error-text {
    font-size: 1.42857143em;
  }
  .error-wrap .error-img-left {
    left: -21px;
  }
  .error-wrap .error-img-right {
    top: -42px;
    right: -35px;
    width: 236px;
  }
}
@media (max-width: 767px) {
  .error-wrap .error-banner {
    left: 50%;
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 294px;
    top: -209px;
  }
  .error-wrap .error-text {
    margin: 260px 0 200px;
  }
  .error-wrap .error-banner .pin-image {
    left: 111px;
    z-index: 10;
  }
  .error-wrap .error-img-left {
    top: auto;
    bottom: -200px;
    left: 10px;
    z-index: -1;
  }
  .error-wrap .error-img-right {
    top: 90px;
    right: 10px;
    width: 230px;
    z-index: -1;
  }
}
@media (max-width: 480px) {
  .error-wrap .error-img-right {
    top: 144px;
    right: 10px;
    width: 154px;
    z-index: -1;
  }
  .error-wrap .error-img-left {
    left: 10px;
    z-index: -1;
    width: 133px;
  }
}
@media (max-width: 400px) {
  .error-wrap .error-text {
    margin: 260px 0 160px;
  }
  .error-wrap .error-img-right {
    top: 104px;
  }
  .error-wrap .error-img-left {
    bottom: -162px;
  }
}
@media (max-width: 362px) {
  .error-wrap .error-img-right {
    right: -12px;
  }
  .error-wrap .error-img-left {
    left: -12px;
  }
  .error-wrap .error-banner .pin-image {
    left: 90px;
    top: -37px;
  }
  .error-wrap .error-banner {
    width: 242px;
    top: -193px;
  }
}
@-moz-keyframes swing {
  0% {
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  50% {
    -moz-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  100% {
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
}
@-ms-keyframes swing {
  0% {
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  50% {
    -moz-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  100% {
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
}
@-webkit-keyframes swing {
  0% {
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
}
@keyframes swing {
  0% {
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  50% {
    -moz-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  100% {
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
}
/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  margin: 0;
  padding: 0;
  outline: none;
  border: 0;
  vertical-align: top;
}
.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020;
}
.fancybox-skin {
  position: relative;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
}
.fancybox-opened {
  z-index: 8030;
}
.fancybox-opened .fancybox-skin {
  -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}
.fancybox-outer,
.fancybox-inner {
  position: relative;
}
.fancybox-inner {
  overflow: hidden;
}
.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}
.fancybox-error {
  margin: 0;
  padding: 15px;
  color: #444;
  white-space: nowrap;
  font: 14px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.fancybox-image,
.fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}
.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}
#fancybox-loading,
.fancybox-close,
.fancybox-prev span,
.fancybox-next span {
  background-image: url('/sitefiles/images/fancybox/fancybox_sprite.png');
}
#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 8060;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
}
#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url('/sitefiles/images/fancybox/fancybox_loading.gif') center center no-repeat;
}
.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  z-index: 8040;
  width: 36px;
  height: 36px;
  cursor: pointer;
}
.fancybox-nav {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  z-index: 8040;
  width: 40%;
  height: 100%;
  text-decoration: none;
  cursor: pointer;
  /* helps IE */
}
.fancybox-prev {
  left: 0;
}
.fancybox-next {
  right: 0;
}
.fancybox-nav span {
  position: absolute;
  top: 50%;
  z-index: 8040;
  visibility: hidden;
  margin-top: -18px;
  width: 36px;
  height: 34px;
  cursor: pointer;
}
.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px;
}
.fancybox-next span {
  right: 10px;
  background-position: 0 -72px;
}
.fancybox-nav:hover span {
  visibility: visible;
}
.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  overflow: visible !important;
  max-width: 99999px;
  max-height: 99999px;
}
/* Overlay helper */
.fancybox-lock {
  overflow: hidden !important;
  width: auto;
}
.fancybox-lock body {
  overflow: hidden !important;
}
.fancybox-lock-test {
  overflow-y: hidden !important;
}
.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8010;
  display: none;
  overflow: hidden;
  background: url('/sitefiles/images/fancybox/fancybox_overlay.png') repeat;
}
.fancybox-overlay-fixed {
  position: fixed;
  right: 0;
  bottom: 0;
}
.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}
/* Title helper */
.fancybox-title {
  position: relative;
  z-index: 8050;
  visibility: hidden;
  text-shadow: none;
  font: normal 13px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.fancybox-opened .fancybox-title {
  visibility: visible;
}
.fancybox-title-float-wrap {
  position: absolute;
  right: 50%;
  bottom: 0;
  z-index: 8050;
  margin-bottom: -35px;
  text-align: center;
}
.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.8);
  background: transparent;
  color: #FFF;
  text-shadow: 0 1px 2px #222;
  white-space: nowrap;
  font-weight: bold;
  line-height: 24px;
  /* Fallback for web browsers that doesn't support RGBa */
}
.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}
.fancybox-title-inside-wrap {
  padding-top: 10px;
}
.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
}
/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading,
  .fancybox-close,
  .fancybox-prev span,
  .fancybox-next span {
    background-image: url('/sitefiles/images/fancybox/fancybox_sprite@2x.png');
    -moz-background-size: 44px 152px;
    -o-background-size: 44px 152px;
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/
  }
  #fancybox-loading div {
    background-image: url('/sitefiles/images/fancybox/fancybox_loading@2x.gif');
    -moz-background-size: 24px 24px;
    -o-background-size: 24px 24px;
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/
  }
}
#fancybox-buttons {
  position: fixed;
  left: 0;
  z-index: 8050;
  width: 100%;
}
#fancybox-buttons.top {
  top: 10px;
}
#fancybox-buttons.bottom {
  bottom: 10px;
}
#fancybox-buttons ul {
  display: block;
  margin: 0 auto;
  padding: 0;
  width: 166px;
  height: 30px;
  border: 1px solid #111;
  border-radius: 3px;
  background: -moz-linear-gradient(top, #444444 0%, #343434 50%, #292929 50%, #333333 100%);
  background: -ms-linear-gradient(top, #444444 0%, #343434 50%, #292929 50%, #333333 100%);
  background: -o-linear-gradient(top, #444444 0%, #343434 50%, #292929 50%, #333333 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #444444), color-stop(50%, #343434), color-stop(50%, #292929), color-stop(100%, #333333));
  background: -webkit-linear-gradient(top, #444444 0%, #343434 50%, #292929 50%, #333333 100%);
  background: linear-gradient(top, #444444 0%, #343434 50%, #292929 50%, #333333 100%);
  background: #323232;
  -moz-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.05);
  -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.05);
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.05);
  list-style: none;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#444444', endColorstr='#222222', GradientType=0);
}
#fancybox-buttons ul li {
  float: left;
  margin: 0;
  padding: 0;
}
#fancybox-buttons a {
  display: block;
  width: 30px;
  height: 30px;
  outline: none;
  background-color: transparent;
  background-image: url('/sitefiles/images/fancybox/fancybox_buttons.png');
  background-repeat: no-repeat;
  text-indent: -9999px;
  opacity: 0.8;
}
#fancybox-buttons a:hover {
  opacity: 1;
}
#fancybox-buttons a.btnPrev {
  background-position: 5px 0;
}
#fancybox-buttons a.btnNext {
  border-right: 1px solid #3e3e3e;
  background-position: -33px 0;
}
#fancybox-buttons a.btnPlay {
  background-position: 0 -30px;
}
#fancybox-buttons a.btnPlayOn {
  background-position: -30px -30px;
}
#fancybox-buttons a.btnToggle {
  width: 35px;
  border-right: 1px solid #3e3e3e;
  border-left: 1px solid #111;
  background-position: 3px -60px;
}
#fancybox-buttons a.btnToggleOn {
  background-position: -27px -60px;
}
#fancybox-buttons a.btnClose {
  width: 35px;
  border-left: 1px solid #111;
  background-position: -56px 0px;
}
#fancybox-buttons a.btnDisabled {
  opacity: 0.4;
  cursor: default;
}
footer {
  background: #575757;
  color: #fff;
  padding-top: 30px;
  display: none;
}
footer h3 {
  font-size: 1.28571429em;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
footer .green-hover {
  /*&:hover {
            > h3 {
                color: #859A3E;
                .font-size(19);
            }
        }*/
}
footer .green-hover h3 {
  color: #fff;
  font-weight: normal;
}
footer .green-btm {
  background: #859a3e;
  margin-top: 40px;
  padding: 15px 0;
}
footer .green-btm .bottom-links {
  text-align: right;
}
footer .green-btm .bottom-links ul {
  padding: 0;
  margin: 0;
}
footer .green-btm .bottom-links ul li {
  list-style-type: none;
  display: inline-block;
  position: relative;
  padding-right: 14px;
}
footer .green-btm .bottom-links ul li:before {
  content: '|';
  display: block;
  color: #fff;
  position: absolute;
  top: 0px;
  right: 4px;
}
footer .green-btm .bottom-links ul li:last-child {
  padding-right: 0;
}
footer .green-btm .bottom-links ul li:last-child:before {
  display: none;
}
footer .green-btm .bottom-links ul li a {
  text-decoration: none;
  color: #fff;
}
footer .green-btm .bottom-links ul li a:hover {
  text-decoration: underline;
}
footer .footer-links {
  float: left;
  width: 50%;
}
footer .footer-links ul {
  padding: 0;
  margin: 0;
}
footer .footer-links ul li {
  list-style-type: none;
  padding-left: 9px;
  position: relative;
}
footer .footer-links ul li:before {
  display: block;
  content: ' ';
  background: #fff;
  width: 3px;
  height: 3px;
  position: absolute;
  top: 8px;
  left: 0px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
footer .footer-links ul li:hover:before {
  background: #729c2a;
  width: 2px;
  height: 14px;
  position: absolute;
  top: 2px;
  left: 0px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 0;
}
footer .footer-links ul li a {
  color: #fff;
  text-decoration: none;
}
footer .social-links {
  float: left;
  width: 50%;
}
footer .social-links .social-link {
  width: 40px;
  height: 40px;
  display: inline-block;
  margin-right: 6px;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -moz-transition-property: transform;
  -o-transition-property: transform;
  transition-property: transform;
}
footer .social-links .social-link:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform, opacity;
  -moz-transition-property: transform, opacity;
  -o-transition-property: transform, opacity;
  transition-property: transform, opacity;
}
footer .social-links .social-link:hover {
  -webkit-transform: translateY(-5px);
  -moz-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  transform: translateY(-5px);
}
footer .social-links .social-link:hover:before {
  opacity: 1;
  -webkit-transform: translateY(5px);
  -moz-transform: translateY(5px);
  -ms-transform: translateY(5px);
  -o-transform: translateY(5px);
  transform: translateY(5px);
}
footer .social-links .social-link .i-youtube {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: url(/SiteFiles/Images/youtube-icon.svg) no-repeat;
}
footer .social-links .social-link .i-twitter {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: url(/SiteFiles/Images/twitter-icon.svg) no-repeat;
}
footer .social-links .social-link .i-linkedin {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: url(/SiteFiles/Images/linkedin-icon.svg) no-repeat;
}
footer .social-links .social-link .i-facebook {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: url(/SiteFiles/Images/facebook-icon.svg) no-repeat;
}
footer .footer-info {
  float: left;
  width: 50%;
}
.InnerPage footer {
  display: block;
}
.cookie-panel {
  background: #171717;
  color: #fff;
  opacity: 0.9;
  bottom: 0;
  box-shadow: -1px -2px 2px 2px #999;
  display: none;
  padding: 20px;
  position: fixed;
  width: 100%;
}
.cookie-panel .cookie-close {
  position: absolute;
  right: 10px;
  top: 0px;
  font-size: 1.5em;
  color: #fff;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cookie-panel .cookie-close:hover {
  color: #729c2a;
  -moz-transform: rotateZ(90deg);
  -ms-transform: rotateZ(90deg);
  -o-transform: rotateZ(90deg);
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}
.cookie-container .cookie-panel-gdpr {
  background: #171717;
  opacity: 0.9;
  color: #fff;
  box-shadow: -1px -2px 2px 2px #999;
  padding: 20px;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 10;
}
.cookie-container .cookie-panel-gdpr .cookie-close {
  position: absolute;
  right: 10px;
  top: 0px;
  font-size: 1.5em;
  color: #fff;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cookie-container .cookie-panel-gdpr .cookie-close:hover {
  color: #729c2a;
  -moz-transform: rotateZ(90deg);
  -ms-transform: rotateZ(90deg);
  -o-transform: rotateZ(90deg);
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}
.cookie-container .cookie-tab-ctrl {
  background: #171717;
  color: #fff;
  display: inline-block;
  padding: 13px;
  cursor: pointer;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
}
@media (max-width: 991px) {
  .cookie-container .cookie-panel-gdpr {
    padding-bottom: 50px;
  }
}
.IE8 footer {
  display: block!important;
}
@media (max-width: 991px) {
  footer {
    padding-top: 20px;
  }
  footer .footer-info img {
    width: 44px;
  }
}
@media (max-width: 767px) {
  footer .green-btm .bottom-links {
    text-align: left;
    margin-top: 5px;
  }
}
@media (max-width: 550px) {
  footer {
    padding-top: 20px;
  }
  footer address {
    margin-bottom: 10px;
  }
  footer .container {
    padding: 0;
  }
  footer .footer-links {
    width: 100%;
    text-align: center;
    margin: 10px 0 5px;
    background: #757575;
    -moz-border-radius: 11px;
    -webkit-border-radius: 11px;
    border-radius: 11px;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  footer .footer-links.footer-open {
    background: #838383;
  }
  footer .footer-links.footer-open h3:after {
    -moz-transform: rotateZ(180deg);
    -ms-transform: rotateZ(180deg);
    -o-transform: rotateZ(180deg);
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
  }
  footer .footer-links:hover {
    background: #838383;
  }
  footer .footer-links ul {
    display: none;
  }
  footer .footer-links ul li {
    padding: 8px 0;
  }
  footer .footer-links ul li:last-child {
    margin-bottom: 7px;
  }
  footer .footer-links ul li:hover {
    background: #757575;
  }
  footer .footer-links ul li:hover a {
    color: #ff9900;
  }
  footer .footer-links ul li a {
    display: block;
    font-size: 1.21428571em;
    -moz-transition: color 0.4s ease-in-out;
    -o-transition: color 0.4s ease-in-out;
    -webkit-transition: color 0.4s ease-in-out;
    transition: color 0.4s ease-in-out;
  }
  footer .footer-links ul li:before {
    display: none;
  }
  footer .footer-links h3 {
    display: block;
    position: relative;
    text-align: center;
    cursor: pointer;
    margin: 0;
    padding: 12px 0px 9px;
  }
  footer .footer-links h3:after {
    font-family: 'FontAwesome';
    content: '\f13a';
    display: block;
    font-size: 22px;
    width: 22px;
    height: 22px;
    position: absolute;
    top: 8px;
    right: 18px;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  footer .green-hover:hover h3 {
    font-size: 1.28571429em;
    color: #fff;
  }
  footer .social-links {
    padding: 16px 0 8px;
    width: 100%;
    text-align: center;
  }
  footer .social-links h3 {
    display: none;
  }
  footer .footer-info {
    width: 100%;
    text-align: center;
  }
  footer .footer-info h3 {
    display: none;
  }
}
.form-box {
  background: #f1f1f1 url(app/modules/~@imgOld/form-repeat.png) repeat 0px 0px;
  padding: 60px 60px 28px 60px;
  margin: 0px 0 20px;
}
.form-box label {
  font-size: 1.42857143em;
  font-weight: normal;
  color: #729c2a;
  margin-bottom: 10px;
}
.form-box input,
.form-box .form-control,
.form-box textarea {
  margin-bottom: 30px;
  background: #fff;
  border: none !important;
  height: 54px;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  padding: 10px 15px;
  color: #7b7b7b;
}
.form-box input:focus,
.form-box .form-control:focus,
.form-box textarea:focus {
  border: 1px solid #729c2a !important;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 none;
}
.form-box input[type='submit'] {
  float: right;
}
.enquiry-form .alert {
  margin-top: 0;
}
textarea {
  min-height: 110px;
  height: auto !important;
  color: #7b7b7b;
  padding: 15px;
}
.small-input {
  outline: none;
}
.EditingFormErrorLabel,
.ErrorLabel {
  width: 100%;
  display: block;
  line-height: 1.3em;
  margin-top: 10px;
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.hideErrorLabels .ErrorLabel {
  padding: 0px;
  border: 0px;
  float: none;
}
.enquiry-form {
  text-align: left;
}
input,
select,
textarea {
  color: #666666;
}
input::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: rgba(138, 138, 138, 0.75);
}
input:-moz-placeholder,
select:-moz-placeholder,
textarea:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(138, 138, 138, 0.75);
}
input::-moz-placeholder,
select::-moz-placeholder,
textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(138, 138, 138, 0.75);
}
input:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: rgba(138, 138, 138, 0.75);
}
.btn,
button,
input[type='button'],
input[type='submit'] {
  display: inline-block;
  position: relative;
  margin: 0;
  border: 0;
  padding: 12px 36px;
  color: #ffffff;
  background-color: #859a3e;
  font-size: 1.28571429em;
  vertical-align: middle;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0;
  -moz-transition: background ease 0.3s;
  -o-transition: background ease 0.3s;
  -webkit-transition: background ease 0.3s;
  transition: background ease 0.3s;
}
.btn:hover,
button:hover,
input[type='button']:hover,
input[type='submit']:hover,
.btn:focus,
button:focus,
input[type='button']:focus,
input[type='submit']:focus {
  color: #ffffff;
  background-color: #a2b952;
}
input[type='checkbox'],
input[type='radio'] {
  /*display: none;*/
}
.checkbox input[type='checkbox'] {
  margin-top: 2px;
  height: auto;
  position: relative;
  display: block;
  float: left;
  margin-left: 0px;
  margin-bottom: 0px;
}
.label-check,
.label-radio {
  display: inline-block;
  position: relative;
  height: 22px;
  margin: 0 26px 0 0;
  padding: 0 0 0 26px;
  vertical-align: middle;
  line-height: 1.57142857em;
}
.label-check::before,
.label-radio::before,
.label-check::after,
.label-radio::after {
  content: ' ';
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 14px;
  width: 14px;
  margin-top: -7px;
}
.label-check::before,
.label-radio::before {
  background: #ffffff;
}
.label-check::after {
  content: '\f00c';
  font-family: FontAwesome;
  font-size: 0;
  color: #575757;
  line-height: 1.07142857em;
}
.label-check.checked::after {
  font-size: 1em;
}
.label-radio::before {
  border-radius: 7px;
}
.label-radio::after {
  left: 3px;
  height: 8px;
  width: 8px;
  margin-top: -4px;
  border-radius: 4px;
}
.label-radio.checked::after {
  background: #575757;
}
.error-control {
  border: 1px solid #a94442;
  background: #f9f1f1;
}
.error-message {
  display: block;
  padding: 8px;
  color: #a94442;
}
.alert {
  width: 100%;
  float: left;
  line-height: 1.3em;
  margin-top: 10px;
}
.ErrorLabel {
  margin: 10px 0;
}
.input-box {
  clear: both;
}
.LoginPanelPasswordRetrieval {
  display: inline-block;
}
.EditingFormInfoLabel,
.InfoLabel {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  display: block;
}
.no-width {
  width: auto;
}
.checkbox-list-vertical input[type='checkbox'] {
  position: absolute;
  z-index: -1000;
  left: -1000px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
.checkbox-list-vertical input[type='checkbox'] + label {
  padding-left: 32px;
  height: 20px;
  display: inline-block;
  line-height: 20px;
  background-repeat: no-repeat;
  background-position: 0 0;
  font-size: 1em;
  vertical-align: middle;
  cursor: pointer;
}
.checkbox-list-vertical input[type='checkbox']:checked + label {
  background-position: 0 -20px;
}
.checkbox-list-vertical label {
  background-image: url(app/modules/~@imgOld/checkbox2.png);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #7b7b7b;
}
@media (max-width: 480px) {
  .form-box {
    padding: 38px 26px 22px 26px;
    margin-bottom: 10px;
  }
}
.blog-comments .form-group {
  margin-left: 0px;
  margin-right: 0px;
}
.no-js-btn {
  display: none !important;
}
.gdpr-checkbox.checkbox input[type='checkbox'] {
  position: fixed;
  width: 13px;
}
.gdpr-checkbox label p {
  color: #575757;
  font-size: 18px;
}
.form-group--conditions {
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 5px;
  max-height: 40vh;
}
.terms-checkbox:disabled + .terms-checkbox__label {
  opacity: 0.5;
  cursor: not-allowed;
}
.terms-btn:disabled {
  cursor: not-allowed;
}
.editing-form-control-nested-control a {
  margin-top: 10px;
}
.download-panel .btn-primary:disabled {
  background-color: #575757;
  border-color: #575757;
}
.HomePage header {
  -moz-transition-delay: 0s;
  -o-transition-delay: 0.4s;
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
.HomePage header .grey-bar {
  -moz-transition-delay: 0.9s;
  -o-transition-delay: 0.7s;
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}
.animate-banner {
  opacity: 0 !important;
  position: relative;
  top: 0;
  left: 100%;
  -moz-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}
.home-banner {
  overflow: hidden;
  opacity: 0 !important;
}
.home-banner .owl-buttons .owl-prev {
  position: absolute;
  z-index: 8000;
  top: 60%;
  margin-top: -13px !important;
  left: 30px;
  opacity: 1 !important;
  text-indent: -9999px;
  width: 47px;
  height: 47px;
  background: url('/sitefiles/images/sprite.png') no-repeat 0px -177px !important;
}
.home-banner .owl-buttons .owl-prev:hover {
  background-position: -59px -177px !important;
}
.home-banner .item {
  position: relative;
}
.home-banner .item .banner-overlay {
  position: absolute;
  z-index: 900;
  bottom: 200px;
  left: 0px;
  right: 0px;
}
.home-banner .item .banner-overlay .banner-title {
  display: inline-block;
  padding: 20px;
  font-size: 2.57142857em;
  color: #729c2a;
  background: #fff;
}
.home-banner .item .banner-overlay .banner-title a {
  display: block;
  color: #729c2a;
  text-decoration: none;
  -moz-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}
.home-banner .item .banner-overlay .banner-title a:hover {
  color: #89bc33;
}
.home-banner .item .banner-overlay .banner-text {
  margin: -27px 0px 0px 34px;
  background: url('app/modules/~@imgOld/white-overlay.png') repeat 0px 0px;
  width: 396px;
  display: block;
  padding: 40px 20px 30px 20px;
  font-size: 1.14285714em;
}
.home-banner .item .banner-overlay .banner-text a {
  color: #666;
  text-decoration: none;
  -moz-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}
.home-banner .item .banner-overlay .banner-text a:hover {
  color: #729c2a;
}
.home-banner .owl-buttons .owl-next {
  position: absolute;
  z-index: 8000;
  top: 60%;
  margin-top: -13px !important;
  right: 30px;
  opacity: 1 !important;
  text-indent: -9999px;
  width: 47px;
  height: 47px;
  background: url('/sitefiles/images/sprite.png') no-repeat 1px -229px !important;
}
.home-banner .owl-buttons .owl-next:hover {
  background-position: -59px -229px !important;
}
.animate-banner.slide-in {
  opacity: 1 !important;
  left: 0;
}
.animate-banner.slide-in .home-banner {
  opacity: 1 !important;
}
.homepage-signposts {
  position: relative;
  top: -36px;
}
.homepage-signposts .signpost-item {
  opacity: 0;
  width: 20%;
  float: left;
  padding: 0 15px;
}
.homepage-signposts .signpost-item .widget.sign-post-widget {
  min-height: 95px;
  /*a:hover {
                .signpost .signpost-icon img {
                    left: 5px;
                    width: 54px;
                }
            }*/
}
.homepage-signposts .signpost-item .widget.sign-post-widget .signpost {
  height: 54px;
}
.homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-icon {
  width: 67px;
}
.homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-icon img {
  left: 7px;
}
.homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-text {
  font-size: 1.64285714em;
  line-height: 26px;
}
.load-home-content {
  opacity: 0;
  -moz-transition: opacity 0.6s ease-in-out;
  -o-transition: opacity 0.6s ease-in-out;
  -webkit-transition: opacity 0.6s ease-in-out;
  transition: opacity 0.6s ease-in-out;
}
.load-home-content.fade-in {
  opacity: 1 !important;
}
.homepage-strip {
  padding: 40px 0 30px;
  background: url(app/modules/~@imgOld/faint-line-repeat.png) repeat 0px 0px;
}
.home-widget-wrap {
  margin: 60px 0;
}
@media (max-width: 1590px) {
  .home-banner .item .banner-overlay .banner-text {
    width: 550px;
  }
  .home-banner .item .banner-overlay {
    bottom: 120px;
  }
}
@media (max-width: 1390px) {
  .home-banner .item .banner-overlay {
    bottom: 75px;
    padding-left: 80px;
  }
  .home-banner .owl-buttons .owl-prev {
    left: 2px;
  }
  .home-banner .owl-buttons .owl-next {
    right: 2px;
  }
}
@media (max-width: 1270px) {
  .home-banner .item .banner-overlay {
    bottom: 65px;
    padding-left: 80px;
  }
  .home-banner .item .banner-overlay .banner-text {
    width: 780px;
  }
}
@media (max-width: 1199px) {
  .home-banner .item .banner-overlay {
    bottom: 38px;
    padding-left: 80px;
  }
  .home-banner .item .banner-overlay .banner-text {
    width: 840px;
    padding: 40px 20px 12px 20px;
  }
  .homepage-signposts {
    position: relative;
    top: -25px;
  }
  .homepage-signposts .signpost-item .widget.sign-post-widget {
    /*a:hover {
                .signpost .signpost-icon img {
                    left: 3px;
                    width: 43px;
                }
            }*/
  }
  .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-icon {
    width: 56px;
  }
  .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-icon img {
    left: 4px;
    width: 39px;
  }
  .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-text {
    font-size: 1.35714286em;
    line-height: 25px;
  }
  .homepage-signposts .widget.sign-post-widget .signpost .depot-title {
    font-size: 1.35714286em;
    line-height: 25px;
  }
}
@media (max-width: 1040px) {
  .home-banner .item .banner-overlay {
    bottom: 26px;
    padding-left: 44px;
  }
}
@media (max-width: 991px) {
  .home-banner .item .banner-overlay {
    bottom: auto;
    top: 48px;
    padding-left: 0;
    padding-right: 10%;
  }
  .home-banner .item .banner-overlay .banner-text {
    width: 100%;
  }
  .homepage-signposts {
    top: 8px;
  }
  .homepage-signposts .signpost-item {
    width: 25%;
    float: left;
    padding: 0 3px;
  }
  .homepage-signposts .signpost-item:last-child {
    display: none;
  }
}
@media (max-width: 767px) {
  .homepage-signposts .signpost-item {
    width: 50%;
  }
  .home-banner .item .banner-overlay .banner-title {
    display: inline-block;
    padding: 14px 0;
    font-size: 1.85714286em;
  }
  .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-text {
    font-size: 1.71428571em;
    line-height: 31px;
  }
  .homepage-signposts .widget.sign-post-widget .signpost .depot-title {
    font-size: 1.71428571em;
    line-height: 25px;
  }
  .home-banner .item .banner-overlay .banner-text {
    padding: 0;
    margin: 0;
  }
  .home-banner .item .banner-overlay {
    top: 5px;
    left: 0;
    position: relative;
  }
  .home-banner .owl-buttons .owl-prev,
  .home-banner .owl-buttons .owl-next {
    top: 40%;
  }
}
@media (max-width: 550px) {
  .home-widget-wrap {
    margin: 25px 0 5px;
  }
  .home-banner .owl-buttons .owl-prev,
  .home-banner .owl-buttons .owl-next {
    top: 20%;
  }
  .home-banner .item .banner-overlay {
    top: 5px;
    left: 0;
    position: relative;
    padding-right: 0;
  }
  .home-banner .item .banner-overlay .container {
    padding: 0;
  }
  .home-banner .item .banner-overlay .banner-title {
    display: inline-block;
    padding: 9px;
    padding-bottom: 0;
    font-size: 20px;
    color: #729c2a;
    background: #fff;
  }
  .home-banner .item .banner-overlay .banner-text {
    width: 100%;
    margin: 0;
    padding: 10px 10px;
    font-size: 1em;
  }
}
@media (max-width: 480px) {
  .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-text {
    font-size: 1.21428571em;
    line-height: 22px;
  }
  .widget.sign-post-widget .signpost .postcode-select .chosen-container .chosen-single {
    padding: 1px 2px !important;
  }
  .homepage-signposts .widget.sign-post-widget .signpost .depot-title {
    font-size: 1.21428571em;
  }
  .widget.sign-post-widget .signpost .postcode-select {
    padding: 0 7px !important;
  }
  .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-icon {
    width: 48px;
  }
  .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-icon img {
    left: -2px;
  }
  /*.homepage-signposts .signpost-item .widget.sign-post-widget a:hover .signpost .signpost-icon img {
        left: -3px;
    }*/
  .homepage-signposts {
    top: -2px;
  }
  .homepage-signposts .widget {
    margin-bottom: 7px;
  }
  .homepage-signposts .chosen-container .chosen-results li {
    height: 42px;
    line-height: 16px;
    padding-left: 12px;
    padding-top: 13px;
    padding-right: 9px;
  }
}
@media (max-width: 335px) {
  .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-text {
    font-size: 1.21428571em;
    line-height: 22px;
  }
}
.ko-form .ko-form-outer .ko-form-inner .ko-form-category .form-group.required label::after {
  content: '\0000a0*';
  display: inline-block;
  color: #a94442;
}
.ko-form .ko-form-outer .ko-form-inner .ko-form-category .form-group .error-control {
  border: 1px solid #a94442;
  background: #ce8483;
}
.ko-form .ko-form-outer .ko-form-inner .ko-form-category .form-group .error-message {
  display: block;
  padding: 8px;
  color: #a94442;
}
.media-hub {
  position: relative;
  padding-bottom: 157px;
}
.media-hub .owl-carousel .owl-wrapper-outer.autoHeight {
  height: auto!important;
}
.media-hub .row {
  margin-right: -10px;
  margin-left: -10px;
}
.media-hub .row .col-sm-6 {
  padding-right: 10px;
  padding-left: 10px;
}
.media-hub .owl-theme .owl-controls {
  margin: 0;
}
.media-hub .owl-theme .owl-controls .owl-buttons div {
  position: absolute;
  top: 50%;
  left: -98px;
  margin: -38px 0 0 0;
  width: 77px;
  height: 77px;
  padding: 0;
  background: none;
  opacity: 1;
  border-radius: 0;
  z-index: 10;
}
.media-hub .owl-theme .owl-controls .owl-buttons div .owl-nav-prev,
.media-hub .owl-theme .owl-controls .owl-buttons div .owl-nav-next {
  background: url('/sitefiles/images/sprite.png') no-repeat 0 -376px;
  display: block;
  width: 77px;
  height: 77px;
  z-index: 2;
  position: relative;
}
.media-hub .owl-theme .owl-controls .owl-buttons div .text-span {
  width: 0;
  position: absolute;
  top: 50%;
  left: 68px;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  overflow: hidden;
  -moz-transition: width 1s ease;
  -o-transition: width 1s ease;
  -webkit-transition: width 1s ease;
  transition: width 1s ease;
  text-align: left;
  display: block;
  padding: 5px 0;
}
.media-hub .owl-theme .owl-controls .owl-buttons div .text-span.text-next {
  left: auto;
  right: 70px;
  text-align: right;
}
.media-hub .owl-theme .owl-controls .owl-buttons div .text-span.text-next .inner-span {
  padding: 5px 20px 5px 10px;
  display: inline-block;
}
.media-hub .owl-theme .owl-controls .owl-buttons div .text-span .inner-span {
  background: #ff9900;
  padding: 5px 10px 5px 20px;
  color: #fff;
  font-size: 1.42857143em;
  text-transform: uppercase;
  line-height: normal;
  white-space: nowrap;
}
.media-hub .owl-theme .owl-controls .owl-buttons div:hover .owl-nav-prev {
  background-position: 0 -376px;
}
.media-hub .owl-theme .owl-controls .owl-buttons div:hover .text-span {
  width: 200px;
}
.media-hub .owl-theme .owl-controls .owl-buttons div.owl-next {
  left: auto;
  right: -98px;
}
.media-hub .owl-theme .owl-controls .owl-buttons div.owl-next .owl-nav-next {
  background-position: -78px -376px;
}
.media-hub .case-box {
  border: 1px solid #cccccc;
  padding: 15px;
  margin-bottom: 15px;
}
.media-hub .case-box:last-child {
  margin: 0;
}
.media-hub .case-box .case-top {
  position: relative;
  padding-right: 90px;
}
.media-hub .case-box .case-top.twit-icon {
  padding-right: 0;
}
.media-hub .case-box .case-top.twit-icon a {
  display: none;
}
.media-hub .case-box .case-top.twit-icon h5 {
  padding-left: 60px;
  position: relative;
}
.media-hub .case-box .case-top.twit-icon h5:before {
  content: '';
  position: absolute;
  top: 0;
  left: 15px;
  background: url('/sitefiles/images/sprite.png') no-repeat -41px 0;
  width: 30px;
  height: 38px;
}
.media-hub .case-box .case-top.youtube-icon {
  padding-right: 0;
}
.media-hub .case-box .case-top.youtube-icon h5 {
  padding-left: 60px;
  position: relative;
}
.media-hub .case-box .case-top.youtube-icon h5:before {
  content: '';
  position: absolute;
  top: 0;
  left: 15px;
  background: url('/sitefiles/images/sprite.png') no-repeat 0px 0;
  width: 30px;
  height: 38px;
}
.media-hub .case-box .case-top h1 {
  display: inline-block;
  background: #ff9900;
  padding: 7px 15px;
  margin-left: -15px;
  color: #fff;
}
.media-hub .case-box .case-top h5 {
  display: inline-block;
  background: #ff9900;
  padding: 7px 15px;
  margin-left: -15px;
  color: #fff;
}
.media-hub .case-box .case-top a {
  position: absolute;
  top: 0;
  right: 0;
  color: #ff9900;
}
.media-hub .case-box.brand-orange .case-top h1 {
  background: #ff9900;
}
.media-hub .case-box.brand-orange .case-top a {
  color: #ff9900;
}
.media-hub .case-box.brand-orange .case-bottom .case-text h6 {
  color: #ff9900;
}
.media-hub .case-box.brand-orange .case-bottom .case-text a {
  color: #ff9900;
}
.media-hub .case-box.brand-green .case-top h5 {
  background: #859a3e;
}
.media-hub .case-box.brand-green .case-top a {
  color: #859a3e;
}
.media-hub .case-box.brand-green .case-bottom .case-text h6 {
  color: #859a3e;
}
.media-hub .case-box.big-box {
  margin-bottom: 0;
}
.media-hub .case-box.big-box .case-bottom .case-image {
  /*width: 65%;*/
  width: 100%;
  padding-right: 0px;
}
.media-hub .case-box.big-box .case-bottom .case-text {
  width: 35%;
  width: 100%;
  margin-top: 20px;
}
.media-hub .case-box .case-bottom .case-image {
  float: left;
  width: 40%;
  padding-right: 20px;
}
.media-hub .case-box .case-bottom .case-text {
  float: left;
  width: 60%;
}
.media-hub .case-box .case-bottom .case-text h6 {
  color: #ff9900;
  margin: 0 0 5px 0;
}
.media-hub .media-view {
  margin: 0 auto;
  width: 74%;
  position: absolute;
  bottom: 50px;
  left: 13%;
}
.media-hub .media-view h5 {
  margin: 0 0 15px;
}
.media-hub .media-view .grey-bar {
  margin: 0 0 15px;
}
.media-hub .media-view .grey-bar:before {
  display: none;
}
.media-hub .media-view .grey-bar .green-block {
  position: absolute;
  top: 0;
  right: auto;
  left: -9px;
  content: ' ';
  display: block;
  height: 8px;
  width: 16px;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  background: #859a3e;
}
.media-hub .media-view span {
  cursor: pointer;
}
.media-hub .media-view span:hover {
  color: #859a3e;
}
.media-hub .media-view .synced span {
  color: #859a3e;
}
.IE8 .media-hub .owl-theme .owl-controls .owl-buttons div .text-span {
  display: none!important;
}
.article-wrap {
  position: relative;
  margin: 15px 0;
}
.article-wrap .media-filter {
  width: 518px;
  padding: 9px;
  background: #ff9900;
  position: absolute;
  right: 0;
  top: -74px;
}
.article-wrap .media-filter .filter-inner {
  width: 100%;
  display: block;
}
.article-wrap .media-filter label {
  display: inline-block;
  color: #fff;
  font-size: 1.28571429em;
  font-weight: normal;
  margin-right: 8px;
}
.article-wrap .media-filter select {
  width: 165px!important;
  margin-bottom: 0;
}
.article-wrap .media-filter .filter {
  display: inline-block;
  width: 200px;
  margin-right: 22px;
}
.article-wrap .media-filter .filter.no-marg {
  margin-right: 0;
}
.article-wrap .media-filter .chosen-container {
  height: 32px;
  margin-bottom: 0;
  background: #f2f2f2;
}
.article-wrap .media-filter .chosen-container .chosen-drop,
.article-wrap .media-filter .chosen-container .chosen-results li {
  background: #f2f2f2;
}
.article-wrap .media-filter .chosen-container .chosen-drop.highlighted,
.article-wrap .media-filter .chosen-container .chosen-results li.highlighted {
  background: #729c2a;
}
.article-wrap .media-filter .chosen-container .chosen-single {
  height: 32px;
  background: #f2f2f2;
}
.article-wrap .media-filter .chosen-container .chosen-single span {
  height: 32px;
  line-height: 32px;
}
.article-wrap .media-filter .chosen-container .chosen-single div {
  padding: 3px 18px;
}
.article-wrap .article-item {
  margin-bottom: 45px;
  padding: 0 20px;
}
.article-wrap .article-item .grey-bar {
  margin-top: 25px;
}
.article-wrap .article-item .article {
  display: table;
  width: 100%;
}
.article-wrap .article-item .article a {
  text-decoration: none;
  display: block;
  position: relative;
}
.article-wrap .article-item .article a:hover .hover-overlay {
  background: #000;
  opacity: 0.4;
}
.article-wrap .article-item .article a:hover .overlay {
  height: 74px;
  width: 90px;
}
.article-wrap .article-item .article a:hover .overlay img {
  width: 56px;
}
.article-wrap .article-item .article .article-img {
  width: 222px;
  max-width: 222px;
  display: table-cell;
  vertical-align: top;
  position: relative;
}
.article-wrap .article-item .article .article-img .fancybox {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  text-decoration: none;
}
.article-wrap .article-item .article .article-img .fancybox:before {
  content: '\f16a';
  font-family: 'FontAwesome';
  font-size: 4.71428571em;
  color: #fff;
  opacity: 0.7;
  position: absolute;
  top: 49%;
  left: 0;
  right: 0;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.article-wrap .article-item .article .article-img .fancybox:hover:before {
  opacity: 1;
  font-size: 74px;
}
.article-wrap .article-item .article .article-img img {
  max-width: 222px;
}
.article-wrap .article-item .article .article-img .hover-overlay {
  opacity: 0;
  background: none;
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.article-wrap .article-item .article .article-img .overlay {
  position: absolute;
  top: 0;
  right: 0;
  height: 58px;
  width: 78px;
  background-color: #ff9900;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 55%;
  z-index: 30;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.article-wrap .article-item .article .article-img .overlay img {
  width: 42px;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.article-wrap .article-item .article .article-img .overlay.news {
  background-image: url(/sitefiles/images/news-feature-icon.png);
}
.article-wrap .article-item .article .article-img .overlay.casestudy {
  background-image: url(/sitefiles/images/news-feature-icon.png);
}
.article-wrap .article-item .article .article-img .overlay.blog {
  background-image: url(/sitefiles/images/blog-feature-icon.png);
}
.article-wrap .article-item .article .article-desc {
  padding-left: 40px;
  display: table-cell;
  vertical-align: top;
}
.article-wrap .article-item .article .article-desc h3 {
  color: #666666;
  font-size: 1.35714286em;
  margin-bottom: 15px;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.article-wrap .article-item .article .article-desc h3:hover {
  color: #729c2a;
}
.article-wrap .article-item .blog-author {
  display: inline-block;
  font-size: 0.92857143em;
  margin-top: 8px;
}
.article-wrap .article-item .blog-author .fa {
  font-size: 1.42857143em;
  padding-right: 8px;
}
.article-wrap .article-item .tags-used {
  display: inline-block;
  font-size: 0.92857143em;
  margin-top: 8px;
}
.article-wrap .article-item .tags-used .fa {
  font-size: 1.42857143em;
  padding-right: 6px;
  padding-left: 8px;
  position: relative;
  top: 3px;
}
.article-wrap .article-item .tags-used ul {
  padding: 0;
  display: inline-block;
}
.article-wrap .article-item .tags-used ul li {
  list-style-type: none;
  display: inline-block;
  padding-left: 0px;
}
.article-wrap .article-item .tags-used ul li:before {
  display: none;
}
.IE8 .tags-used {
  display: block!important;
}
.IE8 .tags-used ul li:before {
  display: none;
}
.IE8 .article-wrap .article-item .article {
  display: block;
}
.IE8 .article-wrap .article-item .article .article-img {
  display: block;
  width: 100%;
}
.IE8 .article-wrap .article-item .article .article-desc {
  display: block;
  margin-top: 15px;
  padding-left: 0;
  width: 100%;
}
.IE8 .article-wrap .article-item .article a:hover .hover-overlay {
  background: url(/sitefiles/images/opacity-40.png) repeat 0px 0px;
  opacity: 1;
}
.hub-content {
  margin-bottom: 40px;
}
.hub-content .grey-bar {
  margin-top: 45px;
}
.hub-content .blog-author {
  display: inline-block;
  font-size: 0.92857143em;
  margin-top: 8px;
  float: left;
}
.hub-content .blog-author .fa {
  font-size: 1.42857143em;
  padding-right: 8px;
}
.hub-content .tags-used {
  display: inline-block;
  font-size: 0.92857143em;
  margin-top: 8px;
  float: left;
}
.hub-content .tags-used .fa {
  font-size: 1.42857143em;
  padding-right: 6px;
  padding-left: 8px;
  position: relative;
  top: 3px;
}
.hub-content .tags-used ul {
  padding: 0;
  display: inline-block;
}
.hub-content .tags-used ul li {
  list-style-type: none;
  display: inline-block;
  padding-left: 0px;
}
.hub-content .tags-used ul li:before {
  display: none;
}
.post-area {
  position: relative;
  padding-left: 85px;
  float: left;
  margin-right: 21px;
  margin-bottom: 18px;
  max-width: 100%;
}
.post-area .left-type {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 78px;
  background: #859a3e;
  color: #fff;
}
.post-area .left-type .date {
  position: absolute;
  top: 30px;
  left: 17px;
  font-size: 1.28571429em;
  text-align: center;
}
.post-area .left-type .date span {
  width: 100%;
  display: block;
  padding-left: 4px;
}
.post-area .left-type .date strong {
  display: block;
  width: 100%;
  font-size: 2.28571429em;
  margin-bottom: 5px;
}
.post-area .left-type .post-type {
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 6px solid #fff;
  padding: 12px;
  width: 100%;
  text-align: center;
}
.post-area .left-type .post-type.bg-orange {
  background: #ff9900;
}
.post-area .left-type .post-type.bg-green {
  background: #859a3e;
}
.post-area .left-type .post-type.bg-grey {
  background: #575757;
}
.post-area .left-type .post-type img {
  width: 40px;
}
@media (max-width: 1330px) {
  .media-hub .owl-theme .owl-controls .owl-buttons div {
    left: 0;
    opacity: 0.5;
  }
  .media-hub .owl-theme .owl-controls .owl-buttons div.owl-next {
    right: 0;
  }
}
@media (max-width: 1199px) {
  .post-area {
    width: 422px;
  }
  .article-wrap .media-filter {
    width: 430px;
  }
  .article-wrap .media-filter .filter {
    width: 160px;
    margin-right: 20px;
  }
  .article-wrap .media-filter .filter .chosen-container .chosen-single span {
    padding-right: 7px;
  }
  .article-wrap .media-filter .filter .chosen-container .chosen-results li {
    padding-right: 6px;
  }
  .media-hub .owl-theme .owl-controls .owl-buttons div {
    left: -98px;
    opacity: 1;
  }
  .media-hub .owl-theme .owl-controls .owl-buttons div.owl-next {
    right: -98px;
  }
  .article-wrap .article-item .article .article-img {
    width: 178px;
    max-width: 178px;
  }
  .article-wrap .article-item .article .article-img img {
    max-width: 178px;
  }
  .article-wrap .article-item .article .article-desc {
    padding-left: 22px;
  }
}
@media (max-width: 1130px) {
  .media-hub .owl-theme .owl-controls .owl-buttons div {
    left: 0;
    opacity: 0.5;
  }
  .media-hub .owl-theme .owl-controls .owl-buttons div.owl-next {
    right: 0;
  }
}
@media (max-width: 991px) {
  .media-hub .case-box .case-bottom iframe {
    min-width: 136px!important;
  }
  .post-area {
    width: 290px;
  }
  .article-wrap {
    padding-top: 66px;
  }
  .article-wrap.list-no-filter {
    padding-top: 0px;
  }
  .article-wrap .media-filter {
    width: 100%;
    position: absolute;
    right: auto;
    top: -14px;
  }
  .article-wrap .media-filter .filter {
    width: 178px;
  }
  .media-hub .owl-theme .owl-controls .owl-buttons div {
    left: -98px;
    opacity: 1;
  }
  .media-hub .owl-theme .owl-controls .owl-buttons div.owl-next {
    right: -98px;
  }
  .media-hub .case-box .case-bottom .case-image,
  .media-hub .case-box.big-box .case-bottom .case-image {
    width: 100%;
    margin-bottom: 15px;
  }
  .media-hub .case-box .case-bottom .case-text,
  .media-hub .case-box.big-box .case-bottom .case-text {
    width: 100%;
  }
  .article-wrap .article-item .article .article-img {
    width: 133px;
    max-width: 133px;
  }
  .article-wrap .article-item .article .article-img img {
    max-width: 133px;
  }
  .article-wrap .article-item .article .article-img .overlay {
    padding: 7px 12px;
  }
  .article-wrap .article-item .article .article-img .overlay img {
    width: 32px;
  }
  .article-wrap .article-item .article a:hover .overlay img {
    width: 45px;
  }
}
@media (max-width: 905px) {
  .media-hub .owl-theme .owl-controls .owl-buttons div {
    left: 0;
    opacity: 0.5;
  }
  .media-hub .owl-theme .owl-controls .owl-buttons div.owl-next {
    right: 0;
  }
}
@media (max-width: 767px) {
  .article-wrap .media-filter .filter {
    width: 160px;
  }
  .media-hub .case-box.big-box .case-bottom .case-image {
    width: 65%;
  }
  .media-hub .case-box.big-box .case-bottom .case-text {
    width: 35%;
  }
  .media-hub .case-box .case-bottom .case-image {
    width: 40%;
  }
  .media-hub .case-box .case-bottom .case-text {
    width: 60%;
  }
  .media-hub .row .col-sm-6 {
    padding-right: 15px;
    padding-left: 15px;
  }
  .media-hub .row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .media-hub .case-box.big-box {
    margin-bottom: 15px;
  }
  .media-hub {
    padding: 0;
  }
  .media-hub .media-view {
    position: static;
    margin: 0 auto 25px;
    width: 100%;
  }
  .media-hub .media-view .grey-bar {
    display: block!important;
  }
  .media-hub .owl-theme .owl-controls .owl-buttons div {
    position: fixed;
  }
  .article-item .grey-bar {
    display: block!important;
  }
  .media-hub .owl-theme .owl-controls .owl-buttons {
    display: none;
  }
  .media-hub .case-box:last-child {
    margin-bottom: 15px;
  }
  .hub-content .tags-used,
  .hub-content .blog-author {
    float: none;
  }
}
@media (max-width: 550px) {
  .post-area {
    width: 100%;
    float: none;
  }
  .hub-content {
    margin-bottom: 20px;
  }
  .hub-content .grey-bar {
    display: block!important;
    margin-top: 25px;
  }
}
@media (max-width: 480px) {
  .article-wrap {
    padding-top: 160px;
  }
  .article-wrap.list-no-filter {
    padding-top: 0px;
  }
  .article-wrap .media-filter {
    padding: 16px;
  }
  .article-wrap .media-filter .filter {
    width: 100%;
    margin-bottom: 15px;
  }
  .article-wrap .media-filter .filter select {
    width: 100%!important;
    height: 40px;
  }
  .article-wrap .media-filter .filter.no-marg {
    margin: 0;
  }
  .media-hub .case-box .case-bottom .case-image,
  .media-hub .case-box.big-box .case-bottom .case-image {
    width: 100%;
  }
  .media-hub .case-box .case-bottom .case-text,
  .media-hub .case-box.big-box .case-bottom .case-text {
    width: 100%;
  }
  .media-hub .case-box .case-bottom .case-text h6 {
    margin-top: 10px;
  }
  .article-wrap .article-item .article {
    display: block;
    width: 100%;
  }
  .article-wrap .article-item .article .article-img {
    width: 100%;
    max-width: 100%;
    display: block;
    margin-bottom: 20px;
  }
  .article-wrap .article-item .article .article-img img {
    max-width: 100%;
  }
  .article-wrap .article-item .article .article-desc {
    width: 100%;
    display: block;
    padding-left: 0;
  }
  .article-wrap .article-item {
    margin-bottom: 25px;
    padding: 0 10px;
  }
  .article-wrap .article-item .tags-used {
    display: block;
  }
  .article-wrap .article-item .tags-used .fa {
    padding-left: 0;
  }
}
.replaced .article-item {
  opacity: 0;
}
@media (max-width: 360px) {
  .post-area .left-type .date {
    top: 20px;
    font-size: 1.28571429em;
  }
}
.BlogCommentsTitle {
  margin-bottom: 20px;
  display: block;
}
.CommentUserName {
  font-weight: bold;
  display: block;
  margin-bottom: 2px;
}
.CommentDate {
  margin-top: 5px;
  display: block;
  font-size: 80%;
}
@-webkit-keyframes opac {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes opac {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.HomePage .no-js {
  -webkit-animation: opac 3s;
  animation: opac 3s;
}
.no-js .js-btn {
  display: none !important;
}
.no-js .no-js-btn {
  display: block !important;
}
.no-js .js-div {
  display: none !important;
}
.no-js .no-js-div {
  display: block !important;
}
.no-js .no-js-div .login-click a {
  color: #ffffff;
}
.no-js .add-to-basket,
.no-js .basket-icon-button {
  display: none !important;
}
.no-js .enquire-now {
  display: block;
  padding-left: 12px !important;
  float: right;
}
.no-js header {
  opacity: 1 !important;
  transition: none;
  display: block;
  top: 0px;
}
.no-js header .depot-area,
.no-js header .search-area,
.no-js header .basket-area,
.no-js header .country-area {
  display: none !important;
}
.no-js header .signoutLink {
  display: none;
}
.no-js header .top-links .left-links .menu-btn {
  display: none !important;
}
.no-js header .top-links .right-links {
  min-width: 30%;
}
.no-js header .top-links .right-links .ipad-search {
  display: none !important;
}
.no-js header .top-links .right-links .mail-icon {
  opacity: 1 !important;
}
.no-js .main-content .owl-carousel {
  display: block;
}
.no-js .main-content .owl-carousel .item {
  display: none;
}
.no-js .main-content .owl-carousel .item:first-child {
  display: block;
}
.no-js .main-content .owl-carousel.media-main .item {
  display: block;
  padding: 0 0 20px 0;
}
.no-js .homepage-signposts .signpost-item {
  opacity: 1;
  width: 25%;
}
.no-js .tech-library .login-register .login-box .login-box-back {
  position: initial !important;
  max-height: none !important;
}
.no-js .tech-library .login-register .login-box:hover.green-login {
  background: #859a3e !important;
}
.no-js .tech-library .login-register .login-box:hover.orange-login {
  background: #f90 !important;
}
.no-js .tech-library .login-register .login-box .login-inner .close-box {
  display: none;
}
.no-js .media-filter,
.no-js .date-filter {
  display: none !important;
}
.no-js #ProductsList .orig,
.no-js .article-wrap .orig {
  display: block;
}
.no-js .tech-library .tech-search {
  display: none;
}
.no-js .media-hub .media-view {
  display: none;
}
.no-js .animate-banner {
  opacity: 1 !important;
  left: 0%;
}
.no-js .animate-banner .home-banner {
  opacity: 1 !important;
}
.no-js .animate-banner .home-banner .item {
  display: none;
}
.no-js .animate-banner .home-banner .item:first-child {
  display: block;
}
.no-js .load-home-content {
  opacity: 1 !important;
}
.no-js .technical-widget .orig {
  display: block;
}
.no-js footer {
  display: block !important;
}
@media (max-width: 991px) {
  .no-js header .desktop-nav {
    display: block;
    width: 100%;
  }
  .no-js header .desktop-nav ul li {
    width: 100%;
    display: inline-block;
  }
  .no-js header .desktop-nav ul li a {
    padding: 10px 14px;
    font-size: 1.2em;
  }
  .no-js header .login-area {
    opacity: 1 !important;
  }
  .no-js header .login-area a {
    padding-top: 10px;
  }
  .no-js header .login-area a span {
    opacity: 1 !important;
  }
  .no-js .footer-links ul {
    display: block;
  }
}
@media (max-width: 767px) {
  .no-js .back-to-desktop {
    display: block;
  }
  header .top-links .right-links .login-area .login-icon {
    top: 3px;
  }
}
@media (max-width: 767px) {
  .no-js header .logo {
    position: absolute;
    margin: 12px 0 13px 18px;
    padding-right: 25px;
    transition: all 0.5s ease-in-out;
    top: 30px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 550px) {
  .no-js header .logo {
    text-align: center;
    margin: 10px auto;
    top: 30px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .no-js .homepage-signposts .signpost-item {
    opacity: 1;
    width: 50%;
  }
  .no-js .homepage-signposts .signpost-item:last-child {
    display: block;
  }
  .no-js .homepage-signposts .signpost-item .widget.sign-post-widget {
    min-height: 105px;
  }
}
.solution-box {
  padding: 15px 25px 25px;
  background: url(app/modules/~@imgOld/faint-line-repeat.png) repeat 0px 0px;
  margin-bottom: 60px;
}
.product-filter {
  background: #ff9900;
  padding: 5px 10px;
  text-align: center;
  margin-bottom: 30px;
  min-height: 54px;
}
.product-filter .filter-inner {
  display: inline-block;
  width: auto;
}
.product-filter label {
  display: block;
  float: left;
  color: #fff;
  margin-right: 13px;
  font-size: 1.28571429em;
  font-weight: normal;
  margin-bottom: 0;
  margin-top: 12px;
}
.product-filter select {
  width: 175px;
  display: block;
  float: left;
  margin: 5px 0 0 0;
  height: 33px;
}
.product-filter .chosen-container {
  display: block;
  float: left;
  width: 175px !important;
  height: 33px;
  margin: 5px 0 0 0;
  background: #f2f2f2;
}
.product-filter .chosen-container .chosen-single span {
  height: 33px;
  line-height: 35px;
  padding-left: 9px;
  text-align: left;
  padding-right: 19px;
}
.product-filter .chosen-container .chosen-single {
  height: 33px;
  padding-left: 0;
}
.product-filter .chosen-container .chosen-single div {
  padding: 3px 10px;
  width: 42px;
}
.product-filter .chosen-container .chosen-results li {
  padding-left: 6px;
  padding-right: 9px;
  text-align: left;
  height: 33px;
  line-height: 25px;
}
.product-filter .prod-keyword-search {
  float: left;
}
.product-filter .prod-keyword-search label {
  display: none;
}
.product-filter .prod-keyword-search #tbProductFilter {
  margin-left: 15px;
  padding: 6px 10px 6px;
  border: none;
  float: left;
  margin-top: 6px;
  position: relative;
}
.product-filter .prod-keyword-search #btnProductFilter {
  padding: 7px 18px 5px 18px;
  background-image: none;
  margin-top: 6px;
  margin-left: 6px;
  float: left;
}
.product-view {
  margin-bottom: 40px;
}
.product-view ul {
  margin-bottom: 25px;
}
.product-view ul li {
  margin-bottom: 10px;
}
.product-view .post-area {
  float: none;
  width: 100%;
  padding-left: 0;
  padding-right: 85px;
}
.product-view .post-area .left-type {
  left: auto;
  right: 0;
}
.product-view .post-area .left-type .new {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  background: #ff9900;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 1.28571429em;
  padding-top: 20px;
}
.product-view .post-area .product-carousel {
  aspect-ratio: 1.70469799;
}
.product-view table {
  font-size: 86%;
  margin-bottom: 40px;
}
.product-view .smallprint {
  font-size: 1em;
  margin-bottom: 30px;
  display: block;
}
.product-view .btn {
  margin-bottom: 40px;
}
.add-to-basket {
  position: relative;
}
.popup-basket-msg {
  font-size: 1.28571429em;
  display: none;
  position: absolute;
  bottom: 105px;
  background-color: #859a3e;
  padding: 10px 10px 0px;
  color: #fff;
  text-align: center;
  z-index: 10;
  right: 0px;
}
.popup-basket-msg.iconview {
  width: 255px;
}
.popup-basket-msg .message i {
  margin-right: 10px;
}
.popup-basket-msg .message a {
  color: #fff;
}
@media (max-width: 767px) {
  .product-filter .prod-keyword-search {
    clear: both;
    margin-left: 0px;
  }
  .product-filter .prod-keyword-search label {
    display: block;
  }
  .product-filter .prod-keyword-search #tbProductFilter {
    margin-left: 1px;
    left: 0px;
    padding: 6px 15px 6px 9px;
  }
}
@media (max-width: 362px) {
  .product-view .post-area .left-type .new {
    width: 52px;
    height: 28px;
    padding-top: 5px;
  }
}
#response {
  overflow: auto;
}
.signpost-banner {
  padding: 5px 30px;
  opacity: 0;
  top: -64px;
  transition: opacity 0.3s ease-in, top 0.3s ease-in;
}
.signpost-banner__link:hover {
  text-decoration: none;
}
.signpost-banner__container {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 996px) {
  .signpost-banner__container {
    flex-direction: column;
  }
}
.signpost-banner__left-container {
  display: flex;
  align-items: center;
}
@media (max-width: 996px) {
  .signpost-banner__left-container {
    margin-bottom: 10px;
  }
}
.signpost-banner__highlighted-text {
  padding: 10px 20px;
  font-size: 27px;
  font-family: 'ff-dax-pro', Arial, sans-serif;
  margin-right: 20px;
}
.signpost-banner__image {
  margin-right: 20px;
}
.signpost-banner__right-container {
  display: flex;
  align-items: flex-end;
}
.signpost-banner__rich-text {
  font-size: 19px;
  line-height: 19px;
  font-family: 'ff-dax-pro', Arial, sans-serif;
  margin: 5px 0;
}
.signpost-banner__rich-text p {
  font-size: 19px;
  line-height: 19px;
  font-family: 'ff-dax-pro', Arial, sans-serif;
  margin-bottom: 0;
}
.signpost-banner__rich-text strong {
  font-family: 'ff-dax-pro', Arial, sans-serif;
}
.styleguide {
  position: relative;
}
.styleguide .styleguide-nav {
  display: block;
  position: fixed;
  width: 160px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  top: 192px;
  right: 0;
  z-index: 99;
}
.styleguide .styleguide-nav ul {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}
.styleguide .styleguide-nav ul li {
  display: block;
}
.styleguide .styleguide-nav ul li:before {
  display: none;
}
.styleguide .styleguide-nav ul li a {
  display: block;
  line-height: 1.5em;
  color: #ff9900;
}
.styleguide .styleguide-nav ul li a:hover {
  color: #ff9900;
}
.styleguide .style-section {
  display: block;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.styleguide .style-section .style-section-title {
  display: block;
  margin: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 4px 4px 0 0;
  padding: 10px 15px;
  background-color: #f5f5f5;
}
.styleguide .style-section .style-section-content {
  display: block;
  margin: 0;
  border-radius: 0 0 4px 4px;
  padding: 15px;
}
.styleguide .swatch {
  display: block;
  margin-bottom: 15px;
  width: 23%;
  float: left;
  height: 142px;
  margin: 0 1% 16px;
  border: 1px solid #a1a1a1;
  border-radius: 6px;
}
.styleguide .swatch .swatch-colour {
  display: block;
  height: 100px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px 4px 0 0;
  background-color: #ffffff;
}
.styleguide .swatch .swatch-detail {
  display: block;
  border-radius: 0 0 4px 4px;
  border: 1px solid #a1a1a1;
  background-color: #a1a1a1;
  text-align: center;
  color: #fff;
}
.styleguide .swatch .swatch-detail .swatch-hex,
.styleguide .swatch .swatch-detail .swatch-name {
  display: block;
}
.styleguide .swatch .swatch-detail .swatch-hex {
  text-transform: lowercase;
}
.styleguide .style-source-hover {
  position: relative;
}
.styleguide .style-source-hover .style-source-btn {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  height: 32px;
  width: 32px;
  line-height: 32px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.styleguide .style-source-hover .style-source-btn::before {
  content: '\f121';
  font-family: 'FontAwesome';
}
.styleguide .style-source-hover:hover .style-source-btn {
  display: block;
}
.styleguide .style-source-hover.style-section .style-source-btn {
  height: 46px;
  line-height: 46px;
}
@media (max-width: 767px) {
  .styleguide .styleguide-nav {
    display: none;
  }
}
.tabbed-area {
  display: block;
  height: 100%;
  margin-bottom: 32px;
}
.section--info-promos .tabbed-area {
  height: 60%;
}
.tabbed-area__title {
  display: block;
  /*color: #ffffff;*/
  line-height: 1;
  text-align: center;
  text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.5);
}
.tabbed-area__navigation {
  display: none;
}
.tabbed-area__tab {
  display: block;
  width: 100%;
  outline: none;
  margin: 0;
  border: 0;
  padding: 8px 32px;
  /*background: #5f259f;
    color: #ffffff;*/
  line-height: 1.5;
  transition: background-color ease 0.3s, color ease 0.3s;
}
.tabbed-area__tab--active,
.tabbed-area__tab:focus,
.tabbed-area__tab:hover {
  /*background: #ffffff;
        color: #5f259f;*/
}
.tabbed-area__content {
  display: none;
  padding: 24px;
  height: 100%;
  width: 100%;
  border: 1px solid #859a3e;
  overflow: auto;
}
.tabbed-area__content--active {
  display: block;
}
button.tabbed-area__tab.tabbed-area__tab--active {
  background: url(/sitefiles/Images/nav-lines.png) repeat 0 0;
  color: #666;
}
.tabbed-area__content .row {
  display: block;
  margin: 0 -20px;
}
.tabbed-area__content .column {
  display: block;
  float: left;
  width: 50%;
  padding: 0 20px;
}
.tabbed-area__content img {
  height: auto !important;
  max-width: 100%;
}
@media screen and (min-width: 768px) {
  .tabbed-area__title {
    height: 80px;
    margin: 0;
    text-align: left;
  }
  .tabbed-area__navigation {
    display: inline-block;
    height: 64px;
  }
  .tabbed-area__navigation .tabbed-area__tab {
    display: inline-block;
  }
  .tabbed-area__tab {
    display: none;
    height: 100%;
    width: auto;
    outline: none;
    margin: 0;
    border: 0;
    padding: 0 32px;
    /*background: #5f259f;
        color: #ffffff;*/
    line-height: 1.33333;
    transition: background-color ease 0.3s, color ease 0.3s;
  }
  .tabbed-area__tab--active,
  .tabbed-area__tab:focus,
  .tabbed-area__tab:hover {
    /*background: #ffffff;
            color: #5f259f;*/
  }
}
@media screen and (min-width: 992px) {
  .tabbed-area__tab {
    line-height: 1;
  }
}
@media screen and (min-width: 1200px) {
  .tabbed-area__tab {
    line-height: 1.5;
  }
}
.tech-library {
  margin-bottom: 50px;
}
.tech-library .tech-header h1 {
  float: left;
  width: 50%;
  margin: 7px 0;
}
.tech-library .tech-header .tech-library-buttons {
  float: left;
  width: 50%;
  margin-bottom: 20px;
  text-align: right;
}
.tech-library .tech-search {
  background: url(app/modules/~@imgOld/faint-line-repeat.png) repeat;
  padding: 40px;
  position: relative;
  margin-bottom: 50px;
}
.tech-library .tech-search .inner-search {
  background: #fff;
  position: relative;
  overflow: hidden;
}
.tech-library .tech-search .inner-search.typed .search-text {
  left: -200px;
}
.tech-library .tech-search .inner-search.typed input[type='submit'] {
  right: 22px;
}
.tech-library .tech-search input[type='text'],
.tech-library .tech-search input[type='search'] {
  width: 100%;
  border: none;
  background: transparent;
  height: auto;
  line-height: normal;
  padding: 15px 85px 15px 40px;
  font-size: 2.85714286em;
  position: relative;
  z-index: 2;
  outline: none;
}
.tech-library .tech-search input[type='text']:focus + input[type='submit'] {
  right: 22px;
}
.tech-library .tech-search input[type='text']:focus ~ .search-text {
  left: -200px;
}
.tech-library .tech-search .search-text {
  position: absolute;
  top: 36px;
  left: 40px;
  font-size: 2.85714286em;
  color: #859a3e;
  -moz-transition: left 0.4s ease;
  -o-transition: left 0.4s ease;
  -webkit-transition: left 0.4s ease;
  transition: left 0.4s ease;
  z-index: 0;
}
.tech-library .tech-search .search-text i {
  margin-right: 15px;
}
.tech-library .tech-search input[type='submit'] {
  position: absolute;
  top: 18px;
  right: -100px;
  background: url('/sitefiles/images/sprite.png') no-repeat 0 -294px;
  text-indent: -2000em;
  width: 54px;
  height: 54px;
  -moz-transition: right 0.4s ease;
  -o-transition: right 0.4s ease;
  -webkit-transition: right 0.4s ease;
  transition: right 0.4s ease;
  z-index: 3;
}
.tech-library h3 {
  color: #666666;
}
.tech-library .files {
  margin-bottom: 40px;
}
.tech-library .files button {
  background: #e4e9d4;
  color: #859a3e;
  padding: 5px 15px;
  margin-bottom: 40px;
}
.tech-library .files button:hover {
  background: #859a3e;
  color: #e4e9d4;
}
.tech-library .login-register {
  padding: 40px;
  background: url(app/modules/~@imgOld/faint-line-repeat.png) repeat 0px 0px;
  margin: 30px 0 40px;
}
.tech-library .login-register .reg-box .EditingFormInfoLabel {
  float: left;
}
.tech-library .login-register .widget {
  margin: 0;
}
.tech-library .login-register .login-box {
  color: #ffffff;
  -moz-transition: background 0.5s ease-in-out;
  -o-transition: background 0.5s ease-in-out;
  -webkit-transition: background 0.5s ease-in-out;
  transition: background 0.5s ease-in-out;
  position: relative;
}
.tech-library .login-register .login-box .alert {
  margin-top: 0;
}
.tech-library .login-register .login-box .LoginPanelPasswordRetrieval {
  display: block;
}
.tech-library .login-register .login-box.orange-login {
  background: #ff9900;
}
.tech-library .login-register .login-box.orange-login .login-box-back {
  background: #ff9900;
}
.tech-library .login-register .login-box.orange-login .login-btn,
.tech-library .login-register .login-box.orange-login input[type='submit'] {
  background-image: url(app/modules/~@imgOld/submit-arrow-orange.png);
  color: #ff9900;
  background-position: right;
}
.tech-library .login-register .login-box.green-login {
  background: #859a3e;
}
.tech-library .login-register .login-box.green-login .login-box-back {
  background: #859a3e;
}
.tech-library .login-register .login-box.green-login .login-btn,
.tech-library .login-register .login-box.green-login input[type='submit'] {
  background-image: url(app/modules/~@imgOld/submit-arrow-green.png);
  color: #859a3e;
}
.tech-library .login-register .login-box.grey-login {
  background: #575757;
}
.tech-library .login-register .login-box.grey-login .login-box-back {
  background: #575757;
}
.tech-library .login-register .login-box:hover.orange-login {
  background: #ffad33;
}
.tech-library .login-register .login-box:hover.green-login {
  background: #a2b952;
}
.tech-library .login-register .login-box:hover.grey-login {
  background: #717171;
}
.tech-library .login-register .login-box .login-box-top {
  display: block;
  padding: 18px 12px;
  -moz-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  cursor: pointer;
}
.tech-library .login-register .login-box .login-box-top .signpost {
  display: table;
  width: 100%;
  height: 54px;
}
.tech-library .login-register .login-box .login-box-top .signpost .signpost-icon {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 100px;
  text-align: center;
}
.tech-library .login-register .login-box .login-box-top .signpost .signpost-icon img {
  position: absolute;
  top: 0px;
  left: 21px;
  width: 46px;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.tech-library .login-register .login-box .login-box-top .signpost .signpost-text {
  display: table-cell;
  vertical-align: middle;
  font-size: 1.85714286em;
  line-height: 29px;
  color: #fff;
}
.tech-library .login-register .login-box .login-box-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  display: block;
  -moz-transition: max-height 0.8s ease-in-out;
  -o-transition: max-height 0.8s ease-in-out;
  -webkit-transition: max-height 0.8s ease-in-out;
  transition: max-height 0.8s ease-in-out;
  -webkit-transition-delay: 0;
  /* Safari */
  -moz-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
  z-index: 2;
}
.tech-library .login-register .login-box .login-box-back .login-inner {
  padding: 40px 30px 30px;
  position: relative;
}
.tech-library .login-register .login-box .login-box-back .login-inner .IncorrectUserName {
  font-size: 30px;
  text-align: center;
}
.tech-library .login-register .login-box .login-box-back .login-inner__gdpr-checkbox {
  display: flex;
}
.tech-library .login-register .login-box .login-box-back .login-inner .close-box {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 1.64285714em;
  cursor: pointer;
}
.tech-library .login-register .login-box .login-box-back .login-inner .login-btn,
.tech-library .login-register .login-box .login-box-back .login-inner input,
.tech-library .login-register .login-box .login-box-back .login-inner textarea {
  display: block;
  width: 100%;
  border: none;
  margin-bottom: 20px;
  padding: 15px 40px 15px 15px;
  border-radius: 0px;
  height: auto;
}
.tech-library .login-register .login-box .login-box-back .login-inner input.email-icon,
.tech-library .login-register .login-box .login-box-back .login-inner input[type='email'] {
  background: #fff url(/sitefiles/images/email-icon.jpg) no-repeat right top;
}
.tech-library .login-register .login-box .login-box-back .login-inner input.name-icon {
  background: #fff url(/sitefiles/images/name-icon.jpg) no-repeat right top;
}
.tech-library .login-register .login-box .login-box-back .login-inner input.company-icon {
  background: #fff url(/sitefiles/images/company-icon.jpg) no-repeat right top;
}
.tech-library .login-register .login-box .login-box-back .login-inner input.password-icon,
.tech-library .login-register .login-box .login-box-back .login-inner input[type='password'] {
  background: #fff url(/sitefiles/images/password-icon.jpg) no-repeat right top;
}
.tech-library .login-register .login-box .login-box-back .login-inner input.address-icon {
  background: #fff url(/sitefiles/images/address-icon.jpg) no-repeat right top;
}
.tech-library .login-register .login-box .login-box-back .login-inner a {
  color: #fff;
  text-decoration: none;
  display: block;
  margin-bottom: 30px;
}
.tech-library .login-register .login-box .login-box-back .login-inner a:hover {
  color: #ff9900;
}
.tech-library .login-register .login-box .login-box-back .login-inner input[type='submit'],
.tech-library .login-register .login-box .login-box-back .login-inner .login-btn {
  background-color: #fff;
  width: auto;
  display: inline-block;
  margin: 0;
  font-size: 1.28571429em;
  vertical-align: middle;
}
.tech-library .login-register .login-box .login-box-back .login-inner .login-btn {
  color: #859a3e;
  background: #fff url(app/modules/~@imgOld/submit-arrow-green.png) no-repeat right center;
  float: right;
}
.tech-library .login-register .login-box .login-box-back .login-inner .checkbox input[type='checkbox'] {
  display: inline-block;
  vertical-align: middle;
  float: none;
  margin-top: -1px;
  width: auto;
  margin-bottom: 0;
}
.tech-library .login-register .login-box .login-box-back .login-inner .checkbox label {
  color: #ffffff;
  vertical-align: middle;
  padding-left: 4px;
}
.tech-library .login-register .login-box.open-box.reg-box .login-box-back {
  max-height: 1000px;
}
.tech-library .login-register .login-box.open-box .login-box-top {
  opacity: 0;
}
.tech-library .login-register .login-box.open-box .login-box-back {
  max-height: 1000px;
  -webkit-transition-delay: 0.5s;
  /* Safari */
  -moz-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
  z-index: 10;
}
.folder-item {
  margin-bottom: 30px;
  width: 100%;
  display: table;
}
.folder-item .folder {
  display: table-cell;
  width: 54px;
  vertical-align: top;
}
.folder-item .folder-desc {
  display: table-cell;
  padding-left: 10px;
  vertical-align: top;
}
.folder-item .folder-desc a {
  text-decoration: none;
}
.folder-item .folder-desc h4 {
  color: #729c2a;
  font-size: 1.21428571em;
  margin-top: 3px;
  margin-bottom: 0;
  -moz-transition: color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out;
  -webkit-transition: color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out;
}
.folder-item .folder-desc h4:hover {
  color: #89bc33;
}
.folder-item .folder-desc p {
  margin: 0;
  font-size: 0.92857143em;
  color: #999999;
}
.tech-details {
  width: 80%;
  float: right;
}
@media (max-width: 996px) {
  .tech-details {
    width: 100%;
  }
}
.intro-tech {
  margin-bottom: 40px;
}
.intro-tech .grey-bar {
  margin-top: 30px;
}
.tech-features {
  margin-bottom: 12px;
}
.tech-features .tech-features__item {
  position: relative;
}
.tech-features .tech-features__item:before {
  content: '';
  display: inline-block;
  margin-right: 8px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #859a3e;
  vertical-align: middle;
}
.tech-date {
  color: #666666;
  font-size: 13px;
  margin-bottom: 13px;
}
.tech-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.tech-footer .btn {
  margin-right: 12px;
}
@media (max-width: 996px) {
  .tech-footer {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .tech-footer .btn {
    margin-right: 0px;
    margin-bottom: 12px;
    width: 100%;
    display: block;
  }
}
.youtube-btn,
.download-btn {
  padding: 10px 25px 8px 88px !important;
  position: relative;
  display: inline-block !important;
  font-size: 1.9em !important;
  margin-top: 15px;
}
.youtube-btn:before,
.download-btn:before {
  display: block;
  font-family: 'FontAwesome';
  font-size: 1.42857143em;
  position: absolute;
  top: 3px;
  color: #ffd596;
  left: 29px;
  right: auto;
}
.youtube-btn:before {
  content: '\f16a';
}
.download-btn:before {
  content: '\f019';
}
.tech-library .login-register .forgot-password,
.tech-library .login-register .login-box .login-box-back .login-inner .checkbox {
  text-align: right;
}
.tech-library .login-register .login-box .login-box-back .login-inner .checkbox {
  padding-left: 20px;
  margin-top: 22px;
}
.tech-library .login-register .login-box .login-box-back .login-inner .checkbox input[type='checkbox'] {
  width: 16px;
  height: 16px;
  margin-top: 2px;
}
@media (max-width: 1199px) {
  .tech-library .login-register .login-box .login-box-top .signpost .signpost-icon {
    width: 77px;
  }
  .tech-library .login-register .login-box .login-box-top .signpost .signpost-icon img {
    left: 7px;
  }
}
@media (max-width: 991px) {
  .tech-library .tech-search .search-text {
    top: 50%;
    margin-top: -7px;
  }
  .tech-library .tech-search input[type='submit'] {
    top: 50%;
    margin-top: -28px;
  }
}
@media (max-width: 767px) {
  .tech-library .tech-header h1,
  .tech-library .tech-header .tech-library-buttons {
    float: none;
    width: 100%;
    text-align: left;
  }
  .intro-tech,
  .tech-library .files {
    margin-bottom: 25px;
  }
}
@media (max-width: 480px) {
  .tech-library .login-register {
    padding: 15px;
  }
  .tech-library .login-register .login-box .login-box-top .signpost .signpost-text {
    font-size: 1.6em;
  }
  .tech-library .tech-header .tech-library-buttons .btn {
    margin-bottom: 15px;
  }
  .tech-library .tech-search {
    padding: 40px 20px;
  }
  .tech-library .tech-search .search-text {
    font-size: 1.85714286em;
    top: 50%;
    margin: -10px 0 0 0;
  }
  .tech-library .tech-search input[type='text'],
  .tech-library .tech-search input[type='search'] {
    padding: 15px 72px 15px 14px;
  }
  .tech-library .tech-search input[type='submit'] {
    background: url('/sitefiles/images/sprite.png') no-repeat -55px -294px;
    top: 50%;
    margin-top: -26px;
  }
  .youtube-btn {
    font-size: 1.4em !important;
  }
}
.replaced .tech-item {
  opacity: 0;
}
#ItemDetails {
  margin-bottom: 30px;
  clear: both;
}
#ItemDetails .tech-icon {
  float: left;
  margin-right: 1%;
  width: 19%;
  margin-bottom: 0px;
}
@media (max-width: 996px) {
  #ItemDetails .tech-icon {
    float: none;
    margin-right: 0px;
    width: auto;
    margin-bottom: 12px;
  }
}
#ItemDetails .tech-icon img {
  max-width: 100%;
}
#ItemDetails .tech-desc .tags {
  font-size: 0.92857143em;
}
#ItemDetails .tech-desc .tags ul {
  padding: 0;
}
#ItemDetails .tech-desc .tags ul li {
  list-style-type: none;
  display: inline-block;
  padding-left: 3px;
}
#ItemDetails .tech-desc .tags ul li:before {
  display: none;
}
#ItemDetails .tech-desc .tags ul a {
  position: relative;
  display: inline-block;
  padding-right: 9px;
  color: #729c2a;
}
#ItemDetails .tech-desc .tags ul a:hover {
  color: #81b02f;
}
#ItemDetails .tech-desc .tags ul a:before {
  display: block;
  content: '|';
  width: 13px;
  text-align: center;
  position: absolute;
  top: 0;
  right: -4px;
  background: none;
}
.tech-tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (max-width: 996px) {
  .tech-tabs {
    width: 100%;
  }
}
.tech-tabs__button {
  color: #6f9d20;
  padding: 14px 28px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  text-align: center;
}
@media (max-width: 996px) {
  .tech-tabs__button {
    font-size: 20px;
    flex: 1 0 auto;
  }
}
.tech-tabs__button.active {
  background: #f6f6f6;
}
.tech-tabs__tab {
  display: none;
  padding: 28px;
  background-color: #f6f6f6;
}
@media (max-width: 996px) {
  .tech-tabs__tab {
    padding: 12px;
  }
}
.tech-tabs__tab.active {
  display: block;
}
.previous-solutions__header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 20px;
  align-items: center;
}
@media (max-width: 996px) {
  .previous-solutions__header {
    flex-wrap: wrap;
  }
}
.previous-solutions h2 {
  display: inline-block;
  margin-bottom: 0px;
}
@media (max-width: 996px) {
  .previous-solutions h2 {
    margin-bottom: 10px;
  }
}
.previous-solutions .ism-modal-prompt {
  display: inline-block;
  margin-left: auto;
}
@media (max-width: 996px) {
  .previous-solutions .ism-modal-prompt {
    width: 100%;
    text-align: center;
  }
}
.input-group .twitter-typeahead {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}
.twitter-typeahead .tt-hint {
  color: #585858;
  color: rgba(88, 88, 88, 0.75);
}
.twitter-typeahead .tt-menu {
  display: block;
  width: 100%;
  padding: 4px 0;
  background-color: #fff;
  box-shadow: #585858 2px 2px 4px;
  box-shadow: rgba(88, 88, 88, 0.25) 2px 2px 4px;
}
.twitter-typeahead .tt-menu .tt-dataset {
  display: block;
}
.twitter-typeahead .tt-menu .tt-dataset .tt-suggestion {
  display: block;
  padding: 5px 12px;
  cursor: pointer;
  min-height: 60px;
  clear: both;
  color: #666666;
  line-height: 1.4em;
}
.twitter-typeahead .tt-menu .tt-dataset .tt-suggestion:hover {
  color: #fff;
  background-color: #ff9900;
}
.twitter-typeahead .tt-menu .tt-dataset .tt-suggestion p {
  margin: 10px 0 0;
}
.twitter-typeahead .tt-menu .tt-dataset .tt-suggestion img {
  max-width: 50px;
  float: left;
  margin-right: 15px;
}
.widget {
  margin-bottom: 25px;
}
.widget.side-nav-widget {
  border: 1px solid #bebebe;
}
.widget.side-nav-widget .link-wrap {
  padding: 10px 0;
}
.widget.side-nav-widget h3 {
  margin: 0;
  font-size: 1.28571429em;
  padding: 18px;
  background: #859a3e;
  color: #fff;
}
.widget.side-nav-widget ul {
  padding: 0;
  margin: 0;
}
.widget.side-nav-widget ul li {
  display: block;
  list-style-type: none;
  position: relative;
  padding-left: 0;
}
.widget.side-nav-widget ul li:before {
  position: absolute;
  top: 11px;
  left: 20px;
  display: none;
  content: ' ';
  width: 13px;
  height: 13px;
  background: url('/sitefiles/images/sprite.png') no-repeat -4px -80px;
}
.widget.side-nav-widget ul li:hover:before {
  display: block;
  -moz-animation: wigglearrow 0.7s ease-in-out forwards;
  -o-animation: wigglearrow 0.7s ease-in-out forwards;
  -webkit-animation: wigglearrow 0.7s ease-in-out forwards;
  animation: wigglearrow 0.7s ease-in-out forwards;
}
.widget.side-nav-widget ul li.active:before {
  display: block;
  background: url('/sitefiles/images/sprite.png') no-repeat -4px -80px;
}
.widget.side-nav-widget ul li.active a {
  background: #ebebeb;
  color: #859a3e;
}
.widget.side-nav-widget ul li a {
  display: block;
  padding: 9px 30px 6px 49px;
  font-size: 1.14285714em;
  color: #575757;
  font-weight: bold;
  text-decoration: none;
  -moz-transition: background 0.3s ease-in-out;
  -o-transition: background 0.3s ease-in-out;
  -webkit-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}
.widget.side-nav-widget ul li a:hover,
.widget.side-nav-widget ul li a.active {
  background: #ebebeb;
  color: #859a3e;
}
.widget.side-nav-widget.enquiry-basket-guide {
  border: none;
  position: relative;
}
.widget.side-nav-widget.enquiry-basket-guide.always-show {
  display: block !important;
}
.widget.side-nav-widget.enquiry-basket-guide.compact {
  display: none;
}
.widget.side-nav-widget.enquiry-basket-guide.letterbox {
  display: block;
}
.widget.side-nav-widget.enquiry-basket-guide.letterbox .row {
  margin: 20px 0 0 0;
}
.widget.side-nav-widget.enquiry-basket-guide.letterbox .col-xs-1,
.widget.side-nav-widget.enquiry-basket-guide.letterbox .col-xs-2 {
  padding: 0;
}
.widget.side-nav-widget.enquiry-basket-guide.letterbox .col-xs-1 {
  width: 6.6%;
}
.widget.side-nav-widget.enquiry-basket-guide.letterbox .col-xs-2 {
  width: 20%;
}
.widget.side-nav-widget.enquiry-basket-guide img {
  display: block;
  margin: auto;
  margin-top: 30px;
}
.widget.side-nav-widget.enquiry-basket-guide .rotate90 {
  -ms-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  margin-top: 0;
}
@media (max-width: 991px) {
  .widget.side-nav-widget.enquiry-basket-guide .rotate90 {
    margin-top: 25px;
  }
}
.widget.side-nav-widget.enquiry-basket-guide .rotate180 {
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.widget.side-nav-widget.enquiry-basket-guide table,
.widget.side-nav-widget.enquiry-basket-guide td {
  border: none;
  white-space: normal;
}
.widget.side-nav-widget.enquiry-basket-guide table.no-padding,
.widget.side-nav-widget.enquiry-basket-guide td.no-padding {
  padding: 0;
}
.widget.side-nav-widget.enquiry-basket-guide .circle {
  background: #ff9900;
  height: 50px;
  width: 50px;
  line-height: 50px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  display: block;
  position: relative;
  border: 2px solid #cccccc;
  margin: auto;
  text-align: center;
  vertical-align: bottom;
  font-size: 1.8em;
}
.widget.side-nav-widget.enquiry-basket-guide .circle .fa {
  color: #ffffff;
}
.widget.side-nav-widget.enquiry-basket-guide p {
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}
.widget.twitter-widget iframe {
  width: 100% !important;
}
.widget.sign-post-widget {
  -moz-transition: background 0.5s ease-in-out;
  -o-transition: background 0.5s ease-in-out;
  -webkit-transition: background 0.5s ease-in-out;
  transition: background 0.5s ease-in-out;
}
.widget.sign-post-widget.orange-signpost {
  background: #ff9900;
}
.widget.sign-post-widget.green-signpost {
  background: #859a3e;
}
.widget.sign-post-widget.grey-signpost {
  background: #575757;
}
.widget.sign-post-widget:hover.orange-signpost {
  background: #ffad33;
}
.widget.sign-post-widget:hover.green-signpost {
  background: #a2b952;
}
.widget.sign-post-widget:hover.grey-signpost {
  background: #717171;
}
.widget.sign-post-widget.tech-file a .signpost .signpost-icon img {
  top: -3px !important;
  width: 50px;
}
.widget.sign-post-widget a {
  outline: none;
  text-decoration: none;
  display: block;
  padding: 18px 12px;
  /*&:hover {
                .signpost .signpost-icon img {
                    width: 57px;
                    top: -4px;
                    left: 18px;
                }
            }*/
}
.widget.sign-post-widget .signpost {
  display: table;
  width: 100%;
}
.widget.sign-post-widget .signpost .depot-title {
  font-size: 1.64285714em;
  line-height: 29px;
  color: #fff;
  text-align: center;
  padding: 10px 0 3px 0;
}
.widget.sign-post-widget .signpost .postcode-select {
  padding: 0 20px;
}
.widget.sign-post-widget .signpost .postcode-select .chosen-container {
  height: 36px;
  line-height: 36px;
  margin-bottom: 17px;
}
.widget.sign-post-widget .signpost .postcode-select .chosen-container .chosen-single {
  padding: 1px 12px;
  height: 36px;
  line-height: 36px;
}
.widget.sign-post-widget .signpost .postcode-select .chosen-container .chosen-single div {
  padding: 5px 15px;
}
.widget.sign-post-widget .signpost .postcode-select .chosen-container .chosen-single span {
  padding-right: 0;
  height: 36px;
  line-height: 36px;
}
.widget.sign-post-widget .signpost .signpost-icon {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 100px;
  text-align: center;
}
.widget.sign-post-widget .signpost .signpost-icon img {
  top: 0px;
  left: 21px;
  width: 46px;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.widget.sign-post-widget .signpost .signpost-text {
  display: table-cell;
  vertical-align: middle;
  font-size: 1.85714286em;
  line-height: 29px;
  color: #fff;
}
.widget.sign-post-widget .dropdown-signpost {
  display: block;
}
.widget.banner-widget {
  position: relative;
  padding-right: 38px;
}
.widget.banner-widget a {
  text-decoration: none;
}
.widget.banner-widget a:hover h3 {
  background: #81b02f;
}
.widget.banner-widget h3 {
  background: #859a3e;
  margin: 0;
  color: #fff;
  font-size: 1.14285714em;
  padding: 10px 15px;
  -moz-transition: background 0.4s ease-in-out;
  -o-transition: background 0.4s ease-in-out;
  -webkit-transition: background 0.4s ease-in-out;
  transition: background 0.4s ease-in-out;
}
.widget.banner-widget .new-item {
  position: absolute;
  top: 0;
  padding: 16px 10px;
  left: 0;
  text-transform: uppercase;
  font-size: 1.35714286em;
  color: #fff;
  background: #ff9900;
}
.widget.banner-widget #widget-banner .item {
  position: relative;
}
.widget.banner-widget #widget-banner .owl-controls .owl-buttons {
  position: absolute;
  top: 50%;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -38px;
}
.widget.banner-widget #widget-banner .owl-controls .owl-buttons .owl-next {
  opacity: 1;
  margin: 14px 5px;
  text-indent: -9999px;
  display: block;
  background: url('/sitefiles/images/sprite.png') no-repeat -4px -105px;
  width: 26px;
  height: 26px;
}
.widget.banner-widget #widget-banner .owl-controls .owl-buttons .owl-next:hover {
  background-position: -36px -105px;
}
.widget.banner-widget #widget-banner .owl-controls .owl-buttons .owl-prev {
  opacity: 1;
  margin: 14px 5px;
  text-indent: -9999px;
  display: block;
  background: url('/sitefiles/images/sprite.png') no-repeat -4px -139px;
  width: 26px;
  height: 26px;
}
.widget.banner-widget #widget-banner .owl-controls .owl-buttons .owl-prev:hover {
  background-position: -36px -139px;
}
.widget.technical-widget {
  position: relative;
}
.widget.technical-widget .login-links {
  position: absolute;
  top: 0px;
  right: 0px;
}
.widget.technical-widget .login-links a {
  position: relative;
  display: inline-block;
  padding-right: 10px;
}
.widget.technical-widget .login-links a:last-child:before {
  display: none;
}
.widget.technical-widget .login-links a:before {
  display: block;
  content: '/';
  width: 13px;
  text-align: center;
  position: absolute;
  top: 0;
  right: -4px;
}
.widget.technical-widget h3 {
  font-size: 1.35714286em;
  color: #575757;
  font-weight: bold;
  margin: 0 0 20px;
  padding-right: 114px;
}
.widget.technical-widget a {
  text-decoration: none;
}
.widget.technical-widget p {
  font-size: 0.92857143em;
  line-height: 17px;
  color: #707070;
}
.widget.technical-widget .tech-item {
  display: table;
  width: 100%;
  margin-bottom: 25px;
}
.widget.technical-widget .tech-item .tech-icon {
  display: table-cell;
  vertical-align: top;
  width: 52px;
  max-width: 52px;
}
.widget.technical-widget .tech-item .tech-desc {
  display: table-cell;
  vertical-align: top;
}
.widget.technical-widget .tech-item .tech-desc h3 {
  font-size: 1.21428571em;
  color: #6f9d20;
  font-weight: normal;
  margin: 0 0 3px;
  padding-right: 0;
}
.widget.technical-widget .tech-item .tech-desc h3:hover {
  color: #84bb26 !important;
}
.widget.technical-widget .tech-item .tech-desc .tags {
  font-size: 0.92857143em;
}
.widget.technical-widget .tech-item .tech-desc .tags ul {
  padding: 0;
}
.widget.technical-widget .tech-item .tech-desc .tags ul li {
  list-style-type: none;
  display: inline-block;
  padding-left: 3px;
}
.widget.technical-widget .tech-item .tech-desc .tags ul li:before {
  display: none;
}
.widget.technical-widget .tech-item .tech-desc .tags ul a {
  position: relative;
  display: inline-block;
  padding-right: 9px;
  color: #729c2a;
}
.widget.technical-widget .tech-item .tech-desc .tags ul a:hover {
  color: #81b02f;
}
.widget.technical-widget .tech-item .tech-desc .tags ul a:before {
  display: block;
  content: '|';
  width: 13px;
  text-align: center;
  position: absolute;
  top: 0;
  right: -4px;
  background: none;
}
.widget.technical-widget .tech-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.widget.technical-widget .tech-grid .tech-item {
  background-color: #ffffff;
  padding: 12px;
  width: 48%;
}
@media (max-width: 996px) {
  .widget.technical-widget .tech-grid .tech-item {
    width: 100%;
    margin-bottom: 12px;
  }
  .widget.technical-widget .tech-grid .tech-item:last-child {
    margin-bottom: 0px;
  }
}
.widget.advertise-widget {
  position: relative;
}
.widget.advertise-widget .owl-pagination {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.widget.advertise-widget .owl-theme .owl-controls .owl-page span {
  display: block;
  margin: 5px 3px;
  width: 10px;
  height: 10px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background: #4f4f4f;
}
.widget.advertise-widget .item img {
  width: 100%;
}
.widget.article-widget {
  position: relative;
}
.widget.article-widget .article-links {
  position: absolute;
  top: 0px;
  right: 0px;
}
.widget.article-widget h3 {
  font-size: 1.35714286em;
  color: #575757;
  font-weight: bold;
  margin: 0 0 20px;
  padding-right: 66px;
}
.widget.article-widget a {
  text-decoration: none;
}
.widget.article-widget p {
  font-size: 0.92857143em;
  line-height: 17px;
  color: #707070;
}
.widget.article-widget .article-item-wrap {
  margin-bottom: 30px;
}
.widget.article-widget .article-item-wrap .grey-divide {
  display: block;
  position: relative;
  height: 8px;
  width: 100%;
  background: #cccccc;
}
.widget.article-widget .article-item-wrap .grey-divide:before {
  position: absolute;
  top: 0;
  right: 0;
  content: ' ';
  display: block;
  height: 9px;
  width: 35px;
  background: url(/sitefiles/images/green-edge.png) no-repeat 0px 1px;
}
.widget.article-widget .article-item-wrap .article-item {
  display: table;
  width: 100%;
}
.widget.article-widget .article-item-wrap .article-item .article-icon {
  position: relative;
  display: table-cell;
  vertical-align: top;
  width: 92px;
  max-width: 92px;
}
.widget.article-widget .article-item-wrap .article-item .article-icon a {
  position: relative;
  display: block;
}
.widget.article-widget .article-item-wrap .article-item .article-icon a:hover .transparent-overlay {
  background: #000;
  opacity: 0.4;
}
.widget.article-widget .article-item-wrap .article-item .article-icon .transparent-overlay {
  background: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.widget.article-widget .article-item-wrap .article-item .article-icon .icon-area {
  width: 34px;
  height: 34px;
  display: block;
  position: absolute;
  background: #859a3e;
  top: 0;
  right: 0;
}
.widget.article-widget .article-item-wrap .article-item .article-desc {
  display: table-cell;
  vertical-align: top;
  padding-left: 15px;
}
.widget.article-widget .article-item-wrap .article-item .article-desc h3 {
  font-size: 1.21428571em;
  color: #6f9d20;
  font-weight: normal;
  margin: 0 0 3px;
  padding-right: 0;
}
.widget.article-widget .article-item-wrap .article-item .article-desc h3:hover {
  color: #84bb26 !important;
}
.widget.article-widget .article-item-wrap .article-item .article-desc .tags {
  font-size: 0.92857143em;
}
.widget.article-widget .article-item-wrap .article-item .article-desc .tags a {
  position: relative;
  display: inline-block;
  padding-right: 13px;
}
.widget.article-widget .article-item-wrap .article-item .article-desc .tags a:before {
  display: block;
  content: '|';
  width: 13px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
}
.widget.blog-widget {
  position: relative;
  padding-top: 39px;
}
.widget.blog-widget .blog-profile {
  position: absolute;
  top: 0;
  left: 0;
}
.widget.blog-widget .blog-links {
  position: absolute;
  top: 0;
  right: 0px;
}
.widget.blog-widget h3 {
  font-size: 1.35714286em;
  color: #575757;
  font-weight: bold;
  margin: 0 0 20px;
  padding-left: 62px;
  padding-right: 66px;
  position: relative;
  top: -37px;
}
.widget.blog-widget a {
  text-decoration: none;
}
.widget.blog-widget p {
  font-size: 0.92857143em;
  line-height: 17px;
  color: #707070;
}
.widget.blog-widget .blog-item-wrap {
  margin-bottom: 0px;
}
.widget.blog-widget .blog-item-wrap .grey-divide {
  display: block;
  position: relative;
  height: 8px;
  width: 100%;
  background: #cccccc;
}
.widget.blog-widget .blog-item-wrap .grey-divide:before {
  position: absolute;
  top: 0;
  right: 0;
  content: ' ';
  display: block;
  height: 9px;
  width: 35px;
  background: url(/sitefiles/images/green-edge.png) no-repeat 0px 1px;
}
.widget.blog-widget .blog-item-wrap .blog-item {
  display: table;
  width: 100%;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-icon {
  position: relative;
  display: table-cell;
  vertical-align: top;
  width: 105px;
  max-width: 105px;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-icon a {
  position: relative;
  display: block;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-icon a:hover .transparent-overlay {
  background: #000;
  opacity: 0.4;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-icon .transparent-overlay {
  background: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-desc {
  display: table-cell;
  vertical-align: top;
  padding-left: 15px;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-desc h3 {
  font-size: 1.21428571em;
  color: #6f9d20;
  font-weight: normal;
  margin: 0 0 3px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  top: 0;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-desc h3:hover {
  color: #84bb26 !important;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-desc .comments {
  font-size: 0.92857143em;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-desc .comments ul {
  padding: 0;
  margin: 0 0 4px;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-desc .comments ul li {
  position: relative;
  list-style-type: none;
  display: inline-block;
  padding-left: 0;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-desc .comments ul li:last-child:before {
  display: none;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-desc .comments ul li:before {
  display: block;
  content: '|';
  width: 13px;
  text-align: center;
  position: absolute;
  color: #729c2a;
  top: 0;
  left: auto;
  right: -4px;
  background: none;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-desc .comments ul a {
  display: block;
  padding-right: 9px;
  color: #729c2a;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-desc .comments ul a:hover {
  color: #81b02f;
}
.widget.block-content-widget {
  position: relative;
}
.widget.block-content-widget .block-links {
  position: absolute;
  top: 0;
  right: 0px;
}
.widget.block-content-widget h3 {
  font-size: 1.35714286em;
  color: #575757;
  font-weight: bold;
  margin: 0 0 20px;
  padding-right: 66px;
}
.widget.block-content-widget a {
  text-decoration: none;
}
.widget.block-content-widget p {
  font-size: 0.92857143em;
  line-height: 17px;
  color: #707070;
  width: 92%;
}
.widget.video-popup-widget a {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  text-decoration: none;
}
.widget.video-popup-widget a:before {
  content: '\f16a';
  font-family: 'FontAwesome';
  font-size: 4.71428571em;
  color: #fff;
  opacity: 0.7;
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.widget.video-popup-widget a:hover:before {
  opacity: 1;
  font-size: 74px;
}
.widget.video-popup-widget a:hover h3 {
  background: #81b02f;
}
.widget.video-popup-widget a h3 {
  margin: 0;
  color: #fff;
  background: #859a3e;
  padding: 18px 10px;
  font-size: 1.28571429em;
  text-align: left;
  -moz-transition: background 0.4s ease-in-out;
  -o-transition: background 0.4s ease-in-out;
  -webkit-transition: background 0.4s ease-in-out;
  transition: background 0.4s ease-in-out;
}
.widget.standard-page-banner .page-banner-inner .item {
  position: relative;
}
.widget.standard-page-banner .page-banner-inner .item .text-overlay {
  position: absolute;
  top: 50px;
  left: 0;
}
.widget.standard-page-banner .page-banner-inner .item .text-overlay h3 {
  background: #fff;
  color: #729c2a;
  font-size: 2.14285714em;
  display: inline-block;
  padding: 20px 25px;
}
.widget.standard-page-banner .page-banner-inner .item .banner-logo {
  position: absolute;
  bottom: 0;
  right: 0;
}
.widget.standard-page-banner .page-banner-inner .item .banner-logo img {
  padding: 15px;
  background: #fff;
  display: inline-block;
}
.widget.standard-page-banner .page-banner-inner.owl-theme .owl-controls {
  margin: 0;
}
.widget.standard-page-banner .page-banner-inner.owl-theme .owl-controls .owl-buttons div {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -21px;
  width: 44px;
  height: 44px;
  background: url('/sitefiles/images/sprite.png') no-repeat 0 -180px;
  text-indent: -2000em;
}
.widget.standard-page-banner .page-banner-inner.owl-theme .owl-controls .owl-buttons div:hover {
  background-position: -60px -180px;
}
.widget.standard-page-banner .page-banner-inner.owl-theme .owl-controls .owl-buttons div.owl-next {
  left: auto;
  right: 0;
  background-position: 0 -230px;
}
.widget.standard-page-banner .page-banner-inner.owl-theme .owl-controls .owl-buttons div.owl-next:hover {
  background-position: -60px -230px;
}
.widget.featured-products .featured-title h5 {
  float: left;
}
.widget.featured-products .featured-title a {
  float: right;
}
.widget.featured-products .featured-product {
  position: relative;
  overflow: hidden;
  margin-bottom: 25px;
}
.widget.featured-products .featured-product .new {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  background: #ff9900;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 1.28571429em;
  padding-top: 20px;
  z-index: 10;
}
.widget.featured-products .featured-product .featured-text {
  top: 100%;
  position: absolute;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  bottom: 0;
  -moz-transition: top 0.3s ease;
  -o-transition: top 0.3s ease;
  -webkit-transition: top 0.3s ease;
  transition: top 0.3s ease;
}
.widget.featured-products .featured-product .featured-text a {
  display: block;
  text-decoration: none;
}
.widget.featured-products .featured-product .featured-text h6 {
  width: 100%;
  background: #859a3e;
  padding: 10px;
  height: 52px;
  color: #fff;
  margin: -52px 0 0 0;
  -moz-transition: margin 0.3s ease;
  -o-transition: margin 0.3s ease;
  -webkit-transition: margin 0.3s ease;
  transition: margin 0.3s ease;
}
.widget.featured-products .featured-product .featured-text p {
  padding: 10px;
  color: #ffffff;
}
.widget.contact-widget h3 {
  font-size: 2em;
  color: #575757;
  font-weight: normal;
}
.widget.contact-widget label {
  font-size: 2.28571429em;
  padding-right: 5px;
  font-weight: normal;
  color: #729c2a;
}
.widget.contact-widget a {
  font-size: 2.28571429em;
  font-weight: normal;
  color: #729c2a;
  text-decoration: none;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.widget.contact-widget a:hover {
  color: #8fc435;
}
.widget.contact-widget .tel-contact {
  margin-bottom: 14px;
}
.widget.contact-widget .email-contact {
  margin: 18px 0 25px;
}
.widget.contact-widget .email-contact label {
  font-size: 1.14285714em;
  padding-right: 10px;
  font-weight: bold;
}
.widget.contact-widget .email-contact a {
  font-size: 1.14285714em;
  font-weight: bold;
}
.widget .icon-area {
  z-index: 50;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.widget .video .icon-area .icon-img {
  display: block;
  width: 34px;
  height: 34px;
  background: url(app/modules/~@imgOld/video-feature.png) no-repeat 0px 0px;
}
.widget .news .icon-area .icon-img {
  display: block;
  width: 34px;
  height: 34px;
  background: url(app/modules/~@imgOld/news-feature.png) no-repeat 0px 0px;
}
.widget .casestudy .icon-area .icon-img {
  display: block;
  width: 34px;
  height: 34px;
  background: url(app/modules/~@imgOld/case-feature.png) no-repeat 0px 0px;
}
.widget a:hover .icon-area {
  width: 40px !important;
  height: 40px !important;
  padding: 3px;
}
.blog-tags {
  position: relative;
  font-size: 0.92857143em;
  padding-left: 22px;
  clear: both;
}
.blog-tags:before {
  position: absolute;
  top: 0px;
  left: 0;
  display: block;
  content: ' ';
  background: url('/sitefiles/images/sprite.png') no-repeat -130px 1px;
  width: 18px;
  height: 18px;
}
.blog-tags ul {
  padding: 0;
}
.blog-tags ul li {
  position: relative;
  list-style-type: none;
  display: inline-block;
  padding-left: 0;
}
.blog-tags ul li:last-child:before {
  display: none;
}
.blog-tags ul li:before {
  display: block;
  /*content: '|';*/
  width: 13px;
  text-align: center;
  position: absolute;
  color: #729c2a;
  top: 0;
  right: -4px;
  background: none;
}
.blog-tags ul a {
  display: block;
  padding-right: 4px;
  color: #729c2a;
}
.blog-tags ul a:hover {
  color: #81b02f;
}
.case-box .blog-tags ul li:before {
  content: none;
}
.case-box .blog-tags ul li a {
  padding-right: 3px;
}
.media-hub .case-box .blog-top {
  padding-right: 65px;
}
.media-hub .case-box .blog-top h5 {
  padding-left: 53px;
}
.media-hub .case-box .blog-top img {
  position: absolute;
  left: -8px;
  top: -10px;
  width: 50px;
}
@media (min-width: 767px) {
  .everything-else .widget.featured-products .featured-product:hover .featured-text {
    top: 0;
  }
  .everything-else .widget.featured-products .featured-product:hover .featured-text h6 {
    margin: 0;
  }
}
.IE8 .widget.featured-products .featured-product .featured-text {
  background: transparent url(/sitefiles/images/opacity-40.png) repeat 0px 0px !important;
}
.strip {
  background: #e3e3e3;
  margin-bottom: 4px;
  padding: 14px 10px 14px 42px;
}
.strip .strip-content {
  display: table;
  width: 100%;
}
.strip .strip-content .small-icon {
  display: table-cell;
  vertical-align: middle;
  width: 22px;
}
.strip .strip-content .strip-text {
  display: table-cell;
  vertical-align: middle;
  font-weight: bold;
  font-size: 1.21428571em;
  color: #575757;
  padding-left: 10px;
}
.IE8 .widget.article-widget .article-item-wrap .article-item .article-icon a:hover .transparent-overlay,
.IE8 .widget.blog-widget .blog-item-wrap .blog-item .blog-icon a:hover .transparent-overlay {
  background: transparent url(/sitefiles/images/opacity-40.png) repeat 0px 0px !important;
  opacity: 1 !important;
}
.IE8 .video-popup-widget.widget a:before {
  left: 50%;
  margin-left: -30px;
  right: auto;
}
@media (max-width: 1199px) {
  .widget.sign-post-widget {
    /*a:hover {
                .signpost .signpost-icon {
                    img {
                        left: 6px;
                        width: 55px;
                    }
                }
            }*/
  }
  .widget.sign-post-widget .signpost .signpost-icon {
    width: 77px;
  }
  .widget.sign-post-widget .signpost .signpost-icon img {
    left: 7px;
  }
  .widget.standard-page-banner .page-banner-inner .item .text-overlay {
    top: 0;
  }
  .widget.standard-page-banner .page-banner-inner .item .banner-logo img {
    max-width: 170px;
  }
  .widget.enquiry-basket-guide.side-nav-widget.letterbox {
    display: none;
  }
  .widget.enquiry-basket-guide.side-nav-widget.compact {
    display: block;
  }
}
@media (max-width: 991px) {
  .widget.standard-page-banner .page-banner-inner .item .text-overlay h3 {
    font-size: 1.4em;
    padding: 15px;
    margin: 0;
  }
  .widget.map-filter ul li a {
    padding: 7px 30px 5px 49px;
  }
  .widget.contact-widget label {
    font-size: 1.85714286em;
  }
  .widget.contact-widget a {
    font-size: 1.85714286em;
  }
  .widget.contact-widget .email-contact {
    margin: 13px 0 21px;
    font-size: 0.85714286em;
  }
  .widget.blog-widget .blog-item-wrap .blog-item .blog-icon {
    display: none;
  }
  .widget.blog-widget h3 {
    margin: 0;
  }
  .widget.blog-widget .blog-item-wrap .blog-item .blog-desc {
    padding-left: 0;
  }
  .featured-products.widget {
    padding: 0 20px;
  }
}
@media (max-width: 767px) {
  .widget.side-nav-widget {
    display: none;
  }
  .widget.map-filter {
    display: block;
  }
  .widget.advertise-widget {
    text-align: center;
  }
  .widget.featured-products.widget {
    padding: 0 15px;
  }
  .widget.map-filter ul li a {
    padding: 10px 30px 9px 49px;
  }
  .widget.blog-widget .blog-item-wrap .blog-item .blog-icon {
    width: 105px;
  }
  .widget.blog-widget h3 {
    margin: 0 0 20px;
  }
  .striped-bg-faint-line .strip {
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }
  .widget.enquiry-basket-guide.side-nav-widget.letterbox {
    display: block;
  }
  .widget.enquiry-basket-guide.side-nav-widget.compact {
    display: none;
  }
}
@media (max-width: 480px) {
  .widget.standard-page-banner .page-banner-inner .item .text-overlay {
    position: static;
  }
  .widget.blog-widget .blog-item-wrap .blog-item .blog-desc .comments {
    font-size: 0.85714286em;
  }
  .widget.blog-widget .blog-item-wrap .blog-item .blog-desc .comments ul li:before {
    display: block;
    content: '|';
    width: 9px;
  }
  .widget.blog-widget .blog-item-wrap .blog-item .blog-desc .comments ul li a {
    padding-right: 6px;
  }
  .widget.blog-widget .blog-item-wrap .blog-item .blog-icon {
    position: relative;
    display: table-cell;
    vertical-align: top;
    width: 91px;
  }
  .widget.blog-widget .blog-item-wrap .blog-item .blog-desc {
    padding-left: 15px;
  }
}
@media (max-width: 575px) {
  .widget.enquiry-basket-guide.side-nav-widget.letterbox {
    display: none;
  }
  .widget.enquiry-basket-guide.side-nav-widget.compact {
    display: block;
  }
}
/* Widget Keyframes */
@-moz-keyframes wigglearrow {
  0% {
    left: 20px;
  }
  25% {
    left: 17px;
  }
  50% {
    left: 20px;
  }
  75% {
    left: 17px;
  }
  100% {
    left: 20px;
  }
}
@-ms-keyframes wigglearrow {
  0% {
    left: 20px;
  }
  25% {
    left: 17px;
  }
  50% {
    left: 20px;
  }
  75% {
    left: 17px;
  }
  100% {
    left: 20px;
  }
}
@-webkit-keyframes wigglearrow {
  0% {
    left: 20px;
  }
  25% {
    left: 17px;
  }
  50% {
    left: 20px;
  }
  75% {
    left: 17px;
  }
  100% {
    left: 20px;
  }
}
@keyframes wigglearrow {
  0% {
    left: 20px;
  }
  25% {
    left: 17px;
  }
  50% {
    left: 20px;
  }
  75% {
    left: 17px;
  }
  100% {
    left: 20px;
  }
}
.FRFR .tech-area a {
  font-size: 11px;
  line-height: 44px;
}
.download-panel {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.5);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.download-panel__close-button {
  position: absolute;
  top: 10px;
  right: 15px;
}
.download-panel__close-icon {
  font-size: 20px;
  cursor: pointer;
}
.download-panel .form-box {
  position: relative;
  min-width: 320px;
  max-width: 800px;
  max-height: 80vh;
}
@media (max-width: 991px) {
  .download-panel .form-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    margin: 0 auto 20px;
  }
}
.download-panel .form-box input[type="submit"] {
  float: none;
  display: block;
  width: 100%;
}
.ism-button {
  outline: none;
  border: none;
  padding: 8px 28px 8px 28px;
  background-color: #6f9d20;
  display: inline-block;
  color: #ffffff;
  font-size: 22px;
  font-family: 'ff-dax-pro', Arial, sans-serif;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  user-select: none;
}
.ism-button:hover,
.ism-button:active,
.ism-button:focus {
  text-decoration: none;
}
.ism-button:disabled {
  background-color: #575757;
}
.ism-button--white {
  background-color: #ffffff;
  border: 1px solid #6f9d20;
  color: #6f9d20;
}
.ism-button--white svg {
  fill: #6f9d20;
}
.ism-button--inline {
  align-self: center;
}
.ism-button--next {
  padding: 8px 50px 8px 28px;
}
.ism-button--next svg {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  height: 20px;
}
.ism-button--small {
  font-size: 20px;
}
.ism-button--previous {
  padding: 8px 28px 8px 50px;
}
.ism-button--previous svg {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  width: 25px;
  height: 20px;
}
.ism-button--restart {
  padding: 8px 50px 8px 28px;
}
.ism-button--restart svg {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 19px;
  height: 19px;
  fill: #fff;
  stroke: #fff;
}
.ism-button--phone {
  padding: 8px 28px 8px 50px;
}
.ism-button--phone svg {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 21px;
  height: 17px;
}
.ism-button--mail {
  padding: 8px 28px 8px 50px;
}
.ism-button--mail svg {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 21px;
  height: 17px;
}
.ism-modal-prompt {
  background-color: #859a3e;
  padding: 10px 14px;
  color: #ffffff;
  font-size: 20px;
  line-height: 1;
  font-family: 'ff-dax-pro', Arial, sans-serif;
  cursor: pointer;
  display: flex;
  flex-direction: revert;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.ism-modal-prompt a {
  color: #ffffff;
}
.ism-modal-prompt span {
  color: #333333;
}
.ism-modal-prompt.small {
  width: 110px;
  margin: 0 auto;
  background-color: #e4e9d4;
  color: #859a3e;
  font-size: 18px;
  font-family: 'ff-dax-pro', Arial, sans-serif;
  padding: 6px 10px;
  text-align: center;
}
.ism-modal-prompt.small:hover {
  background-color: #859a3e;
  color: #e4e9d4;
}
.dimensions {
  background-image: url('app/modules/ism/~@imgOld/ism/icon-grass.svg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 300px;
  height: 230px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .dimensions {
    height: 250px;
    padding-bottom: 0px;
  }
}
.dimensions__outer {
  background-color: #fdc135;
  border: 1px solid black;
  width: 100%;
  height: 100%;
  padding: 5px;
  position: relative;
  margin-right: 20px;
  transition: width 300ms ease, height 300ms ease;
}
.dimensions__inner {
  background-color: #c7c7c7;
  border: 1px solid black;
  width: 100%;
  height: 100%;
}
.dimensions__arrow {
  position: absolute;
  background-color: #333333;
}
.dimensions__arrow:before {
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #333333;
  position: absolute;
}
.dimensions__arrow:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #333333;
  position: absolute;
}
.dimensions__arrow--width {
  top: 50%;
  right: -15px;
  transform: translateY(-50%);
  width: 1px;
  transition: height 375ms ease;
}
.dimensions__arrow--width:before {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
}
.dimensions__arrow--width:after {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.dimensions__arrow--width span {
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}
.dimensions__arrow--length {
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  transition: width 375ms ease;
}
.dimensions__arrow--length:before {
  right: -2px;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
}
.dimensions__arrow--length:after {
  left: -2px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
}
.dimensions__arrow--length span {
  left: 50%;
  transform: translateX(-50%);
  top: 10px;
}
.dimensions__arrow span {
  color: #333333;
  font-family: 'ff-dax-pro';
  position: absolute;
}
.ism-modal {
  font-size: 16px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 8002;
}
.ism-modal.active {
  display: block;
}
.ism-modal__inner {
  height: calc(100% - 15px);
  padding: 18px;
}
@media (min-width: 996px) {
  .ism-modal__inner {
    padding: 20px;
  }
}
.ism-modal__header {
  position: relative;
  padding: 0 30px 0 0;
  display: flex;
  flex-direction: column;
  height: 120px;
}
@media (min-width: 996px) {
  .ism-modal__header {
    padding: 0 30px;
  }
}
.ism-modal__header img {
  width: 100%;
  max-width: 307px;
  margin: 0 auto 0 0;
  display: block;
  flex: 0 0 auto;
}
@media (min-width: 996px) {
  .ism-modal__header img {
    margin: 0 auto;
  }
}
.ism-modal__close {
  position: absolute;
  top: 12px;
  right: 0;
  transform: translateY(-50%);
  background: transparent;
  padding: 0px;
  width: 20px;
  height: 20px;
}
.ism-modal__close:active,
.ism-modal__close:focus,
.ism-modal__close:hover {
  background: transparent;
  border: none;
  outline: none;
}
.ism-modal__close:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('app/modules/ism/~@imgOld/ism/icon-cross.svg');
}
.ism-modal__intro {
  text-align: center;
}
.ism-modal__intro img {
  margin-bottom: 50px;
}
.ism-modal__intro p {
  max-width: 550px;
  margin: 0 auto;
}
.ism-modal__steps {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 15px;
}
.ism-modal__step {
  flex: 1 1 auto;
  background-color: #ccddab;
  width: 100%;
  height: 100%;
  margin-right: 4px;
}
.ism-modal__step.active {
  background-color: #6f9d20;
  cursor: pointer;
}
.ism-modal__step:last-child {
  margin-right: 0px;
}
.ism-modal__content {
  height: calc(100% - 60px - 120px);
  padding: 20px 0px;
  overflow-y: auto;
  /* width */
  /* Track */
  /* Handle */
}
@media (min-width: 996px) {
  .ism-modal__content {
    height: calc(100% - 120px);
    padding: 40px 0px;
  }
}
.ism-modal__content.boxes-open {
  overflow-y: hidden;
}
.ism-modal__content::-webkit-scrollbar {
  width: 8px;
}
.ism-modal__content::-webkit-scrollbar-track {
  background: #ffffff;
}
.ism-modal__content::-webkit-scrollbar-thumb {
  background: #6f9d20;
  border-radius: 10px;
}
.ism-modal__footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: #6f9d20;
}
@media (min-width: 996px) {
  .ism-modal__footer {
    background-color: transparent;
    height: 60px;
    z-index: 0;
  }
}
.ism-modal__footer--next {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.ism-modal__footer--previous {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
.ism-step-picker {
  align-self: center;
  cursor: pointer;
  user-select: none;
  position: relative;
  z-index: 2;
}
.ism-step-picker__selected {
  font-size: 15px;
  padding: 10px 28px 10px 0px;
  font-family: 'ff-dax-pro', Arial, sans-serif;
}
@media (min-width: 996px) {
  .ism-step-picker__selected {
    font-size: 18px;
  }
}
.ism-step-picker__selected svg {
  width: 22px;
  height: 10px;
  stroke: #6f9d20;
  margin-left: 8px;
}
.ism-step-picker__items {
  display: none;
  max-width: 415px;
  width: calc(100vw - 36px);
  max-height: 50vh;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  border: 1px solid #c7c7c7;
}
.ism-step-picker.active .ism-step-picker__items {
  display: block;
}
.ism-step-picker__item {
  padding: 12px 10px;
  opacity: 0.3;
}
.ism-step-picker__item.completed,
.ism-step-picker__item.active {
  opacity: 1;
}
.ism-step-picker__item.active {
  text-decoration: underline;
}
.ism-step-picker__item:hover {
  opacity: 1;
}
.ism-question {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;
  margin-right: 15px;
}
.ism-question--y-centre {
  justify-content: center;
}
.ism-question h2,
.ism-question h3 {
  font-size: 25px;
  text-align: center;
  margin-bottom: 28px;
  color: #333333;
  font-family: 'ff-dax-pro', Arial, sans-serif;
}
@media (min-width: 996px) {
  .ism-question h2,
  .ism-question h3 {
    font-size: 30px;
    margin-bottom: 45px;
  }
}
.ism-question strong {
  font-family: 'ff-dax-pro', Arial, sans-serif;
}
.ism-question p {
  font-size: 17px;
  margin-bottom: 24px;
  color: #333333;
}
.ism-question__avatar {
  fill: #6f9d20;
  width: 70px;
  height: 70px;
  align-self: center;
  margin-bottom: 35px;
}
.ism-question__avatar + .ism-button {
  margin-bottom: 8px;
}
.ism-question__icon {
  width: 300px;
  margin-bottom: 25px;
}
.ism-question__icon svg {
  width: 100%;
}
.ism-question__depth {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto 45px auto;
}
.ism-question__depth-icon {
  width: 100%;
}
.ism-question__depth-icon--gw {
  height: 103px;
}
.ism-question__depth-label {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #6f9d20;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
  padding: 7px 0px;
  top: -3px;
  user-select: none;
}
@media (min-width: 996px) {
  .ism-question__depth-label {
    top: 3px;
  }
}
.ism-question__depth-label svg {
  display: block;
  fill: #6f9d20;
  width: 7px;
  height: 16px;
  margin-right: 4px;
}
.ism-question__depth-indicator {
  width: 1px;
  background-color: #6f9d20;
  margin-right: 4px;
  position: relative;
  height: 100%;
}
.ism-question__depth-indicator:before,
.ism-question__depth-indicator:after {
  content: '';
  width: 7px;
  height: 7px;
  background-image: url('/sitefiles/images/ism/icon-point.svg');
  position: absolute;
}
.ism-question__depth-indicator:before {
  content: '';
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
}
.ism-question__depth-indicator:after {
  content: '';
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.ism-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 750px;
  margin: 0 auto;
  width: 100%;
}
@media (min-width: 996px) {
  .ism-form {
    position: relative;
    z-index: 1;
  }
}
.ism-form__row {
  max-width: 304px;
  width: 100%;
  margin: 5px 0px;
}
.ism-form__row .ism-button {
  margin: 0px 6px;
}
.ism-form__row--wide {
  max-width: 420px;
}
.ism-form__row--x-wide {
  max-width: 500px;
}
.ism-form__row--marg-20 {
  margin: 20px 0px;
}
.ism-form__row--marg-top-30 {
  margin-top: 30px;
}
.ism-form__row--split {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.ism-form__row--x-centre {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.ism-form__row ul {
  margin-bottom: 26px;
}
.ism-form__error {
  display: block;
  font-weight: bold;
  font-family: 'ff-dax-pro', Arial, sans-serif;
  color: red !important;
  font-size: 14px;
}
.ism-form__control {
  padding: 5px 0px;
  margin: 0 auto;
}
.ism-form__control--centre {
  align-items: center;
}
.ism-form__control label {
  display: block;
  font-weight: bold;
  font-family: 'ff-dax-pro', Arial, sans-serif;
  color: #333333;
}
.ism-form__control input {
  display: inline-block;
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #000;
}
.ism-form__control input:focus,
.ism-form__control input:active {
  outline: none;
}
.ism-form__control iframe {
  border-radius: 4px;
}
.ism-form__control .ism-form__error {
  display: none;
}
.ism-form__control.invalid input {
  border: 1px solid red;
}
.ism-form__control.invalid .ism-form__error {
  display: block;
}
.ism-form__control--sub {
  display: inline-block;
  vertical-align: bottom;
  margin-left: 3px;
}
.ism-form__control input[type='number'] {
  max-width: 100px;
  text-align: center;
}
.ism-form__radios {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
.ism-form__radio {
  border: 1px solid #c7c7c7;
  padding: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin-bottom: 18px;
  align-self: center;
  margin-right: 12px;
}
.ism-form__radio:last-child {
  margin-right: 0px;
}
@media (min-width: 360px) {
  .ism-form__radio {
    align-self: auto;
    padding: 20px;
  }
}
.ism-form__radio-value {
  width: 24px;
  height: 24px;
  border: 1px solid #333333;
  border-radius: 50%;
  margin-right: 7px;
  position: relative;
}
.ism-form__radio.invalid {
  border: 1px solid red;
}
.ism-form__radio.active {
  border: 1px solid #6f9d20;
  border-bottom: 6px solid #6f9d20;
  margin-bottom: 12px;
}
.ism-form__radio.active .ism-form__radio-value:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 15px;
  background-color: #6f9d20;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.ism-form__radio-label {
  font-size: 14px;
  font-weight: normal;
}
@media (min-width: 360px) {
  .ism-form__radio-label {
    font-size: 17px;
  }
}
.ism-boxes:not(.ism-single-question) {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  width: 100%;
  justify-content: center;
}
@media (min-width: 996px) {
  .ism-boxes:not(.ism-single-question) {
    flex-direction: row;
    flex-wrap: wrap;
    width: auto;
  }
}
.ism-boxes .ism-box {
  flex: 0 0 auto;
  padding: 8px 8px 8px 40px;
  position: relative;
}
@media (min-width: 996px) {
  .ism-boxes .ism-box {
    padding: 8px 8px 60px 8px;
    width: 33%;
  }
}
.ism-boxes .ism-box__inner {
  border: 1px solid #d5d5d5;
  padding: 20px 12px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  height: 100%;
}
@media (min-width: 996px) {
  .ism-boxes .ism-box__inner {
    flex-direction: column;
    padding: 12px;
  }
}
.ism-boxes .ism-box.invalid .ism-box__inner {
  border: 1px solid red;
}
.ism-boxes .ism-box.active .ism-box__inner {
  border: 1px solid #6f9d20;
  border-bottom: 7px solid #6f9d20;
}
.ism-boxes .ism-box__media {
  position: relative;
  flex: 0 0 auto;
  max-width: 40%;
}
@media (min-width: 996px) {
  .ism-boxes .ism-box__media {
    margin-bottom: 16px;
    max-width: 100%;
  }
}
.ism-boxes .ism-box__media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover';
}
.ism-boxes .ism-box__label {
  text-align: center;
  font-weight: normal;
  font-size: 17px;
  display: block;
  flex: 1 1 auto;
}
@media (min-width: 996px) {
  .ism-boxes .ism-box__label {
    flex: 1 0 auto;
    margin-bottom: 50px;
  }
}
.ism-boxes .ism-box__state {
  width: 24px;
  height: 24px;
  border: 1px solid #333333;
  border-radius: 50%;
  position: relative;
  flex: 0 0 auto;
}
@media (min-width: 996px) {
  .ism-boxes .ism-box__state {
    margin: 0 auto 20px auto;
    width: 30px;
    height: 30px;
  }
}
.ism-boxes .ism-box.active .ism-box__state:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: #6f9d20;
}
@media (min-width: 996px) {
  .ism-boxes .ism-box.active .ism-box__state:before {
    width: 20px;
    height: 20px;
  }
}
.ism-boxes .ism-box__info {
  position: absolute;
  left: 15px;
  bottom: 50%;
  transform: translate(-50%, 50%);
}
@media (min-width: 996px) {
  .ism-boxes .ism-box__info {
    left: 50%;
    bottom: 30px;
  }
}
.ism-boxes.ism-single-question .ism-box__info {
  position: relative;
  top: -20px;
  left: 8px;
}
.ism-info-icon {
  position: relative;
  width: 28px;
  height: 28px;
  border: 1px solid #333333;
  border-radius: 50%;
  cursor: pointer;
}
.ism-info-icon > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #333333;
  width: 16px;
  height: 16px;
}
.ism-box-modal {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.84);
  top: 18px;
  left: 0;
  width: 100%;
  height: calc(100% - 50px - 18px);
  padding: 16px;
  z-index: 10;
  flex-direction: column;
  align-items: center;
  display: none;
}
@media (min-width: 996px) {
  .ism-box-modal {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: unset;
  }
}
.ism-box-modal.active {
  display: flex;
}
.ism-box-modal__close {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}
.ism-box-modal__close svg {
  width: 18px;
  height: 18px;
}
.ism-box-modal__media {
  max-width: 260px;
  flex: 0 0 auto;
}
@media (min-width: 996px) {
  .ism-box-modal__media {
    margin: 0 auto;
  }
  .full-width .ism-box-modal__media {
    width: 100%;
    max-width: 700px;
  }
}
.ism-box-modal__inner {
  max-width: 760px;
  width: 100%;
  background-color: #ffffff;
  padding: 20px;
  border-bottom: 7px solid #6f9d20;
  margin: auto;
  display: flex;
  box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.25);
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  max-height: 100%;
  overflow-y: auto;
}
.ism-box-modal--small .ism-box-modal__inner {
  max-width: 460px;
}
@media (min-width: 996px) {
  .ism-box-modal__inner {
    flex-direction: row;
  }
}
.ism-box-modal__inner.full-width {
  flex-direction: column;
}
.ism-box-modal__copy {
  padding: 25px 0px;
}
.ism-box-modal__copy h2,
.ism-box-modal__copy h3 {
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 30px;
  margin-top: 0px;
}
.ism-box-modal__copy p {
  font-size: 16px;
}
.ism-form__materials {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  border-top: 1px solid #707070;
  margin-top: 20px;
  margin-bottom: 30px;
}
@media (max-width: 996px) {
  .ism-form__materials--desktop {
    display: none;
  }
}
.ism-form__materials--mobile {
  display: flex;
}
@media (min-width: 996px) {
  .ism-form__materials--mobile {
    display: none;
  }
}
@media (min-width: 996px) {
  .ism-form__materials {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.ism-material {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 12px;
}
@media (min-width: 996px) {
  .ism-material {
    width: 45%;
  }
}
.ism-material__container .ism-material {
  width: 100%;
}
.ism-material__title {
  font-size: 15px;
  color: #000000;
  font-weight: 700;
  padding-bottom: 10px;
}
.ism-material__media {
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 17px;
  flex: 0 0 32px;
}
.ism-material__media--large {
  width: 80px;
  height: auto;
  flex: 0 0 auto;
}
.ism-material__media--loose-ground {
  background-image: url(/sitefiles/images/ism/materials/loose-ground.png);
}
.ism-material__media--soft-clay {
  background-image: url(/sitefiles/images/ism/materials/soft-clay.png);
}
.ism-material__media--loose-sand {
  background-image: url(/sitefiles/images/ism/materials/loose-sand.png);
}
.ism-material__media--peat {
  background-image: url(/sitefiles/images/ism/materials/peat.png);
}
.ism-material__media--silt {
  background-image: url(/sitefiles/images/ism/materials/silt.png);
}
.ism-material__media--road {
  background-image: url(/sitefiles/images/ism/materials/road.png);
}
.ism-material__media--cohesive-fill {
  background-image: url(/sitefiles/images/ism/materials/cohesive-fill.png);
}
.ism-material__media--dense-sand {
  background-image: url(/sitefiles/images/ism/materials/dense-sand.png);
}
.ism-material__media--stiff-clay {
  background-image: url(/sitefiles/images/ism/materials/stiff-clay.png);
}
.ism-material__media--rock {
  background-image: url(/sitefiles/images/ism/materials/rock.png);
}
.ism-material__media--loose-gravel {
  background-image: url(/sitefiles/images/ism/materials/loose-gravel.png);
}
.ism-material__label {
  font-size: 15px;
}
.ism-solutions {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 40px;
  max-width: 850px;
  width: 100%;
}
@media (min-width: 996px) {
  .ism-solutions {
    position: relative;
    z-index: 1;
  }
}
@media (min-width: 996px) {
  .ism-solutions + .ism-form__row {
    position: relative;
    z-index: 1;
  }
}
.ism-solutions-item {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 40px;
}
@media (min-width: 996px) {
  .ism-solutions-item {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
.ism-solutions-item h2 {
  font-size: 21px;
  text-align: left;
  margin-bottom: 18px;
}
.ism-solutions-item ul {
  margin-bottom: 32px;
  padding: 0px;
  list-style-type: none;
  border: 1px solid #6f9d20;
  list-style: disc;
}
@media (min-width: 996px) {
  .ism-solutions-item ul {
    list-style-type: disc;
    padding-left: 18px;
    border: none;
  }
}
.ism-solutions-item ul li {
  color: #6f9d20;
  padding: 5px 10px;
  border-bottom: 1px solid #6f9d20;
  list-style-type: disc;
}
@media (min-width: 996px) {
  .ism-solutions-item ul li {
    padding: 0px;
    border-bottom: none;
  }
}
.ism-solutions-item ul li:before {
  display: none;
}
.ism-solutions-item ul li:nth-child(even) {
  background-color: #e2f0d9;
}
@media (min-width: 996px) {
  .ism-solutions-item ul li:nth-child(even) {
    background: none;
  }
}
.ism-solutions-item ul li:last-child {
  border-bottom: none;
}
.ism-solutions-item ul li strong {
  margin-right: auto;
}
@media (min-width: 996px) {
  .ism-solutions-item ul li strong {
    margin-right: 0px;
  }
}
@media (min-width: 996px) {
  .ism-solutions-item ul li strong:after {
    content: ':';
    display: inline-block;
    margin-right: 4px;
  }
}
.ism-solutions-item ul li span {
  color: #333333;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: 'ff-dax-pro', Arial, sans-serif;
}
@media (min-width: 996px) {
  .ism-solutions-item ul li span {
    display: inline-block;
  }
}
.ism-solutions-item__media {
  flex: 0 0 35%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ism-solutions-item__media picture {
  height: 100%;
}
.ism-solutions-item__media img {
  flex: 0 0 auto;
  display: block;
  margin-left: auto;
}
@supports (display: grid) {
  .ism-solutions-item__media img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    font-family: 'object-fit: contain';
  }
}
.ism-solutions-item__media-inner {
  width: 100%;
  padding: 16px;
  flex: 1 0 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 996px) {
  .ism-solutions-item__media-inner {
    padding: 0;
  }
}
@supports (display: grid) {
  .ism-solutions-item__media-inner {
    height: 100%;
    display: block;
  }
}
.ism-solutions-item__copy {
  flex: 0 0 65%;
}
.ism-solutions-item__copy-inner {
  padding: 15px;
}
@media (min-width: 996px) {
  .ism-solutions-item__copy-inner {
    padding: 70px;
  }
}
.ism-sidebar__header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: #859a3e;
  padding: 12px 20px;
}
.ism-sidebar__header:hover {
  text-decoration: none;
}
.ism-sidebar__header--toggle {
  cursor: pointer;
}
.ism-sidebar__header-media {
  flex: 0 0 50%;
  padding-right: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ism-sidebar__header-media img {
  flex-grow: 0;
  flex-shrink: 0;
}
.ism-sidebar__header-title {
  flex: 0 0 50%;
  font-size: 20px;
  color: #ffffff;
  padding-left: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}
.ism-sidebar__header-title span {
  width: 100%;
}
.ism-sidebar__items {
  border: 1px solid #859a3e;
}
.ism-sidebar-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 10px 60px 10px 40px;
  position: relative;
  cursor: pointer;
}
@media (max-width: 996px) {
  .ism-sidebar-item {
    padding: 10px 10px 10px 40px;
  }
}
.ism-sidebar-item:hover {
  background-color: #ededed;
  text-decoration: none;
}
.ism-sidebar-item:hover .ism-sidebar-item__download {
  display: block;
}
@media (max-width: 996px) {
  .ism-sidebar-item:hover .ism-sidebar-item__download {
    display: none;
  }
}
.ism-sidebar-item:hover:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-image: url('app/modules/ism/~@imgOld/ism/icon-arrows.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
.ism-sidebar-item__media {
  margin-right: 8px;
  flex: 0 0 auto;
}
.ism-sidebar-item__media img {
  width: 23px;
  height: 23px;
}
.ism-sidebar-item__name {
  font-size: 17px;
  color: #333333;
}
.ism-sidebar-item__download {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translate(50%, -50%);
  width: 23px;
  height: 23px;
  fill: #859a3e;
  margin-left: 24px;
  display: none;
}
