.ism-sidebar {
  &__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: @brand-green;
    padding: 12px 20px;

    &:hover {
      text-decoration: none;
    }

    &--toggle {
      cursor: pointer;
    }

    &-media {
      flex: 0 0 50%;
      padding-right: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        flex-grow: 0;
        flex-shrink: 0;
      }
    }

    &-title {
      flex: 0 0 50%;
      font-size: 20px;
      color: @brand-white;
      padding-left: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;

      span {
        width: 100%;
      }
    }
  }

  &__items {
    border: 1px solid @brand-green;
  }

  &-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 10px 60px 10px 40px;
    position: relative;
    cursor: pointer;

    @media (max-width: @screen-tablet) {
      padding: 10px 10px 10px 40px;
    }

    &:hover {
      background-color: #ededed;
      text-decoration: none;

      .ism-sidebar-item__download {
        display: block;

        @media (max-width: @screen-tablet) {
          display: none;
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 10px;
        background-image: url('~@imgOld/ism/icon-arrows.svg');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &__media {
      margin-right: 8px;
      flex: 0 0 auto;

      img {
        width: 23px;
        height: 23px;
      }
    }

    &__name {
      font-size: 17px;
      color: #333333;
    }

    &__download {
      position: absolute;
      right: 40px;
      top: 50%;
      transform: translate(50%, -50%);
      width: 23px;
      height: 23px;
      fill: @brand-green;
      margin-left: 24px;
      display: none;
    }
  }
}
