.ism-button {
  outline: none;
  border: none;
  padding: 8px 28px 8px 28px;
  background-color: @brand-green--light;
  display: inline-block;
  color: @brand-white;
  font-size: 22px;
  font-family: @bold-font-family;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  user-select: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  &:disabled {
    background-color: @brand-grey;
  }

  &--white {
    background-color: @brand-white;
    border: 1px solid @brand-green--light;
    color: @brand-green--light;

    svg {
      fill: @brand-green--light;
    }
  }

  &--inline {
    align-self: center;
  }

  &--next {
    padding: 8px 50px 8px 28px;

    svg {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      width: 25px;
      height: 20px;
    }
  }

  &--small {
    font-size: 20px;
  }

  &--previous {
    padding: 8px 28px 8px 50px;

    svg {
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      width: 25px;
      height: 20px;
    }
  }

  &--restart {
    padding: 8px 50px 8px 28px;

    svg {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      width: 19px;
      height: 19px;
      fill: #fff;
      stroke: #fff;
    }
  }

  &--phone {
    padding: 8px 28px 8px 50px;

    svg {
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      width: 21px;
      height: 17px;
    }
  }

  &--mail {
    padding: 8px 28px 8px 50px;

    svg {
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      width: 21px;
      height: 17px;
    }
  }
}

.ism-modal-prompt {
  background-color: @brand-green;
  padding: 10px 14px;
  color: @brand-white;
  font-size: 20px;
  line-height: 1;
  font-family: @bold-font-family;
  cursor: pointer;
  display: flex;
  flex-direction: revert;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  a {
    color: @brand-white;
  }

  span {
    color: #333333;
  }

  &.small {
    width: 110px;
    margin: 0 auto;
    background-color: #e4e9d4;
    color: #859a3e;
    font-size: 18px;
    font-family: @base-font-family;
    padding: 6px 10px;
    text-align: center;

    &:hover {
      background-color: #859a3e;
      color: #e4e9d4;
    }
  }
}
