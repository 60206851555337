@import '../partials/_mixins';
@import '../partials/_variables';

// Body
body {
  color: @base-font-color;
  font-family: @base-font-family;
  font-size: ~'@{base-font-size}px';
  line-height: ~'@{base-line-height}px';
}

// Elements
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: @heading-font-family;
  .font-size(25);
  margin: 0 0 20px 0;
}

h1 {
  .font-size(25);
  color: @brand-font-green;
  font-weight: bold;

  .fa {
    margin-right: 10px;
    font-size: 140%;
  }

  small {
    font-size: 60%;
    color: @brand-font-green;
  }
}

h2 {
  .font-size(25);
  color: @base-font-color;
  font-weight: bold;
}

h3,
.BlogCommentsTitle {
  color: @brand-font-green;
  .font-size(25);
  font-weight: bold;
}

h4 {
  .font-size(20);
}

h5 {
  .font-size(18);
}

h6 {
  .font-size(16);
}

a {
  outline: none !important;
  color: @base-link-color;

  &:focus,
  &:hover {
    outline: none !important;
    color: @base-link-active-color;
  }
}

blockquote {
  border-left-color: @base-font-color;
}

select {
  width: 100%;
  height: 40px;
  border-radius: 0;
  border: none;
}

.load-more-btn {
  background: #e4e9d4;
  color: @brand-green;
  padding: 5px 15px;
  margin-bottom: 40px;

  &:hover {
    background: @brand-green;
    color: #e4e9d4;
  }
}

.share-icons {
  float: right;
  display: inline-block;
  padding-top: 10px;
}

.load-overlay {
  display: none;
}

.HomePage .load-overlay {
  display: block;
  z-index: 9999;
  background: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .load-icon {
    display: block;
    width: 80px;
    height: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -40px;
  }
}

.fade-in {
  opacity: 1 !important;
  top: 0 !important;
}

.alLeft {
  text-align: left;
}

.alCenter {
  text-align: center;
}

.alRight {
  text-align: right;
}

.inner-content {
  margin-bottom: 45px;

  .grey-bar {
    margin-top: 45px;
  }
}

.InnerPage .main-content {
  padding-top: 15px;
}

// breadcrumbs
.breadcrumbs {
  margin: 20px 0 10px;

  a,
  span {
    color: @brand-grey;
    text-decoration: none;
    margin-right: 30px;
    margin-bottom: 10px;
    position: relative;
    display: inline-block;

    &:after {
      content: '\f054';
      display: block;
      position: absolute;
      font-family: 'FontAwesome';
      top: 0;
      right: -20px;
      color: @brand-green;
      font-size: 0.8em;
    }

    &.CMSBreadCrumbsCurrentItem {
      &:after {
        content: '\f078';
        right: -18px;
        top: -2px;
      }
    }

    &:hover {
      color: @brand-green;
    }
  }

  .hdn {
    display: none;
  }
}

.breadcrumbs + img,
.top-image {
  margin-bottom: 15px;
}

.striped-bg-faint-line {
  padding: 30px 0 25px;
  background: url(~@imgOld/faint-line-repeat.png) repeat 0px 0px;

  label {
    .font-size(16.5);
    margin: 16px 6px 0 0;
    display: block;
    float: left;
  }

  .info-strips {
    display: block;
    float: left;
    margin: 0;
  }

  .strip {
    display: inline-block;
    padding: 14px 25px;
    margin: 0 0 0 16px;
  }
}

img {
  max-width: 100%;
}

.marg-btm {
  margin-bottom: 15px;
}

.swipe-table {
  display: none;
  margin-bottom: 8px;
  font-weight: bold;
}

#page-wrapper {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  background: #f2f2f2;
}

.inner-wrapper {
  background: #fff;
  min-height: 100vh;
  position: relative;
}

input[type='submit'],
.btn {
  position: relative;
  outline: none;
  width: auto;
  float: none;
  background: @brand-font-green url(~@imgOld/submit-arrow.png) no-repeat left center;
  color: #fff;
  font-size: 1.42857143em;
  padding: 12px 44px 12px 18px;
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  margin-bottom: 15px;

  &.btn-orange {
    background: @brand-orange url(~@imgOld/submit-arrow.png) no-repeat right center;

    &:hover,
    &:focus {
      background: lighten(@brand-orange, 10%) url(~@imgOld/submit-arrow.png) no-repeat right center;
    }
  }

  &.btn-grey {
    background: @brand-grey url(~@imgOld/submit-arrow.png) no-repeat right center;

    &:hover,
    &:focus {
      background: lighten(@brand-grey, 10%) url(~@imgOld/submit-arrow.png) no-repeat right center;
    }
  }

  &.back-arrow {
    background-image: url(~@imgOld/back-arrow.png);

    &.back-align-left {
      background-position-x: 3px;
    }
  }

  &.with-icon {
    padding: 12px 18px 12px;
    background-image: none !important;

    .fa {
      padding-right: 15px;
    }
  }

  &.no-arrow {
    background-image: none !important;
  }
}

.enquiry-btn {
  float: right;
  min-width: 163px;
  margin: 0px;
  position: relative;
  right: 0;

  .fa {
    position: absolute;
    z-index: 15;
    left: 0px;
    top: 23px;
    color: #fff;
    .font-size(18);

    .pad-right {
      left: -60px;
      top: 20px;
    }
  }

  input[type='submit'] {
    content: '\f07a';
    background: @brand-orange url(~@imgOld/submit-arrow.png) no-repeat right center;

    &:hover {
      background: lighten(@brand-orange, 10%) url(~@imgOld/submit-arrow.png) no-repeat right center;
    }

    padding: 12px 18px 12px;
    background-image: none !important;
    padding-left: 50px;
  }
}

.placeholder {
  color: #c6c6c6;
}

.main-content {
  ul {
    padding: 0;

    li {
      position: relative;
      color: @base-font-color;
      list-style-type: none;
      padding-left: 16px;

      &:before {
        display: block;
        position: absolute;
        top: calc(0.5em - 3px);
        left: 0px;
        width: 10px;
        height: 10px;
        content: ' ';
        border-radius: 50%;
        background-color: @brand-green;
      }

      a {
        color: @base-font-color;

        &:hover {
          color: @brand-font-green;
        }
      }
    }
  }

  blockquote {
    border-left-color: @brand-font-green;
    .font-size(17);

    footer {
      background: none;
    }
  }

  .table-responsive {
  }

  .table {
    th {
      color: @brand-font-green;
      .font-size(18);
      font-weight: normal;
      border-right: 1px solid @brand-green;
      border-bottom: 2px solid #859a3e;
      padding-bottom: 3px;
      padding-left: 15px;

      &:last-child {
        /*border-right: none;*/
      }
    }

    td {
      .font-size(16);
    }

    tr {
      td {
        border-right: 1px solid @brand-green;
        border-bottom: 1px solid @brand-green;
        border-top: none;
        padding: 15px 10px 3px;

        &:last-child {
          /*border-right: none;*/
        }
      }

      .td-no-border {
        border: none;
      }
    }
  }
}

.blog-replys .CommentDetail {
  background-color: #fcfcfc; /*#f90 !important;*/
  font-family: 'ff-dax-pro', Arial, sans-serif;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin: 5px;
  padding: 5px;

  &:nth-child(2n + 1) {
    background-color: #fdfdfd !important;
  }
}

.CommentDetail .table-responsive .table {
  border-collapse: collapse;
}
/*User name and admin controls row in a comment*/
.CommentDetail {
  .table tr:nth-child(2n + 1) {
    background-color: #859a3e !important;
    color: #fff;
  }

  .table tr:nth-child(3n + 1) {
    background-color: #ebebeb !important;
    color: #859a3e;
  }
}

.HomePage .content-wrap {
  opacity: 1;
}

.animate-with-dropdown {
  position: relative;
}

.site-offline {
  header {
    position: relative !important;
    z-index: 3000;
    width: 100% !important;
    top: 0 !important;
    left: 0 !important;
    opacity: 1 !important;
    display: block !important;

    .logo {
      left: 0;
    }
  }

  .error-wrap {
    top: 82px;
  }

  .grey-bar {
    width: 100% !important;
  }
}

.main-content .grey-bar {
  width: 100%;
}

@media (max-width: 1199px) {
  .striped-bg-faint-line label {
    float: none;
    margin: 0 0 10px 0;
  }

  .striped-bg-faint-line {
    float: none;
    display: table;
    width: 100%;

    .strip {
      display: table-cell;
      margin: 0;
      background: none;
      padding: 0 2px;

      .strip-content {
        padding: 14px 10px;
        background: #e3e3e3;
      }
    }
  }
}

@media (max-width: 991px) {
  .content-wrap {
    opacity: 1 !important;
  }

  .HomePage .content-wrap {
    margin-top: 0 !important;
  }

  .container.no-tab-pad {
    width: 100%;
    padding: 0;
  }
}

@media (max-width: 767px) {
  .share-icons {
    float: none;
    display: block;
    padding-top: 10px;
  }

  .swipe-table {
    display: block;
  }

  .site-offline {
    header {
      .logo {
        left: 10px;
        width: 300px;
      }
    }
  }

  .inner-content {
    margin-bottom: 25px;
  }

  .mobGap {
    margin-bottom: 25px;
  }

  .striped-bg-faint-line .info-strips {
    display: block;

    .strip {
      display: block;
      float: left;
      margin: 0 2px 2px 0;
      padding: 0;
      width: 48%;

      .strip-content {
        padding: 14px 10px;
      }
    }
  }
}

@media (max-width: 480px) {
  select {
    width: 100% !important;
    height: 40px;
  }

  .site-offline .error-wrap {
    margin-bottom: 300px;
  }

  #advertise-carousel {
    display: none !important;
  }
}

@media (max-width: 450px) {
  .inner-wrapper.move-wrapper {
    -moz-transform: translateX(244px);
    -ms-transform: translateX(244px);
    -o-transform: translateX(244px);
    -webkit-transform: translateX(244px);
    transform: translateX(244px);
  }

  .striped-bg-faint-line .info-strips {
    .strip {
      float: none;
      margin: 0 0 2px 0;
      padding: 0;
      width: 100%;

      .strip-content {
        padding: 14px 10px;
      }
    }
  }

  .btn-orange {
    clear: both;
    right: 30px;
  }
}

.hdn {
  display: none;
}

/* CMS Desk */

.EditMode {
  .homepage-signposts {
    .EditorWidget.WebPartInner {
      float: left;
      width: 20%;

      .WebPartContent {
        .signpost-item {
          width: 100%;
        }
      }
    }
  }

  .striped-bg-faint-line {
    .EditorWidget.WebPartInner {
      float: left;
    }
  }
}

.my-profile-logout {
  text-align: right;
  margin-bottom: 39px;
}

.orig {
  display: none;
}

.load-more-btn {
  display: none;
}

@media screen and (max-width: 767px) {
  .table-responsive {
    border: 0px solid !important;
    /*overflow-x: hidden;*/
  }

  .col-sm-6.col-md-4.guest-checkout {
    padding-top: 25px;
  }
}

.option-btn {
  max-width: 188px;
}

.guest-checkout {
}

.no-js-div {
  display: none !important;
}

.enquire-now {
  display: none !important;
}

.consent-item {
  margin-bottom: 34px;

  .btn {
    margin-bottom: 20px;
  }

  .btn-manual-revoke {
    display: inline-block;
    position: relative;
    margin: 0;
    border: 0;
    padding: 12px 36px;
    color: #fff;
    background-color: @brand-orange;
    font-size: 1.28571429em;
    vertical-align: middle;
    box-sizing: border-box;
    border-radius: 0;
    transition: background 0.3s ease;
    margin-bottom: 20px;
  }

  .grey-bar:last-of-type {
    display: none;
  }
}

.table-responsive .CaptchaTable {
  width: auto;
  td {
    border: none !important;

    img {
      padding-top: 30px;
    }
  }
}

.lazy-load {
  opacity: 0;
  transition: opacity 0.25s ease;
  will-change: opacity;

  &.loaded {
    opacity: 1;
  }
}

.mob-only {
  display: none !important;

  @media screen and (max-width: @screen-mobile) {
    display: inherit !important;
  }
}
