@import '../partials/_mixins';
@import '../partials/_variables';

.solution-box {
  padding: 15px 25px 25px;
  background: url(~@imgOld/faint-line-repeat.png) repeat 0px 0px;
  margin-bottom: 60px;
}

.product-filter {
  background: @brand-orange;
  padding: 5px 10px;
  text-align: center;
  margin-bottom: 30px;
  min-height: 54px;

  .filter-inner {
    display: inline-block;
    width: auto;
  }

  label {
    display: block;
    float: left;
    color: #fff;
    margin-right: 13px;
    .font-size(18);
    font-weight: normal;
    margin-bottom: 0;
    margin-top: 12px;
  }

  select {
    width: 175px;
    display: block;
    float: left;
    margin: 5px 0 0 0;
    height: 33px;
  }

  .chosen-container {
    display: block;
    float: left;
    width: 175px !important;
    height: 33px;
    margin: 5px 0 0 0;
    background: #f2f2f2;

    .chosen-single span {
      height: 33px;
      line-height: 35px;
      padding-left: 9px;
      text-align: left;
      padding-right: 19px;
    }

    .chosen-single {
      height: 33px;
      padding-left: 0;
    }

    .chosen-single div {
      padding: 3px 10px;
      width: 42px;
    }

    .chosen-results li {
      padding-left: 6px;
      padding-right: 9px;
      text-align: left;
      height: 33px;
      line-height: 25px;
    }
  }

  .prod-keyword-search {
    float: left;

    label {
      display: none;
    }

    #tbProductFilter {
      margin-left: 15px;
      padding: 6px 10px 6px;
      border: none;
      float: left;
      margin-top: 6px;
      position: relative;
    }

    #btnProductFilter {
      padding: 7px 18px 5px 18px;
      background-image: none;
      margin-top: 6px;
      margin-left: 6px;
      float: left;
    }
  }
}

.product-view {
  margin-bottom: 40px;

  ul {
    margin-bottom: 25px;

    li {
      margin-bottom: 10px;
    }
  }

  .post-area {
    float: none;
    width: 100%;
    padding-left: 0;
    padding-right: 85px;

    .left-type {
      left: auto;
      right: 0;

      .new {
        position: absolute;
        top: 0;
        left: 0;
        color: #fff;
        background: @brand-orange;
        width: 60px;
        height: 60px;
        text-align: center;
        .font-size(18);
        padding-top: 20px;
      }
    }

    .product-carousel {
      aspect-ratio: 762/447;
    }
  }

  table {
    font-size: 86%;
    margin-bottom: 40px;
  }

  .smallprint {
    .font-size(14);
    margin-bottom: 30px;
    display: block;
  }

  .btn {
    margin-bottom: 40px;
  }
}

.add-to-basket {
  position: relative;
}

.popup-basket-msg {
  .font-size(18);
  display: none;
  position: absolute;
  bottom: 105px;
  background-color: @brand-green;
  padding: 10px 10px 0px;
  color: #fff;
  text-align: center;
  z-index: 10;
  right: 0px;

  &.iconview {
    width: 255px;
  }

  .message {
    i {
      margin-right: 10px;
    }
    a {
      color: #fff;
    }
  }
}

@media (max-width: 767px) {
  .product-filter .prod-keyword-search {
    clear: both;
    margin-left: 0px;

    label {
      display: block;
    }

    #tbProductFilter {
      margin-left: 1px;
      left: 0px;
      padding: 6px 15px 6px 9px;
    }
  }
}

@media (max-width: 362px) {
  .product-view .post-area .left-type .new {
    width: 52px;
    height: 28px;
    padding-top: 5px;
  }
}

#response {
  overflow: auto;
}
