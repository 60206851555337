.ism-modal {
  @ism-steps-height: 15px;
  @ism-footer-height: 60px;
  @ism-footer-height--mobile: 50px;
  @ism-header-height: 120px;
  font-size: 16px;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: @brand-white;
  z-index: 8002;

  &.active {
    display: block;
  }

  &__inner {
    height: calc(~'100% - @{ism-steps-height}');
    padding: 18px;

    @media (min-width: @screen-tablet) {
      padding: 20px;
    }
  }

  &__header {
    position: relative;
    padding: 0 30px 0 0;
    display: flex;
    flex-direction: column;
    height: @ism-header-height;

    @media (min-width: @screen-tablet) {
      padding: 0 30px;
    }

    img {
      width: 100%;
      max-width: 307px;
      margin: 0 auto 0 0;
      display: block;
      flex: 0 0 auto;

      @media (min-width: @screen-tablet) {
        margin: 0 auto;
      }
    }
  }

  &__close {
    position: absolute;
    top: 12px;
    right: 0;
    transform: translateY(-50%);
    background: transparent;
    padding: 0px;
    width: 20px;
    height: 20px;

    &:active,
    &:focus,
    &:hover {
      background: transparent;
      border: none;
      outline: none;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('~@imgOld/ism/icon-cross.svg');
    }
  }

  &__intro {
    text-align: center;
    // margin-top: 15%;

    img {
      margin-bottom: 50px;
    }

    p {
      max-width: 550px;
      margin: 0 auto;
    }
  }

  &__steps {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: @ism-steps-height;
  }

  &__step {
    flex: 1 1 auto;
    background-color: #ccddab;
    width: 100%;
    height: 100%;
    margin-right: 4px;

    &.active {
      background-color: @brand-green--light;
      cursor: pointer;
    }

    &:last-child {
      margin-right: 0px;
    }
  }

  &__content {
    height: calc(~'100% - @{ism-footer-height} - @{ism-header-height}');
    padding: 20px 0px;
    overflow-y: auto;

    @media (min-width: @screen-tablet) {
      height: calc(~'100% - @{ism-header-height}');
      padding: 40px 0px;
    }

    &.boxes-open {
      overflow-y: hidden;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: @brand-white;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: @brand-green--light;
      border-radius: 10px;
    }
  }

  &__footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: @ism-footer-height--mobile;
    background-color: @brand-green--light;

    @media (min-width: @screen-tablet) {
      background-color: transparent;
      height: @ism-footer-height;
      z-index: 0;
    }

    &--next {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }

    &--previous {
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
    }
  }
}
